import JSZip from "jszip"
import { saveAs } from "file-saver"

export const updateDownloadsCounter = firebase => async count => {
  const geotags_count = require("firebase").firestore.FieldValue.increment(
    count || 1
  )
  // User counter
  const user = require("firebase").default.auth().currentUser

  await firebase.user(user.uid).update({
    geotags_count,
  })
}

// Creates a new zip file with files compressed.
export const generateFilesDownload = async files => {
  console.info(files.length)
  updateDownloadsCounter(files.length)
  const zip = new JSZip()
  const folder = zip.folder("localrocket-geotag")
  files.forEach(file => {
    try {
      let data = file.image.src
      data = data.split(",")[1]
      data = atob(data)
      folder.file(file.name, data, { binary: true })
    } catch (e) {
      console.info(e)
    }
  })

  zip.generateAsync({ type: "blob" }).then(e => {
    saveAs(e, "localrocket-geotag.zip")
  })
}
