import React, { useState, useEffect } from "react"
import GeoGridItem from "./GeoGridItem"
import NewButton from "./NewButton"

export default function GeoGridList({ grids, onRemoveGrid }) {
  return (
    <>
      <NewButton />
      <hr />
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Negocio</th>
              <th>Keyword</th>
              <th>Tamaño</th>
              <th>Radio</th>
              <th>Puntos</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {grids.map((grid, index) => (
              <GeoGridItem
                key={index}
                grid={grid}
                onRemoveGrid={onRemoveGrid}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
