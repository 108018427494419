import React, { useContext, useEffect, useState } from "react"
import { CheckoutContext } from "../context"
// import CreditCardForm from "./CreditCardForm"
import Loading from "../Loading"

import { loadStripe } from "@stripe/stripe-js"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import * as actions from "../actions"
import { useFirebase } from "../../Firebase"

import {
  getPaymentMethod,
  savePaymentMethod,
} from "../../Settings/Billing/actions"
import { getBillingDetails } from "../../PaymentsNew/actions"
import CreditCardForm from "../../Settings/Billing/CreditCardForm"
import {
  PaymentMethod,
  EmptyPaymentMethod,
} from "../../Settings/Billing/DefaultPaymentMethod"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)

export default function PaymentMethods({ order, firebase, location }) {
  // const createPayment = actions.createPayment(firebase)

  // const { state, dispatch } = useContext(CheckoutContext)
  const [environment, setEnvironment] = useState(
    process.env.NODE_ENV === "development" ? "test" : "prod"
  )
  // const [loading, setLoading] = useState(true)
  // const [error, setError] = useState(null)

  // useEffect(() => {
  //   console.info("creamos payment?")
  //   createPayment(order)
  //     .then((paymentIntent) => {
  //       // console.info(order, paymentIntent)
  //       dispatch({ type: "setPayment", paymentIntent })
  //     })
  //     .catch((err) => {
  //       setError(
  //         "Ha ocurrido un error cargando el sistema de pago seguro. Vuelve a intentarlo más tarde."
  //       )
  //       console.info(err)
  //     })
  //     .then((_) =>
  //       firebase
  //         .settings()
  //         .doc("subscriptions")
  //         .get()
  //         .then((snapshot) => {
  //           setEnvironment(snapshot.get("environment"))
  //         })
  //     )
  //     .then((_) => setLoading(false))
  // }, [])

  return (
    <div>
      <button
        className="button special icon fa-credit-card"
        onClick={(evt) => evt.preventDefault()}
      >
        Tarjeta
      </button>
      <hr />
      <Elements stripe={stripePromise}>
        <DefaultPaymentMethod
          environment={process.env.NODE_ENV === "development" ? "test" : "prod"}
        />
      </Elements>
      {/* {loading ? (
        <Loading />
      ) : !error ? (
        <Elements stripe={stripePromise}>
          <CreditCardForm environment={environment} />
        </Elements>
      ) : (
        <p>{error}</p>
      )} */}
      <hr />
    </div>
  )
}

/*****
 *
 */

const DefaultPaymentMethod = ({ environment }) => {
  const { state, dispatch } = useContext(CheckoutContext)
  const firebase = useFirebase()
  const stripe = useStripe()

  const createPaymentSubmit = actions.createPaymentSubmit(firebase)

  const getPaymentMethodAction = getPaymentMethod(firebase)
  const savePaymentMethodAction = savePaymentMethod(firebase)

  const [paymentMethod, setPaymentMethod] = useState()
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  const handleEdit = async () => setIsEditing(true)
  const handleCancel = () => setIsEditing(false)

  const fetchPaymentMethod = async () => {
    setLoading(true)
    await getPaymentMethodAction().then(setPaymentMethod)

    await getBillingDetails(firebase)
      .then((details) => setDetails(details))
      .then(() => setLoading(false))
  }

  useEffect(() => {
    fetchPaymentMethod()
  }, [])

  const handleUpdate = async (paymentMethod) => {
    setLoading(true)

    await savePaymentMethodAction(paymentMethod)
    await fetchPaymentMethod()
    setIsEditing(false)
    setLoading(false)
  }

  const paymentSubmit = async () => {
    return createPaymentSubmit(paymentMethod, state.order, stripe)
    // const SUBSCRIPTIONS_BASE_URL = process.env.GATSBY_SUBSCRIPTIONS_URL
    // const { currentUser } = firebase.auth

    // const headers = {
    //   Authorization: await currentUser.getIdToken(),
    //   "Content-Type": "application/json",
    // }

    // const url = `${SUBSCRIPTIONS_BASE_URL}/subscriptions/create`

    // return fetch(url, {
    //   headers,
    //   method: "POST",
    //   body: JSON.stringify({
    //     paymentMethod: paymentMethod.id,
    //     orderId: state.order.id,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((subscription) => {
    //     console.info(subscription)
    //     if (subscription && subscription.status === "active") {
    //       // Subscription is active, no customer actions required.
    //       console.info("Suscripción creada con éxito y activa")
    //       return { subscription }
    //     }
    //     let paymentIntent = subscription.latest_invoice.payment_intent

    //     if (
    //       paymentIntent.status === "requires_action" ||
    //       paymentIntent.status === "requires_payment_method"
    //     ) {
    //       console.info("confirmando tarjeta, acción necesaria")
    //       return stripe
    //         .confirmCardPayment(paymentIntent.client_secret, {
    //           payment_method: paymentMethod.id,
    //         })
    //         .then((result) => {
    //           if (result.error) {
    //             // Start code flow to handle updating the payment details.
    //             // Display error message in your UI.
    //             // The card was declined (i.e. insufficient funds, card has expired, etc).
    //             console.info("error con el pago", result.error)
    //             throw result.error
    //           } else {
    //             if (result.paymentIntent.status === "succeeded") {
    //               // Show a success message to your customer.
    //               console.info("Se ha procesado con éxito")
    //               return { subscription }
    //             }
    //           }
    //         })
    //     }
    //   })
  }

  useEffect(() => {
    if (isEditing || !paymentMethod) {
      dispatch({
        type: "setPaymentSubmit",
        paymentSubmit: null,
      })
    } else {
      dispatch({
        type: "setPaymentSubmit",
        paymentSubmit: paymentSubmit,
      })
    }
  }, [isEditing, paymentMethod])

  return (
    <>
      <div style={{ marginBottom: "2em" }}>
        {loading ? (
          <Loading />
        ) : isEditing ? (
          <CreditCardForm
            details={details}
            environment={environment}
            onCancel={handleCancel}
            onPaymentMethod={handleUpdate}
          />
        ) : paymentMethod ? (
          <>
            <PaymentMethod {...paymentMethod} />
            <button onClick={handleEdit}>Editar método de pago</button>
          </>
        ) : (
          <>
            <EmptyPaymentMethod />
            <button onClick={handleEdit}>Añadir método de pago</button>
          </>
        )}
      </div>
    </>
  )
}
