import React from "react"
import Details from "./Details"
import DefaultPaymentMethod from "./DefaultPaymentMethod"
export default function Billing() {
  return (
    <>
      <Details />
      <hr />
      <DefaultPaymentMethod />
    </>
  )
}
