import React from "react"

export default () => (
  <div className="gdpr">
    <p className="title">
      <strong>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS</strong>
    </p>
    <p>
      <strong>RESPONSABLE:</strong> SIO Levante S.L, CIF: B-98801020, Domicilio
      Social: Avda. Francia 44, Entresuelo. C.P. 46023, Valencia, e-mail:{" "}
      <a href="mailto:info@localrocket.me">info@localrocket.me</a>.
    </p>
    <p>
      <strong>FINALIDADES:</strong> Utilizamos tus datos para: atender
      solicitudes de información, contratación de productos y/o servicios y
      remitir comunicaciones.
    </p>
    <p>
      <strong>LEGITIMACIÓN:</strong> Nos das tu permiso para el tratamiento de
      tus datos como interesado.
    </p>
    <p>
      <strong>DESTINATARIOS:</strong> – No se ceden datos a terceros, salvo
      obligación legal – Personas físicas o jurídicas directamente relacionadas
      con el Responsable – Encargados de Tratamiento de la U.E. o adheridos al
      Privacy Shield.
    </p>
    <p>
      <strong>DERECHOS:</strong> – Revocar el consentimiento – Acceso,
      rectificación, supresión, limitación u oposición al tratamiento, derecho a
      no ser objeto de decisiones automatizadas, así como a obtener información
      clara y transparente sobre el tratamiento de los datos.
    </p>
    <p>
      <strong>INFORMACIÓN ADICIONAL:</strong> – Se puede consultar la política
      de privacidad de forma más detallada{" "}
      <a href="https://localrocket.me/politica-privacidad/" target="_blank">
        aquí
      </a>{" "}
      y las condiciones de uso{" "}
      <a href="https://localrocket.me/aviso-legal/" target="_blank">
        aquí
      </a>
      .
    </p>
  </div>
)
