import React from "react"

export default function Overview({ grid }) {
  const { latitude, longitude } = grid.get("center")
  const radius = grid.get("radius")
  const radiusFormatted = Math.floor(radius / 1000)
  const size = grid.get("size")
  const points = grid.get("points")
  const pointsCount = points.length
  const arp = (
    points
      .filter(p => p.pos > 0)
      .map(p => p.pos)
      .reduce((acc, val) => (acc = acc + val)) / pointsCount
  ).toFixed(2)

  return (
    <>
      <h2>Informe</h2>
      <p>
        Término de búsqueda: <strong>{grid.get("businessTerm")}</strong>
      </p>
      <div
        style={{
          border: "1px solid #b3b3b3",
          padding: "1em",
          marginBottom: "2em",
        }}
      >
        {grid.get("businessName")}
      </div>
      <p>
        Búsqueda usando una rejilla de {size}&times;{size} con un radio de{" "}
        {radiusFormatted} km cubriendo un total de{" "}
        {(radius * radius) / 1000 / 1000} km² con centro en{" "}
        <a
          href={`https://www.google.es/maps/search/${latitude},${longitude}`}
          target="_blank"
        >
          {latitude}, {longitude}
        </a>
        .
      </p>
      <p>ARP: {arp}</p>
    </>
  )
}
