import React, { useEffect, useState } from "react"
import { Link } from "@reach/router"
import AuthUserContext from "../../Session/context"
import { withFirebase } from "../../Firebase"
import Loading from "../Loading"

const ReturnBackButton = ({ order }) => {
  const payload = order.get("payload")

  if (payload) {
    if (payload.type == "power-link") {
      return (
        <Link to="/app/branded-maps-searches" className="button special">
          Volver
        </Link>
      )
    }
  }

  return (
    <Link to="/app/" className="button special">
      Ir al Dashboard
    </Link>
  )
}

const Order = ({ order }) => {
  // console.info(order)
  return (
    <div className="grid-wrapper">
      <div className="col-2"></div>
      <div className="col-8" style={{ textAlign: "center" }}>
        {order !== null ? (
          <>
            {order.get("status") == "succeeded" ? (
              <h2>¡Tu pago se ha realizado con éxito!</h2>
            ) : (
              <h2>¡Tu pago se ha realizado con éxito!</h2>
            )}

            <p>
              <small style={{ opacity: 0.5 }}>
                Pedido {order.get("orderNumber")}
              </small>
            </p>

            {order.get("status") == "succeeded" ? (
              <>
                <p>
                  ¡Gracias! Hemos la suscripción se ha activado correctamente.
                </p>
                {order.get("payload") &&
                order.get("payload").type == "power-link" ? (
                  <p>
                    Tu ficha se empezará a procesar en las siguientes 24 horas.
                  </p>
                ) : null}
              </>
            ) : (
              <p>¡Gracias! Estamos validando la transacción.</p>
            )}
          </>
        ) : (
          <h2>Lo sentimos, no se ha encontrado el pedido.</h2>
        )}

        {order.get("invoicePdf") || order.get("invoiceUrl") ? (
          <>
            <hr />
            {order.get("invoiceUrl") ? (
              <a
                href={order.get("invoiceUrl")}
                target="_blank"
                className="button"
              >
                Ver Recibo
              </a>
            ) : null}{" "}
            {order.get("invoicePdf") ? (
              <a href={order.get("invoicePdf")} className="button">
                Descargar factura
              </a>
            ) : null}
          </>
        ) : null}

        <hr />
        <ReturnBackButton order={order} />
      </div>
      <div className="col-2"></div>
    </div>
  )
}

const Thanks = ({ id, firebase }) => {
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState(null)

  useEffect(() => {
    firebase
      .orders()
      .where("orderNumber", "==", parseInt(id))
      .limit(1)
      .onSnapshot(querySnapshot => {
        setOrder(querySnapshot.docs[0])
        setLoading(false)
      })
  }, [])

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          {loading ? <Loading /> : <Order order={order} />}
        </div>
      </section>
    </div>
  )
}

export default withFirebase(({ firebase, orderId }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <Thanks firebase={firebase} authUser={authUser} id={orderId} />
      ) : null
    }
  </AuthUserContext.Consumer>
))
