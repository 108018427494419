import React, { useEffect, useState } from "react"
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form"
import Select from "../../Select"
import countries from "i18n-iso-countries"
import locale from "i18n-iso-countries/langs/es.json"
import ReactCountryFlag from "react-country-flag"
import { useFirebase } from "../../Firebase"
import { useToasts } from "../../FlashNotices"
import {
  getBillingDetails,
  saveBillingDetails,
} from "../../PaymentsNew/actions"
import { CircularProgress } from "@material-ui/core"

// TODO:
// import SalesTax from "sales-tax"
// SalesTax.setTaxOriginCountry("ES", false)
// SalesTax.toggleEnabledTaxNumberFraudCheck(false)

countries.registerLocale(locale)

export const Form = () => {
  const { register, setValue, getValues, formState, trigger, control } =
    useFormContext()

  const [option, setOption] = React.useState({})
  const [options, setOptions] = React.useState([])

  useEffect(() => {
    const defaultOption = getValues("details.country")
    const options = Object.entries(countries.getNames("es"))
      .map(([value, label]) => ({
        value,
        label,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))

    setOptions(options)
    setOption(options.find((f) => f.value === defaultOption))
  }, [])

  const handleSelectChange = (option) => {
    setValue("details.country", option.value)
    trigger("details.country")
    setOption(option)
  }
  const disabled = formState.isSubmitting

  return (
    <>
      <div className="grid-wrapper">
        <div className="col-6">
          <div className="field">
            <label>Nombre de facturación*</label>
            <input
              type="text"
              {...register("details.name", { required: true })}
              disabled={disabled}
            />
            {formState.errors["details.name"] && (
              <div className="error">
                <span>Este campo es obligatorio</span>
              </div>
            )}
          </div>
        </div>

        <div className="col-6">
          <div className="field">
            <label>Número de IVA*</label>
            <input
              type="text"
              {...register("details.vatnumber", { required: true })}
              disabled={disabled}
            />
            {formState.errors["details"] &&
              formState.errors["details"]["vatnumber"] && (
                <div className="error">
                  <span>
                    {formState.errors["details"]["vatnumber"]["message"]}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="field">
        <label>Dirección*</label>
        <input
          type="text"
          disabled={disabled}
          {...register("details.address1", { required: true })}
        />
        {formState.errors["details.address1"] && (
          <div className="error">
            <span>Este campo es obligatorio</span>
          </div>
        )}
      </div>
      <div className="field">
        <label>Dirección 2</label>
        <input
          type="text"
          disabled={disabled}
          {...register("details.address2")}
        />
      </div>
      <div className="grid-wrapper">
        <div className="col-6">
          <div className="field">
            <label>Código postal*</label>
            <input
              type="text"
              disabled={disabled}
              {...register("details.postalcode", { required: true })}
            />
            {formState.errors["details.postalcode"] && (
              <div className="error">
                <span>Este campo es obligatorio</span>
              </div>
            )}
          </div>
        </div>

        <div className="col-6">
          <div className="field">
            <label>Ciudad*</label>
            <input
              type="text"
              disabled={disabled}
              {...register("details.city", { required: true })}
            />
            {formState.errors["details.city"] && (
              <div className="error">
                <span>Este campo es obligatorio</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="field select">
        <label>Pais*</label>

        <Controller
          name="details.country"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              value={option}
              defaultValue={option}
              placeholder="Selecciona tu pais"
              formatOptionLabel={(option) => (
                <>
                  {option.value ? (
                    <ReactCountryFlag
                      className="emojiFlag"
                      countryCode={option.value}
                      aria-label={option.label}
                    />
                  ) : null}{" "}
                  {option.label}
                </>
              )}
              isSearchable={true}
              isDisabled={disabled}
              menuPlacement="auto"
              onChange={handleSelectChange}
            />
          )}
        />

        {formState.errors["details.country"] && (
          <div className="error">
            <span>Este campo es obligatorio</span>
          </div>
        )}
      </div>
    </>
  )
}

const DetailsForm = ({ details, onSubmit }) => {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      details: details,
    },
  })

  const handleSubmit = async (data) => {
    await onSubmit(data)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Form />
        <DetailsButton />
      </form>
    </FormProvider>
  )
}

export default function Details() {
  const firebase = useFirebase()
  const { success, error } = useToasts()

  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState({})

  useEffect(() => {
    setLoading(true)

    getBillingDetails(firebase)
      .then((data) => {
        setDetails(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        // TODO: mensaje toast
        console.info(error)
      })
  }, [firebase])

  const handleSubmit = async (data) => {
    await saveBillingDetails(firebase, data.details)
      .catch((err) => error("Ha ocurrido un error guardando los datos."))
      .then(() => success("Datos guardados correctamente."))
  }

  return (
    <>
      <h2>Datos de facturación</h2>
      {loading ? (
        <CircularProgress />
      ) : (
        <DetailsForm details={details} onSubmit={handleSubmit} />
      )}
    </>
  )
}

export const DetailsButton = () => {
  const { formState } = useFormContext()
  const className = formState.isSubmitting ? "loading" : ""
  const disabled = formState.isSubmitting || !formState.isValid

  return (
    <button className={className} disabled={disabled}>
      Guardar
    </button>
  )
}
