import React, { useState, useEffect } from "react"
import { Router, navigate } from "@reach/router"
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import { CheckoutContextProvider, CheckoutContext } from "./context"

export const SubmitButton = () => {
  const { formState } = useFormContext()
  const className = formState.isSubmitting ? "loading" : ""
  const disabled = formState.isSubmitting || !formState.isValid

  return (
    <>
      <button className={className} disabled={disabled}>
        Continuar
      </button>{" "}
    </>
  )
}

export default ({ map, onBoost, close }) => {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      mapUrl: "",
      mapKeyword1: "",
      mapKeyword2: "",
      mapKeyword3: "",
    },
  })

  const handleSubmit = async (data) => {
    const urls = ["https://drive.google.com/", "https://www.google.com/maps/"]
    if (!data.mapUrl.startsWith(urls[0]) && !data.mapUrl.startsWith(urls[1])) {
      methods.setError("mapUrl", {
        type: "invalid",
        message: `La url pública debe empezar por ${urls[0]} o ${urls[1]}.`,
      })
    } else {
      onBoost(data)
    }
  }

  const handleCancel = async (data) => {
    close()
  }

  return (
    <FormProvider {...methods}>
      <div className="grid-wrapper">
        <div className="col-12">
          <p>
            Con el Map Boost envías señales de popularidad a tu mapa mediante la
            creación de enlaces de Tier2 y embeds en blogs 2.0 tal y como
            sabemos que mejor funciona. El pedido incluye el servicio de
            indexación de todas las URL resultantes en un periodo de 30 días.
          </p>
          <p>
            <strong>IMPORTANTE:</strong> Es necesario hacer público tu mapa
            personalizado dentro de Google My Maps.
          </p>
          <h3>{map.get("businessName")}</h3>
        </div>
      </div>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Form />
        <hr />
        <SubmitButton /> o{" "}
        <a href="#" onClick={handleCancel}>
          Cancelar
        </a>
      </form>
    </FormProvider>
  )
}

const Form = ({ map }) => {
  const { formState, register } = useFormContext()
  const { errors } = formState
  console.info(errors)
  return (
    <div className="grid-wrapper">
      <div className="field col-12">
        <label htmlFor="business_name">URL pública del mapa*</label>

        <input
          className={errors.mapUrl && "error"}
          disabled={formState.isSubmitting}
          type="text"
          id="map_url"
          {...register("mapUrl", {
            required: true,
            // pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
          })}
          placeholder="URL"
        />
        {errors["mapUrl"] && (
          <div className="error">
            <span>
              {errors["mapUrl"]["type"] == "required" ? (
                <span>Este campo es obligatorio</span>
              ) : errors["mapUrl"]["type"] == "pattern" ? (
                <span>No parece una URL válida</span>
              ) : (
                <span>{errors["mapUrl"]["message"]}</span>
              )}
            </span>
          </div>
        )}
      </div>

      <div className="field col-4">
        <label htmlFor="business_name">Keyword 1*</label>
        <div style={{ display: "flex" }}>
          <input
            className={errors.mapKeyword1 && "error"}
            disabled={formState.isSubmitting}
            type="text"
            id="map_keyword1"
            {...register("mapKeyword1", { required: true })}
            placeholder="Keyword"
          />
        </div>
      </div>
      <div className="field col-4">
        <label htmlFor="business_name">Keyword 2</label>
        <div style={{ display: "flex" }}>
          <input
            className={errors.mapKeyword2 && "error"}
            disabled={formState.isSubmitting}
            type="text"
            id="map_keyword2"
            {...register("mapKeyword2")}
            placeholder="Keyword"
          />
        </div>
      </div>
      <div className="field col-4">
        <label htmlFor="business_name">Keyword 3</label>
        <div style={{ display: "flex" }}>
          <input
            className={errors.mapKeyword3 && "error"}
            disabled={formState.isSubmitting}
            type="text"
            id="map_keyword3"
            {...register("mapKeyword3")}
            placeholder="Keyword"
          />
        </div>
      </div>
    </div>
  )
}
