import React, { useRef, useState, createContext, useContext } from "react"

import { ConfirmationDialog } from "./ConfirmationDialog"

const ConfirmationServiceContext = createContext(Promise.reject)

export const useConfirmation = () => useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = useState(null)

  const awaitingPromiseRef = useRef()
  const openConfirmation = options => {
    setConfirmationState(options)
    return new Promise((resolve, reject) => {
      // save the promise result to the ref
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    // Mostly always you don't need to handle canceling of alert dialog
    // So shutting up the unhandledPromiseRejection errors
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }
    setConfirmationState(null)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }
    setConfirmationState(null)
  }

  return (
    <div>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />
      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </div>
  )
}
