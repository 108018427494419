import React, { useState, useEffect } from "react"
import { Link, navigate } from "@reach/router"
import AuthUserContext from "../Session/context"
import { withFirebase } from "../Firebase"

import { useToasts } from "../FlashNotices"
import { useForm, FormProvider } from "react-hook-form"
import * as actions from "../PaymentsNew/actions"

import {
  CheckoutContextProvider,
  CheckoutContext,
} from "../PaymentsNew/context"
import {
  Loading,
  PaymentMethods,
  PaymentMethodsButton,
} from "../PaymentsNew/PaymentMethods"
import Overview from "../PaymentsNew/Overview"

const Form = ({ orderNumber, firebase, authUser }) => {
  let { state, dispatch } = React.useContext(CheckoutContext)
  const toasts = useToasts()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    actions.getBillingDetails(firebase).then((details) => {
      dispatch({ type: "setDetails", payload: details })
    })
    const unsubscribe = firebase
      .orders(authUser.uid)
      .where("orderNumber", "==", parseInt(orderNumber))
      .limit(1)
      .onSnapshot(
        (snapshot) => {
          if (snapshot.empty) {
            toasts.error("El pedido no existe o no es válido.")
            setError("Lo sentimos, no hemos podido encontrar tu pedido. ")
          } else {
            const order = snapshot.docs[0]

            dispatch({ type: "setProduct", payload: order.get("productId") })
            dispatch({
              type: "setOrder",
              payload: { id: order.id, ...order.data() },
            })
            dispatch({ type: "update", payload: order.get("credits") })
          }
          setLoading(false)
        },
        (err) => {
          if (err.code === "permission-denied") {
            toasts.error("El pedido no existe o no es válido.")
            setError("Lo sentimos, no hemos podido encontrar tu pedido. ")
          } else {
            toasts.error("El pedido no existe o no es válido.")
            setError("Lo sentimos, no hemos podido encontrar tu pedido. ")
          }
        }
      )

    return () => unsubscribe()
  }, [firebase, authUser.uid])

  const onSubmit = async (data) => {
    const { paymentSubmit } = state
    await paymentSubmit()
      .then((res) => {
        toasts.success("El pago ha sido completado con éxito.")
        navigate(`/app/payments/order/${state.order.orderNumber}`, {
          replace: true,
        })
      })
      .catch((err) => toasts.error(err.message))
  }

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid-wrapper">
          <div className="col-8">
            <h2>Pedido #{orderNumber}</h2>
            {loading ? (
              <Loading />
            ) : error ? (
              <>
                <h2>{error}</h2>
                <Link to={"/app/payments"} className="button">
                  Ver todos los pagos
                </Link>
              </>
            ) : (
              <PaymentMethods firebase={firebase} />
            )}
          </div>
          <div className="col-4">
            {loading ? null : error ? null : (
              <>
                <h3>Resumen del pedido</h3>
                <Overview order={state.order} />
                <hr />
                <PaymentMethodsButton />
              </>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

const Payment = ({ orderNumber, authUser, firebase }) => {
  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <Form
            orderNumber={orderNumber}
            authUser={authUser}
            firebase={firebase}
          />
        </div>
      </section>
    </div>
  )
}

export default ({ order }) => {
  const Component = withFirebase(({ firebase }) => (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <CheckoutContextProvider>
            <Payment
              orderNumber={order}
              authUser={authUser}
              firebase={firebase}
            />
          </CheckoutContextProvider>
        ) : null
      }
    </AuthUserContext.Consumer>
  ))
  return <Component />
}
