import React from "react"
import { Link } from "@reach/router"

import MapItem from "./MapItem"

export default ({ maps, onSelectMap, onRemoveMap, boost }) => {
  return (
    <>
      <Link to="new" className="button right">
        Nuevo mapa
      </Link>

      <hr />
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Mapa</th>
              <th>Fecha</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {maps.map(map => (
              <MapItem
                key={map.id}
                uid={map.uid}
                map={map}
                name={map.get("businessName")}
                createdAt={map.get("createdAt").toDate()}
                filePath={map.get("filePath")}
                onSelectMap={onSelectMap}
                onRemoveMap={onRemoveMap}
                boost={boost}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
