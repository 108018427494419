import React from "react"

import { navigate } from "@reach/router"

import Form from "./Form"
import * as actions from "../actions"

export default function ProjectsNew({ authUser, firebase }) {
  const createProject = actions.createProject(firebase)

  const onSubmit = async data => {
    createProject(data).then(project => {
      navigate(project.id)
    })
  }

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <Form onSubmit={onSubmit} />
        </div>
      </section>
    </div>
  )
}
