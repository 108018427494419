import React, { useState, useEffect, useContext } from "react"
import { Router } from "@reach/router"

import PowerLinksList from "./PowerLinksList"
import PowerLinksNew from "./PowerLinksNew"
import PowerLinksShow from "./PowerLinksShow"
import PowerLinksEdit from "./PowerLinksEdit"

export default function PowerLinks() {
  return (
    <Router>
      <PowerLinksList path="/" />
      <PowerLinksShow path="/:id/show" />
      <PowerLinksEdit path="/:id/edit" />
      <PowerLinksNew path="/*" />
    </Router>
  )
}

export const PowerLinksNav = () => {
  return <div></div>
}
