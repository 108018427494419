import React from "react"
import { useFormContext } from "react-hook-form"

export default function Price({ price, checked, onChecked }) {
  const { register } = useFormContext()

  const amount = price.unit_amount
  const amountBefore = null

  const className = `box pack ${checked ? "selected" : ""}`

  const humanize = (count, interval) => {
    const month = ["més", "meses"]

    if (interval == "month") {
      return count == 1 ? month[0] : `${count} ${month[1]}`
    }
    return `${count} ${interval}`
  }

  return (
    <div className={className}>
      <input
        type="radio"
        id={`subscription-${price.id}`}
        {...register('priceId', { required: true })}
        value={price.id}
        defaultChecked={checked}
        onChange={onChecked.bind(null, price)} />

      <label
        htmlFor={`subscription-${price.id}`}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <strong style={{ paddingLeft: "1em" }}>
          <span style={{ fontSize: "1.5em" }}>{price.nickname}</span>
          {price.recurring ? (
            <div>
              <small>
                Se renovará automáticamente cada{" "}
                {humanize(
                  price.recurring.interval_count,
                  price.recurring.interval
                )}
              </small>
            </div>
          ) : null}
        </strong>

        <span style={{ textAlign: "right", whiteSpace: "nowrap" }}>
          <strong style={{ fontSize: "1.5em" }}>
            {(price.unit_amount / 100).toFixed(2)} €
          </strong>

          {amountBefore ? (
            <>
              <br />
              <strong
                style={{
                  fontSize: "1.5em",
                  textDecoration: "line-through",
                  opacity: 0.5,
                  fontWeight: "normal",
                }}
              >
                {(amountBefore / 100).toFixed(2)} €
              </strong>
            </>
          ) : null}
          {amountBefore ? (
            <>
              <br />
              <span style={{ opacity: 0.75 }}>
                ahorro {Math.round((1 - amount / amountBefore) * 100)}%
              </span>
            </>
          ) : null}
          {/* <br />
          <small>+TAX</small> */}
        </span>
      </label>
    </div>
  );
}
