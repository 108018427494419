import React from "react"
import { Link } from "@reach/router"
import { formatTimestamp } from "../../utils/time"

export default function GeoGridItem({ grid, onRemoveGrid }) {
  const statusFormatted = () => {
    const status = grid.get("status")
    if (status == "pending") {
      return "Procesando..."
    } else if (status == "completed") {
      return "Completado"
    }
  }
  return (
    <tr>
      <td style={{ whiteSpace: "nowrap" }}>
        {formatTimestamp(grid.get("createdAt").toDate())}
      </td>
      <td>{grid.get("businessName")}</td>
      <td>{grid.get("businessTerm")}</td>
      <td>{grid.get("size")}</td>
      <td>{grid.get("radius")}</td>
      <td>{grid.get("size") * grid.get("size")} puntos</td>
      <td>{statusFormatted()}</td>
      <td style={{ display: "flex" }}>
        {grid.get("status") == "completed" ? (
          <Link to={grid.id} style={{ whiteSpace: "nowrap" }}>
            Ver informe
          </Link>
        ) : null}{" "}
        <span style={{ display: "inline-block", margin: "0 5px" }}></span>
        {grid.get("status") == "completed" ? (
          <a
            href="#"
            onClick={() => onRemoveGrid(grid.id)}
            style={{ whiteSpace: "nowrap" }}
          >
            <i className="fa fa-trash"></i> Eliminar
          </a>
        ) : null}
      </td>
    </tr>
  )
}
