import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import { Router, navigate } from "@reach/router"

import { CircularProgress } from "@material-ui/core"

import AuthUserContext from "../Session/context"
import { withFirebase } from "../Firebase"

import PaymentsNewNav from "./nav"

import PaymentSteps from "./PaymentSteps"

import AddFunds from "./AddFunds"
import Details from "./Details"
import PaymentMethods from "./PaymentMethods"

import { CheckoutContextProvider, CheckoutContext } from "./context"
import * as actions from "./actions"

const customStyles = {
  overlay: { zIndex: 4 },
  content: {
    backgroundColor: "#242943",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    borderRadius: 0,
    padding: 0,
    zIndex: 4,
  },
}
Modal.setAppElement("#___gatsby")

export const Loading = () => (
  <div className="grid-wrapper">
    <div
      className="col-12"
      style={{
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </div>
  </div>
)

const PaymentsNew = ({ authUser, firebase }) => {
  const getPrices = actions.getPrices(firebase)
  const [loading, setLoading] = useState(true)

  let { state, dispatch } = React.useContext(CheckoutContext)

  useEffect(() => {
    setLoading(true)

    actions
      .getBillingDetails(firebase)
      .then((data) => {
        dispatch({ type: "setDetails", payload: data })

        // return firebase.products("credits").get()
        return getPrices(process.env.GASTBY_STRIPE_MAPS_PRODUCT)
      })
      .then((prices) => {
        dispatch({ type: "setProducts", payload: prices })
        // Store selected price
        const price = prices.find((p) => p.id == state?.priceId) || prices[0]

        dispatch({ type: "setProduct", payload: price.id })

        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        // TODO: mensaje toast
        console.info(error)
      })
  }, [firebase])

  if (state.paymentIntent && state.paymentIntent.status === "succeeded") {
    navigate(`/app/payments/order/${state.order.orderNumber}`, {
      replace: true,
    })
  }

  return (
    <div id="main" className="alt">
      <section>
        <PaymentSteps />
        <div className="inner">
          {loading ? (
            <Loading />
          ) : (
            <Router>
              <AddFunds path="/" />
              <Details path="/checkout" />
              <PaymentMethods path="/confirm" authUser={authUser} />
            </Router>
          )}
        </div>
      </section>
    </div>
  )
}

export default withFirebase(({ firebase }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <CheckoutContextProvider>
          <Modal
            isOpen={true}
            style={customStyles}
            contentLabel="Recargar crédito"
          >
            <PaymentsNew authUser={authUser} firebase={firebase} />
          </Modal>
        </CheckoutContextProvider>
      ) : null
    }
  </AuthUserContext.Consumer>
))

export { PaymentsNewNav }
