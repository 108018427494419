import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { formatTimestamp } from "../../utils/time"
import { useFirebase } from "../Firebase"

const Item = ({ item }) => {
  const itemUrl = `https://console.firebase.google.com/u/0/project/localrocket-prod/firestore/data/~2Fsubscriptions~2F${item.id}`
  return (
    <tr>
      <td>
        <a href={itemUrl} target="_blank" rel="noreferrer">
          {item.get("subscription_id")}
        </a>
      </td>
      <td>{item.get("subscription_status")}</td>
      <td>
        {item?.get("createdAt") &&
          formatTimestamp(item?.get("createdAt")?.toDate())}
      </td>
      <td>
        {item?.get("billing_cycle_anchor") &&
          formatTimestamp(item?.get("billing_cycle_anchor")?.toDate())}
      </td>
      <td>
        {item?.get("period_start") &&
          formatTimestamp(item?.get("period_start")?.toDate())}
      </td>
      <td>
        {item?.get("period_end") &&
          formatTimestamp(item?.get("period_end")?.toDate())}
      </td>
      <td>
        {item?.get("nextCycle") &&
          formatTimestamp(item?.get("nextCycle")?.toDate())}
      </td>
      <td>
        <Link to={`user/${item.get("user_id")}`}>Detalles</Link>
      </td>
    </tr>
  )
}

export default function Subscriptions() {
  const firebase = useFirebase()
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    firebase.firestore
      .collection("subscriptions")
      .where("payload_type", "==", "power-link")
      .orderBy("nextCycle", "desc")
      .get()
      .then((snapshot) => {
        setItems(snapshot.docs)
      })
  }, [firebase.firestore])

  return (
    <>
      <h2>Suscripciones</h2>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Estado</th>
            <th>Creación</th>
            <th>Anchor</th>
            <th>Inicio</th>
            <th>Fin</th>
            <th>Próximo ciclo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </tbody>
      </table>
    </>
  )
}
