import React from "react"

import Overview from "./Overview"
import Map from "./Map"

export default function Report({ grid }) {
  const center = grid.get("center")
  const points = grid.get("points")

  return (
    <div>
      <div className="grid-wrapper">
        <div className="col-6">
          <Overview grid={grid} />
        </div>
        <div className="col-6" style={{ position: "relative" }}>
          <Map
            initialCenter={{ lat: center.latitude, lng: center.longitude }}
            zoom={15}
            points={points}
            apiKey="AIzaSyBkzwPM29tt2ucTXQno-LxMrhN2Wqs2ams"
          />
        </div>
      </div>
    </div>
  )
}
