import React from "react"

import { withFirebase } from "../../Firebase"
import AuthUserContext from "../../Session/context"

import List from "./PowerLinksList"

export default withFirebase(({ firebase }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? <List authUser={authUser} firebase={firebase} /> : null
    }
  </AuthUserContext.Consumer>
))
