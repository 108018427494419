import React from "react"

import { useForm } from "react-hook-form"

export default function Form({ onSubmit }) {
  const {
    register,
    errors,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState,
    triggerValidation,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label>Nombre de proyecto</label>
        <input type="text" {...register('name', { required: true })} />
      </div>
      <hr />
      <div>
        <button className="button">Crear proyecto</button>
      </div>
    </form>
  );
}
