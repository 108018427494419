import React, { useEffect, useState, useContext } from "react"
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import { Router, navigate } from "@reach/router"
import { CheckoutContext } from "../context"

import Loading from "../Loading"
import SubmitButton from "./SubmitButton"
import PaymentMethods from "./PaymentMethods"
import Overview from "../Overview"

import * as actions from "../actions"

import { useToasts } from "../../FlashNotices"

export default function Payment({ firebase, location }) {
  const getOrder = actions.getOrder(firebase)

  const toasts = useToasts()
  const { state, dispatch } = useContext(CheckoutContext)

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const [loading, setLoading] = useState(true)

  const loadCurrentOrder = async (orderId) => {
    try {
      if (!orderId) throw "order/missing"

      const order = await getOrder(orderId)

      if (!order) throw "order/missing"

      dispatch({ type: "setOrder", order })
      setLoading(false)
    } catch {
      navigate("/app/subscriptions/checkout")
    }
  }

  useEffect(() => {
    const orderId = state.orderId || location.state.orderId

    loadCurrentOrder(orderId)
    toasts.error("test")
  }, [])

  const onSubmit = async (data) => {
    const { paymentSubmit } = state

    await paymentSubmit()
      .then((res) => {
        console.info(res)
        toasts.success("El pago ha sido completado con éxito.")
        navigate(`/app/subscriptions/order/${state.order.orderNumber}`, {
          replace: true,
        })
      })
      .catch((err) => {
        console.info("error", err)
        alert(err.message)
        toasts.error(err.message)
      })
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid-wrapper">
            <div className="col-8">
              <h2>Confirmación</h2>
              {loading ? (
                <Loading />
              ) : (
                <PaymentMethods order={state.order} firebase={firebase} />
              )}
            </div>
            <div className="col-4">
              <h3>Resumen del pedido</h3>
              <Overview order={state.order} />
              <hr />
              <SubmitButton />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
