import React from "react"
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import { Router, navigate } from "@reach/router"
import { CheckoutContext } from "./context"
import * as actions from "./actions"
import { Form } from "../Settings/Billing/Details"
import Overview from "./Overview"
import { withFirebase } from "../Firebase"

// TODO:
// import SalesTax from "sales-tax"
// SalesTax.setTaxOriginCountry("ES", false)
// SalesTax.toggleEnabledTaxNumberFraudCheck(false)

export default withFirebase(({ firebase }) => {
  let { state, dispatch } = React.useContext(CheckoutContext)

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      product: state.product,
      details: state.details || {},
    },
  })

  const handleDetailsSubmit = async (details) => {
    await actions
      .saveBillingDetails(firebase, details)
      .then(() => console.info("Datos guardados correctamente."))
      .then(() => {
        return actions.setOrder(state.order, {
          productId: state.productId,
          billingDetails: details,
        })
      })
      .then((order) => {
        dispatch({ type: "setOrder", payload: order })
        navigate("confirm")
      })
      .catch((err) => {
        console.info(err)
      })
  }

  const onSubmit = async (data) => {
    dispatch({ type: "setDetails", payload: data["details"] })
    await handleDetailsSubmit(data["details"])
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid-wrapper">
            <div className="col-8">
              <h2>Datos de facturación</h2>
              <Form />
            </div>
            <div className="col-4">
              <h3>Resumen del pedido</h3>
              <Overview />
              <hr />
              <DetailsButton />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
})

export const DetailsButton = () => {
  const { formState } = useFormContext()

  const className = formState.isSubmitting ? "loading" : ""
  const disabled = formState.isSubmitting || !formState.isValid

  return (
    <button className={className} disabled={disabled}>
      Continuar
    </button>
  )
}
