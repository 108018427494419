import React, { useState, useEffect } from "react"
import { Router, navigate } from "@reach/router"
import { Link } from "@reach/router"
import * as ROUTES from "../../constants/routes"
import Modal from "react-modal"

import AuthUserContext from "../Session/context"
import { withFirebase } from "../Firebase"

import { CircularProgress } from "@material-ui/core"

import MapsNav from "./nav"

import MapsList from "./MapsList"
import MapsEmpty from "./MapsEmpty"

import MapBoostForm from "../MapBoost/MapBoostForm"

import { useConfirmation, ConfirmationServiceProvider } from "../ConfirmDialog"

const customStyles = {
  overlay: { zIndex: 4, backgroundColor: "rgba(0, 0, 0, 0.7)" },
  content: {
    backgroundColor: "#242943",
    // top: "0",
    // left: "0",
    // right: "0",
    // bottom: "0",
    border: 0,
    borderRadius: 0,
    padding: "1em 2em",
    zIndex: 4,
  },
}
Modal.setAppElement("#___gatsby")

const Maps = ({ firebase, authUser }) => {
  const [loading, setLoading] = useState(true)
  const [maps, setMaps] = useState([])

  const [selectedMap, setSelectedMap] = useState(null)

  useEffect(() => {
    const unsubscribe = firebase
      .userMaps(authUser.uid)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        setMaps(querySnapshot.docs)
        setLoading(false)
      })
    return () => unsubscribe()
  }, [firebase, authUser.uid])

  const confirm = useConfirmation()

  const onSelectMap = (mapId) => {
    setSelectedMap(maps.find((map) => map.id == mapId))
  }

  const onRemoveMap = (id) => {
    confirm({
      variant: "danger",
      title: "¿Eliminar mapa?",
      description: `El mapa se eliminará. Esta operación no se puede deshacer. ¿Quieres continuar?`,
    }).then(() => {
      firebase.userMaps(authUser.uid).doc(id).delete()
    })
  }

  const onMapBoost = async (data) => {
    return Promise.resolve().then(() =>
      navigate(`/app/maps/${selectedMap.id}/boost`, { state: data })
    )
  }

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          {loading ? (
            <CircularProgress />
          ) : maps.length ? (
            <>
              <MapsList
                maps={maps}
                onRemoveMap={onRemoveMap}
                onSelectMap={onSelectMap}
                // boost={authUser.role == "admin"}
                boost={true}
              />
              {/* <p>
                Recuerda potenciar tu mapa con la opción <strong>boost</strong>{" "}
                para lograr resultados óptimos.
              </p> */}
            </>
          ) : (
            <MapsEmpty />
          )}
        </div>
      </section>
      <Modal
        isOpen={selectedMap != null}
        style={customStyles}
        contentLabel="Mapboost"
        shouldCloseOnEsc={true}
        onRequestClose={() => setSelectedMap(null)}
      >
        {selectedMap ? (
          <MapBoostForm
            map={selectedMap}
            onBoost={onMapBoost}
            close={() => setSelectedMap(null)}
          />
        ) : null}
      </Modal>
    </div>
  )
}

export default withFirebase(({ firebase }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <ConfirmationServiceProvider>
          <Maps authUser={authUser} firebase={firebase} />
        </ConfirmationServiceProvider>
      ) : null
    }
  </AuthUserContext.Consumer>
))

export { MapsNav }
