import React from "react"
import { Link } from "@reach/router"

export default function NewGridButton() {
  return (
    <Link to="new" className="button right">
      Nuevo Geo Grid
    </Link>
  )
}
