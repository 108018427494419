import React, { useState, useEffect } from "react"
import { Link } from "@reach/router"
import * as ROUTES from "../constants/routes"

import AuthUserContext from "./Session/context"
import { withFirebase } from "./Firebase"

import SignOutButton from "./Logout"

import logo from "../assets/images/logo-localrocket.png"

const CreditView = ({ firebase, authUser }) => {
  const [credits, setCredits] = useState(0)

  useEffect(() => {
    const unsubscribe = firebase.user(authUser.uid).onSnapshot((snapshot) => {
      setCredits(snapshot.get("credits") || 0)
    })
    return () => unsubscribe()
  }, [firebase, authUser.uid])

  return (
    <>
      <strong>{credits}</strong>
    </>
  )
}

const Navigation = ({ authUser, firebase }) => {
  return (
    <div className="navbar">
      <nav>
        <div
          style={{
            flex: 1,
            padding: "0 1em",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Link to="/app/" className="logo">
            <img src={logo} alt="Localrocket" />
          </Link>
        </div>

        {/* <div
          style={{
            display: "flex",
            borderLeft: "1px solid rgba(212,212,255,.1)",
            alignItems: "center",
            padding: "0 1em",
          }}
        >
          <div style={{ textAlign: "right" }}>
            Saldo
            <br />
            <CreditView firebase={firebase} authUser={authUser} />
          </div>
          <div style={{ paddingLeft: "1em" }}>
            <Link to={ROUTES.PAYMENTS_NEW} className="button small special">
              Recargar
            </Link>
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            borderLeft: "1px solid rgba(212,212,255,.1)",
            alignItems: "center",
          }}
        >
          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link">
              <span className="icon alt fa-user"></span>
              <span style={{ paddingLeft: "1em" }}>{authUser.username}</span>
            </div>

            <div className="navbar-dropdown is-right">
              {authUser.admin && (
                <Link to={ROUTES.ADMIN} className="navbar-item">
                  Admin
                </Link>
              )}
              <Link to={ROUTES.PAYMENTS} className="navbar-item">
                Pagos
              </Link>
              <Link to={ROUTES.SETTINGS} className="navbar-item">
                Configuración
              </Link>
              <a href="mailto:soporte@localrocket.me" className="navbar-item">
                Soporte
              </a>
              <hr className="navbar-divider" />
              <div className="navbar-item">
                <SignOutButton />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default withFirebase(({ firebase }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? <Navigation authUser={authUser} firebase={firebase} /> : null
    }
  </AuthUserContext.Consumer>
))
