import React from "react"
import NewButton from "./NewButton"

export default function GeoGridsEmpty() {
  return (
    <div>
      <p>No has creado geo grids.</p>
      <p>Cuando lo hagas, aparecerán aquí.</p>
      <NewButton />
    </div>
  )
}
