import React from "react"

import { withFirebase } from "../../Firebase"
import AuthUserContext from "../../Session/context"

import PowerLinksNew from "./PowerLinksNew"

export default withFirebase(({ firebase }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <PowerLinksNew authUser={authUser} firebase={firebase} />
      ) : null
    }
  </AuthUserContext.Consumer>
))
