import React from "react"
import { CircularProgress } from "@material-ui/core"

export default () => (
  <div className="grid-wrapper">
    <div
      className="col-12"
      style={{
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </div>
  </div>
)
