import fire from "firebase/app"

export const createGrid = (firebase, data) => {
  const user = require("firebase").default.auth().currentUser

  const center = new fire.firestore.GeoPoint(
    parseFloat(data.lat),
    parseFloat(data.lng)
  )

  data = {
    businessName: data.businessName,
    businessTerm: data.businessTerm,
    size: data.size,
    radius: data.radius,
    center: center,
    createdAt: fire.firestore.FieldValue.serverTimestamp(),
  }

  return firebase.userGrids(user.uid).add(data)
}
