import React, { useEffect, useState } from "react"

import AuthUserContext from "../Session/context"
import { withFirebase } from "../Firebase"

import { CircularProgress } from "@material-ui/core"
import Report from "./Report"
import GeoGridNav from "./nav"

const GeoGrid = ({ firebase, authUser, id }) => {
  const [grid, setGrid] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const unsubscribe = firebase
      .userGrids(authUser.uid)
      .doc(id)
      .onSnapshot(snapshot => {
        setGrid(snapshot)
        setLoading(false)
      })
    return () => unsubscribe()
  }, [firebase, authUser.uid])

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Report grid={grid} />
          )}
        </div>
      </section>
    </div>
  )
}

export default withFirebase(({ firebase, id }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <GeoGrid authUser={authUser} firebase={firebase} id={id} />
      ) : null
    }
  </AuthUserContext.Consumer>
))

export { GeoGridNav }
