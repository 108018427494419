// const SUBSCRIPTIONS_BASE_URL =
//   "http://localhost:5001/maps-7f983/europe-west1/subscriptions-http"
const SUBSCRIPTIONS_BASE_URL = process.env.GATSBY_SUBSCRIPTIONS_URL

/* GET PRICES */
export const getPrices = (firebase) => async (productId) => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
  }

  const url = `${SUBSCRIPTIONS_BASE_URL}/prices/${productId}`

  return fetch(url, { headers })
    .then((response) => response.json())
    .then((prices) =>
      prices.prices
        .filter((price) => price.active)
        .sort((price1, price2) => price1.unit_amount - price2.unit_amount)
    )
}

/* GET BILLING DETAILS */
export const getBillingDetails = (firebase) => async () => {
  const { currentUser } = firebase.auth

  const snapshot = await firebase.billingDetails(currentUser.uid).get()

  return {
    vatnumber: snapshot.get("vatnumber"),
    name: snapshot.get("name"),
    address1: snapshot.get("address1"),
    address2: snapshot.get("address2"),
    postalcode: snapshot.get("postalcode"),
    city: snapshot.get("city"),
    state: snapshot.get("state"),
    country: snapshot.get("country"),
  }
}

/* UPDATE BILLING DETAILS */
export const updateBillingDetails = (firebase) => async (details) => {
  const { currentUser } = firebase.auth

  const data = {
    vatnumber: details.vatnumber || "",
    name: details.name || "",
    address1: details.address1 || "",
    address2: details.address2 || "",
    postalcode: details.postalcode || "",
    city: details.city || "",
    state: details.state || "",
    country: details.country || "",
  }

  const billing = await firebase.billingDetails(currentUser.uid).get()

  if (billing.exists) {
    await billing.ref.update(data)
  } else {
    await billing.ref.set(data)
  }
}

export const getPayment = async (firebase) => {
  const authUser = await firebase.user().get()

  const url = `${process.env.GATSBY_PAYMENT_URL}/intents/${authUser.id}`

  const params = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  return fetch(url, params)
    .then((res) => res.json())
    .then((json) => {
      return json
    })
    .catch((error) => {
      console.info("Ha ocurrido un error:")
      console.info(error)
      throw error
    })
}

/* SET ORDER */
export const setOrder = (firebase) => (order, opts) =>
  order ? updateOrder(firebase)(order, opts) : createOrder(firebase)(opts)

// Create order
export const createOrder = (firebase) => async (opts) => {
  console.info("createOrder")
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
    "Content-Type": "application/json",
  }

  const { productId, priceId, billingDetails, payload } = opts

  return await fetch(`${SUBSCRIPTIONS_BASE_URL}/orders`, {
    method: "POST",
    headers,
    body: JSON.stringify({ productId, priceId, billingDetails, payload }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.info(err)
      throw err
    })
}

export const updateOrder = (firebase) => async (order, opts) => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
    "Content-Type": "application/json",
  }

  const { productId, priceId, billingDetails } = opts

  return await fetch(`${SUBSCRIPTIONS_BASE_URL}/orders/${order.id}`, {
    method: "PUT",
    headers,
    body: JSON.stringify({ productId, priceId, billingDetails }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.info(err)
      throw err
    })
}

export const getOrder = (firebase) => async (orderId) => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
    "Content-Type": "application/json",
  }

  return fetch(`${SUBSCRIPTIONS_BASE_URL}/orders/${orderId}`, {
    method: "GET",
    headers,
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((error) => {
      console.info("Ha ocurrido un error:")
      console.info(error)
      throw error
    })
}

/**
 *
 */
export const createPaymentSubmit =
  (firebase) => async (paymentMethod, order, stripe) => {
    const SUBSCRIPTIONS_BASE_URL = process.env.GATSBY_SUBSCRIPTIONS_URL
    const { currentUser } = firebase.auth

    const headers = {
      Authorization: await currentUser.getIdToken(),
      "Content-Type": "application/json",
    }

    const url = `${SUBSCRIPTIONS_BASE_URL}/subscriptions`

    return fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify({
        paymentMethod: paymentMethod.id,
        orderId: order.id,
      }),
    })
      .then((response) => response.json())
      .then((subscription) => {
        console.info(subscription)
        if (subscription && subscription.status === "active") {
          // Subscription is active, no customer actions required.
          console.info("Suscripción creada con éxito y activa")
          return { subscription }
        }
        let paymentIntent = subscription.latest_invoice.payment_intent

        if (
          paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_payment_method"
        ) {
          console.info("confirmando tarjeta, acción necesaria")
          return stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentMethod.id,
            })
            .then((result) => {
              if (result.error) {
                // Start code flow to handle updating the payment details.
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc).
                console.info("error con el pago", result.error)
                throw result.error
              } else {
                if (result.paymentIntent.status === "succeeded") {
                  // Show a success message to your customer.
                  console.info("Se ha procesado con éxito")
                  return { subscription }
                }
              }
            })
        }
      })
  }

/**
 * Crea y obtiene order y pago actual.
 *
 * @param {*} firebase
 * @param {*} credits
 */
export const createPayment = (firebase) => async (order) => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
    "Content-Type": "application/json",
  }

  return fetch(`${SUBSCRIPTIONS_BASE_URL}/subscriptions`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      orderId: order.id,
    }),
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((error) => {
      console.info("Ha ocurrido un error:")
      console.info(error)
      throw error
    })
}

export const confirmCardPayment =
  (stripe) =>
  async ({ ccElement, paymentIntent, billingDetails }) => {
    // You can also use handleCardPayment with the PaymentIntents API.
    // See our handleCardPayment documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment
    const response = await stripe.confirmCardPayment(
      paymentIntent.clientSecret,
      {
        payment_method: {
          card: ccElement,
          billing_details: billingDetails,
        },
      }
    )

    if (response.error) {
      // Handle payment error
      throw response.error
    }

    if (
      response.paymentIntent &&
      response.paymentIntent.status === "succeeded"
    ) {
      // Handle payment success
      return response.paymentIntent
    } else {
      // Handle unknown error
      throw {
        code: "unknown",
        message:
          "Ha ocurrido un error procesando el pago. Vuelve a intentarlo.",
      }
    }
  }
