import React from "react"

import PasswordForgetForm from "../components/PasswordForget"
import { LoginLink } from "../components/Login"
import { RegisterLink } from "../components/Register"

export default function PasswordForgetPage() {
  return (
    <div id="main" className="alt">
      <section className="grid-wrapper">
        <div className="inner col-3"></div>
        <div className="inner col-6">
          <header className="major">
            <h1>Recupera tu contraseña</h1>
          </header>
          <p>
            Indica tu dirección de correo eletrónico a continuación y te
            enviaremos un mensaje con más instrucciones.
          </p>
          <PasswordForgetForm />
          <LoginLink />
          <RegisterLink />
        </div>
      </section>
    </div>
  )
}
