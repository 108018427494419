import React from "react"
import NewButton from "./NewButton"

export default () => {
  return (
    <div>
      <p>No has creado búsquedas de marca.</p>
      {/* <p>Cuando lo hagas, aparecerán aquí.</p> */}
      {/* <NewButton /> */}
    </div>
  )
}
