import React from "react"
import { useForm, FormProvider, useFormContext } from "react-hook-form"

import { withFirebase } from "../Firebase"
import AuthUserContext from "../Session/context"

import { useToasts } from "../FlashNotices"

import { CircularProgress } from "@material-ui/core"

export const Form = () => {
  const { register, errors, formState } = useFormContext()

  const disabled = formState.isSubmitting

  return <>
    <div className="field">
      <label>Nombre*</label>
      <input
        type="text"
        {...register('user.username', { required: true })}
        disabled={disabled} />
      {errors["details.name"] && (
        <div className="error">
          <span>Este campo es obligatorio</span>
        </div>
      )}
    </div>

    <div className="field">
      <label>Email*</label>
      <input
        type="text"
        {...register('user.email', { required: true })}
        disabled={true} />
      {errors["user.email"] && (
        <div className="error">
          <span>Este campo es obligatorio</span>
        </div>
      )}
    </div>
  </>;
}

const AccountForm = ({ authUser, onSubmit }) => {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      user: {
        username: authUser.username,
        email: authUser.email,
      },
    },
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Form />
        <AccountButton />
      </form>
    </FormProvider>
  )
}

export default withFirebase(({ authUser, firebase }) => {
  const { success, error } = useToasts()

  const handleSubmit = async (data) => {
    // await saveBillingDetails(firebase, data.details)
    //   .catch(error => error("Ha ocurrido un error guardando los datos."))
    //   .then(() => success("Datos guardados correctamente."))
  }

  return (
    <>
      <h2>Tu cuenta</h2>
      <AuthUserContext.Consumer>
        {(authUser) =>
          !authUser ? (
            <CircularProgress />
          ) : (
            <AccountForm authUser={authUser} onSubmit={handleSubmit} />
          )
        }
      </AuthUserContext.Consumer>
    </>
  )
})

export const AccountButton = () => {
  const { formState } = useFormContext()
  const className = formState.isSubmitting ? "loading" : ""
  const isDisabled = formState.isSubmitting || !formState.isValid

  return (
    <button className={className} disabled={isDisabled}>
      Guardar
    </button>
  )
}
