import React, { useContext } from "react"
import { Link } from "@reach/router"
import { AuthUserContext } from "../Session"

import { Badge } from "@material-ui/core"
import iconMap from "../../assets/images/dashboard/icon-map-stacking.png"
import iconGeo from "../../assets/images/dashboard/icon-geo-tagging.png"
import iconRank from "../../assets/images/dashboard/icon-rank-tracker.png"
import iconBrandedMapsSearches from "../../assets/images/dashboard/icon-branded-maps-searches.png"

import ProjectsHome from "./Projects"

export default function Home() {
  const authUser = useContext(AuthUserContext)

  const buttons = []
  buttons.push({ icon: iconMap, text: "Mapas Fractales", url: "maps" })
  buttons.push({ icon: iconGeo, text: "Image Geo Tagging", url: "geotags" })
  if (authUser && authUser.roles && authUser.roles.includes("admin")) {
    // buttons.push({ icon: iconMap, text: "GeoGrid", url: "geogrids" })
  }
  buttons.push({
    icon: iconBrandedMapsSearches,
    text: "Branded Maps Searches",
    url: "branded-maps-searches",
  })
  buttons.push({
    icon: iconRank,
    text: "Local Rank Tracker",
    url: "https://collac.io",
  })

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <header className="major">
            <h2>¡Bienvenido/a, {authUser.username}!</h2>
          </header>

          {/* {authUser && authUser.roles && authUser.roles.includes("admin") ? (
            <ProjectsHome />
          ) : null} */}

          <h3>Servicios</h3>
          <div className="grid-items services">
            {buttons.map((button, index) => (
              <div
                key={index}
                className="grid-item service"
                style={{ paddingBottom: "1em" }}
              >
                <Badge
                  color="primary"
                  badgeContent={"Próximamente"}
                  invisible={!!button.url}
                >
                  {button.url.startsWith("http") ? (
                    <a
                      href={button.url}
                      style={{ border: "none", textAlign: "center" }}
                      className={`${!button.url ? "disabled" : ""}`}
                    >
                      <img
                        src={button.icon}
                        alt={button.text}
                        style={{ width: 100 }}
                      />
                      <br />
                      <span>{button.text}</span>
                    </a>
                  ) : (
                    <Link
                      to={button.url ? button.url : "#"}
                      style={{ border: "none", textAlign: "center" }}
                      className={`${!button.url ? "disabled" : ""}`}
                    >
                      <img
                        src={button.icon}
                        alt={button.text}
                        style={{ width: 100 }}
                      />
                      <br />
                      <span>{button.text}</span>
                    </Link>
                  )}
                </Badge>
              </div>
            ))}
          </div>
          <hr className="major" />

          <h3>Soporte</h3>
          <p>
            ¿Necesitas ayuda? Envíanos un email a{" "}
            <a href="mailto:soporte@localrocket.me">soporte@localrocket.me</a>.
          </p>
        </div>
      </section>
    </div>
  )
}
