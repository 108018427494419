import React from "react"

import { withFirebase } from "../Firebase"
import AuthUserContext from "../Session/context"

import GeoGridNewNav from "./nav"
import Form from "./Form"
import { ConfirmationServiceProvider } from "../ConfirmDialog"

const GeoGridNew = ({ authUser, firebase }) => {
  return (
    <ConfirmationServiceProvider>
      <Form authUser={authUser} firebase={firebase} />
    </ConfirmationServiceProvider>
  )
}

export default withFirebase(({ firebase }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? <GeoGridNew authUser={authUser} firebase={firebase} /> : null
    }
  </AuthUserContext.Consumer>
))

export { GeoGridNewNav }
