import React, { useRef } from "react"
import { useForm, FormProvider, useFormContext } from "react-hook-form"

import fire from "firebase/app"
import { withFirebase } from "../Firebase"
import AuthUserContext from "../Session/context"

import { useToasts } from "../FlashNotices"

import { CircularProgress } from "@material-ui/core"

export const Form = () => {
  const { register, errors, formState, watch } = useFormContext()

  const password = useRef({})
  password.current = watch("password", "")

  const disabled = formState.isSubmitting

  return <>
    <div className="field">
      <label>Contraseña actual*</label>
      <input
        type="password"
        {...register('current_password', { required: true })}
        disabled={disabled} />
      {errors["current_password"] && (
        <div className="error">
          <span>Este campo es obligatorio</span>
        </div>
      )}
    </div>
    <div className="field">
      <label>Contraseña nueva*</label>
      <input
        type="password"
        {...register('password', { required: true })}
        disabled={disabled} />
      {errors["password"] && (
        <div className="error">
          <span>Este campo es obligatorio</span>
        </div>
      )}
    </div>
    <div className="field">
      <label>Confirmar contraseña*</label>
      <input
        type="password"
        {...register('current_confirmation', {
          required: true,
          validate: (value) =>
            value === password.current ||
            "La confirmación de contraseña no es válida.",
        })}
        disabled={disabled} />
      {errors["current_confirmation"] && (
        <div className="error">
          {errors["current_confirmation"]["type"] === "required" && (
            <span>Este campo es obligatorio</span>
          )}
          {errors["current_confirmation"]["type"] === "validate" && (
            <span>{errors["current_confirmation"]["message"]}</span>
          )}
        </div>
      )}
    </div>
  </>;
}

const SecurityForm = ({ authUser, onSubmit }) => {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {},
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Form />
        <SecurityButton />
      </form>
    </FormProvider>
  )
}

export default withFirebase(({ authUser, firebase }) => {
  const { success, error } = useToasts()

  const handleSubmit = async (data) => {
    const user = fire.auth().currentUser

    // const provider = new fire.auth.GoogleAuthProvider()
    // provider.addScope("profile")
    // provider.addScope("email")

    // await user
    //   .reauthenticateWithPopup(provider)
    //   .then(() => console.log("Ok"))
    //   .catch(err => console.info(err))

    // user
    //   .reauthenticateWithCredential(data.current_password)
    //   .then(function() {
    //     // User re-authenticated.
    //     console.info("re auth")
    //   })
    //   .catch(error => {
    //     console.info("::::::::")
    //     // An error happened.
    //     console.info(error)
    //     // if (error.code === "auth/argument-error") {
    //     //   console.info("meh")
    //     //   setError(
    //     //     "current_password",
    //     //     "notMatch",
    //     //     "La contraseña indicada no es correcta."
    //     //   )
    //     // }
    //   })

    console.info(data)

    await user
      .updatePassword(data.password)
      .then(function () {
        // Update successful.
        console.info(":)")
      })
      .catch(function (error) {
        // An error happened.

        // setError("username", "notMatch", "please choose a different username")
        console.info("bad", error)
      })
  }

  return (
    <>
      <AuthUserContext.Consumer>
        {(authUser) =>
          !authUser ? (
            <>
              <h2>Tu cuenta</h2>
              <CircularProgress />
            </>
          ) : (
            <>
              <h2>Cambiar la contraseña</h2>
              <SecurityForm authUser={authUser} onSubmit={handleSubmit} />
            </>
          )
        }
      </AuthUserContext.Consumer>
    </>
  )
})

export const SecurityButton = () => {
  const { formState } = useFormContext()
  const className = formState.isSubmitting ? "loading" : ""
  const disabled = formState.isSubmitting || !formState.isValid
  return (
    <button className={className} disabled={disabled}>
      Guardar
    </button>
  )
}
