import React, { useEffect, useState } from "react"
import { Link } from "@reach/router"
import AuthUserContext from "../Session/context"
import { withFirebase } from "../Firebase"
import Loading from "../Loading"

const Thanks = ({ id, firebase }) => {
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState(null)

  useEffect(() => {
    firebase
      .orders()
      .where("orderNumber", "==", parseInt(id))
      .limit(1)
      .onSnapshot(querySnapshot => {
        if (querySnapshot.empty) {
          setOrder(null)
        } else {
          setOrder(querySnapshot.docs[0])
        }
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div id="main" className="alt">
        <section>
          <div className="inner">
            <Loading />
          </div>
        </section>
      </div>
    )
  }

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <div className="grid-wrapper">
            <div className="col-2"></div>
            <div className="col-8" style={{ textAlign: "center" }}>
              {order !== null ? (
                <>
                  {order.get("status") == "succeeded" ? (
                    <h2>¡Tu pago se ha realizado con éxito!</h2>
                  ) : (
                    <h2>¡Tu pago se ha realizado con éxito!</h2>
                  )}

                  <p>
                    <small style={{ opacity: 0.5 }}>
                      Pedido {order.get("orderNumber")}
                    </small>
                  </p>

                  {order.get("status") == "succeeded" ? (
                    order.get("credits") ? (
                      <p>El crédito se añadirá pronto a tu cuenta.</p>
                    ) : (
                      <p>
                        ¡Gracias! Hemos recibido tu solicitud y nos pondremos en
                        contacto contigo.
                      </p>
                    )
                  ) : (
                    <p>¡Gracias! Estamos validando la transacción.</p>
                  )}
                </>
              ) : (
                <h2>Lo sentimos, no se ha encontrado el pedido.</h2>
              )}
              {order.get("invoicePdf") || order.get("invoiceUrl") ? (
                <>
                  <hr />
                  {order.get("invoiceUrl") ? (
                    <a
                      href={order.get("invoiceUrl")}
                      target="_blank"
                      className="button"
                    >
                      Ver Recibo
                    </a>
                  ) : null}{" "}
                  {order.get("invoicePdf") ? (
                    <a href={order.get("invoicePdf")} className="button">
                      Descargar factura
                    </a>
                  ) : null}
                </>
              ) : null}
              <hr />
              <Link to="/app/" className="button special">
                Ir al Dashboard
              </Link>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withFirebase(({ firebase, id }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <Thanks firebase={firebase} authUser={authUser} id={id} />
      ) : null
    }
  </AuthUserContext.Consumer>
))
