import React, { useState, useEffect } from "react"

import { CircularProgress } from "@material-ui/core"

import PowerLinksEmpty from "./PowerLinksEmpty"
import NewButton from "./NewButton"
import PowerLinkListItem from "./PowerLinksListItem"

import * as actions from "../actions"

const PowerLinksList = ({ links, onRefresh }) => {
  return (
    <div className="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Proyecto</th>
            <th>Suscripción</th>
            <th>Estado</th>
            <th>Inicio</th>
            <th>Fin</th>

            <th>Realizadas</th>
            <th>Fallidas</th>
            <th>% éxito</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {links.map((link) => (
            <PowerLinkListItem
              key={link.id}
              uid={link.uid}
              link={link}
              onRefresh={onRefresh}
              // name={link.get("businessName")}
              // createdAt={map.get("createdAt").toDate()}
              // filePath={map.get("filePath")}
              // onSelectMap={onSelectMap}
              // onRemoveMap={onRemoveMap}
              // boost={boost}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ({ firebase, authUser }) => {
  const refreshPowerLinks = actions.refreshPowerLinks(firebase)

  const [loading, setLoading] = useState(true)
  const [links, setLinks] = useState([])

  useEffect(() => {
    const unsubscribe = firebase
      .userPowerLinks(authUser.uid)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        setLinks(querySnapshot.docs)
        setLoading(false)
      })
    return () => unsubscribe()
  }, [firebase, authUser.uid])

  useEffect(() => {
    if (links.length) {
      //refreshPowerLinks(links)
    }
  }, [links])

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          {loading ? (
            <CircularProgress />
          ) : links.length ? (
            <>
              {/* <NewButton />
              <hr /> */}
              <PowerLinksList links={links} />
            </>
          ) : (
            <PowerLinksEmpty />
          )}
        </div>
      </section>
    </div>
  )
}
