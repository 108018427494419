/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Router } from "@reach/router"
import toast, { Toaster } from "react-hot-toast"

import { withAuthorization, AuthUserContext } from "../Session"

import Navigation from "../navigation"
import HomeNav from "./nav"

import Payments, { PaymentsNav } from "../Payments"
import Payment from "../Payment"
import PaymentsNew, { PaymentsNewNav } from "../PaymentsNew"
import PaymentThanks from "../PaymentsNew/Thanks"
import Subscriptions, { SubscriptionsNav } from "../Subscriptions"
import Home from "./Home"
import Maps, { MapsNav } from "../Maps"
import MapBoost, { MapBoostNav } from "../MapBoost"
import MapsNew, { MapsNewNav } from "../MapsNew"
import GeoTags, { GeoTagsNav } from "../GeoTags"
import GeoGrids, { GeoGridsNav } from "../GeoGrids"
import GeoGrid, { GeoGridNav } from "../GeoGrid"
import GeoGridNew, { GeoGridNewNav } from "../GeoGridNew"
import PowerLinks, { PowerLinksNav } from "../PowerLinks"

import { ProjectsNew } from "../Projects"
import Coupons from "../Coupons"
import Settings from "../Settings"
import Admin from "../Admin"

import { FlashNoticesProvider } from "../FlashNotices"
import { useAuth } from "../Session/withAuthentication"

// TODO:
// const condition = authUser => !!authUser
// const DashboardPage = compose(withAuthorization(condition))(Dashboard)

const Breadcrumbs = () => {
  return (
    <div className="breadcrumbs">
      <nav>
        {/* we don't want this taking focus */}
        <Router primary={false}>
          <HomeNav path="/" />
          <PaymentsNav path="payments" />
          <PaymentsNewNav path="payments/new/*" />
          {/* <SubscriptionsNav path="subscriptions/*" /> */}
          <MapsNav path="maps" />
          <MapBoostNav path="maps/:mapId/boost" />
          <MapsNewNav path="maps/new" />
          <GeoTagsNav path="geotags" />
          <GeoGridsNav path="geogrids" />
          <GeoGridNav path="geogrids/:id" />
          <GeoGridNewNav path="geogrids/new" />
        </Router>
      </nav>
    </div>
  )
}

export default function DashboardPage() {
  // return <DashboardPage />
  const authUser = useAuth()

  return (
    <div className="app">
      <Navigation authUser={authUser} />
      <Breadcrumbs />
      <FlashNoticesProvider>
        <Router>
          <Home path="/" />
          <Payments path="payments" />
          <PaymentThanks path="payments/order/:id" />
          <PaymentsNew path="payments/new/*" />
          <Payment path="payments/:order/pay" />
          <Subscriptions path="subscriptions/*" />
          <Maps path="maps" />
          <MapsNew path="maps/new" />
          <MapBoost path="maps/:mapId/boost/*" />
          <GeoTags path="geotags" />
          <PowerLinks path="branded-maps-searches/*" />
          <Settings path="settings/*" />
          <Coupons path="redeem" />
          <Coupons path="redeem/:code" />
          {/*
          <GeoGrids path="geogrids" />
          <GeoGrid path="geogrids/:id" />
          <GeoGridNew path="geogrids/new" />
          <ProjectsNew path="projects/new" />
        */}

          {authUser.admin && <Admin path="admin/*" />}
        </Router>
        <Toaster />
      </FlashNoticesProvider>
    </div>
  )
}
