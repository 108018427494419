import React, { useEffect, useState } from "react"
import { formatTimestamp } from "../../utils/time"
import { useFirebase } from "../Firebase"
import Loading from "../Loading"
import { FirebaseLink } from "./utils"

const SubscriptionInfo = ({ item }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Estado</th>
          <th>Creación</th>
          <th>Anchor</th>
          <th>Inicio</th>
          <th>Fin</th>
          <th>Próximo ciclo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <FirebaseLink path={["subscriptions", item.id]}>
              {item.get("subscription_id")}
            </FirebaseLink>
          </td>
          <td>{item.get("subscription_status")}</td>
          <td>
            {item?.get("createdAt") &&
              formatTimestamp(item?.get("createdAt")?.toDate())}
          </td>
          <td>
            {item?.get("billing_cycle_anchor") &&
              formatTimestamp(item?.get("billing_cycle_anchor")?.toDate())}
          </td>
          <td>
            {item?.get("period_start") &&
              formatTimestamp(item?.get("period_start")?.toDate())}
          </td>
          <td>
            {item?.get("period_end") &&
              formatTimestamp(item?.get("period_end")?.toDate())}
          </td>
          <td>
            {item?.get("nextCycle") &&
              formatTimestamp(item?.get("nextCycle")?.toDate())}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const PowerLinkInfo = ({ link }) => {
  return (
    <tr>
      <td>
        <FirebaseLink path={["power-links", link.id]}>{link.id}</FirebaseLink>
      </td>

      <td>
        <FirebaseLink
          path={[
            "users",
            link.get("uid"),
            "power-links",
            link.id,
            "slots",
            link.get("remoteId"),
          ]}
        >
          {link.get("remoteId")}
        </FirebaseLink>
      </td>

      <td>{link.get("status")}</td>

      <td>{link.get("subscriptionStatus")}</td>
      <td>{formatTimestamp(link.get("subscriptionStart").toDate())}</td>
      <td>{formatTimestamp(link.get("subscriptionEnd").toDate())}</td>
      <td>{formatTimestamp(link.get("updatedAt").toDate())}</td>
    </tr>
  )
}

const ScheduleInfo = ({ schedule }) => {
  const s = schedule
  return (
    <tr>
      <td>
        <FirebaseLink path={["scheduled-tasks", s.id]}>
          {s.get("object_id")}
        </FirebaseLink>
      </td>
      <td>{s.get("auto") ? "Sí" : "No"}</td>
      <td>{formatTimestamp(s.get("run_at").toDate())}</td>
      <td>{formatTimestamp(s.get("ends_at").toDate())}</td>
      <td>({s.get("run_at") < s.get("ends_at") ? "Activo" : "Finalizado"})</td>
    </tr>
  )
}

const Subscription = ({ item }) => {
  const { firestore } = useFirebase()
  const [powerLinks, setPowerLinks] = useState()
  const [schedules, setSchedules] = useState()

  useEffect(() => {
    const subscription = firestore
      .collection("power-links")
      .where("subscriptionId", "==", item.id)
      .get()
      .then((snapshot) => {
        setPowerLinks(snapshot.docs)
      })
    return () => subscription
  }, [firestore, item])

  useEffect(() => {
    const subscription = firestore
      .collection("scheduled-tasks")
      .where("subscription", "==", item.id)
      .get()
      .then((snapshot) => {
        setSchedules(snapshot.docs)
      })
    return () => subscription
  }, [firestore, item])

  return (
    <>
      <SubscriptionInfo item={item} />
      <div style={{ margin: "0 2em 2em 2em" }}>
        <h3>Búsquedas</h3>
        <table>
          <thead>
            <tr>
              <th>id</th>
              <th>remoteId</th>
              <th>estado</th>
              <th>suscripción</th>
              <th>suscripción start</th>
              <th>suscripción end</th>
              <th>Actualizado</th>
            </tr>
          </thead>
          <tbody>
            {powerLinks &&
              powerLinks.map((link) => (
                <PowerLinkInfo key={link.id} link={link} />
              ))}
          </tbody>
        </table>
        <hr />
        <h3>Cron</h3>
        <table>
          <thead>
            <tr>
              <th>link id</th>
              <th>auto</th>
              <th>Próxima ejecución</th>
              <th>Finalización:</th>
              <th>Estado:</th>
            </tr>
          </thead>
          <tbody>
            {schedules &&
              schedules.map((s) => <ScheduleInfo key={s.id} schedule={s} />)}
          </tbody>
        </table>
      </div>
    </>
  )
}

const UserInfo = ({ id, ...user }) => {
  const { firestore } = useFirebase()
  const [subscriptions, setSubscriptions] = useState()

  useEffect(() => {
    const subscription = firestore
      .collection("subscriptions")
      .where("user_id", "==", id)
      .get()
      .then((snapshot) => {
        setSubscriptions(snapshot.docs)
      })
    return () => subscription
  }, [firestore, id])

  return (
    <div>
      <FirebaseLink path={["users", id]}>{user.username}</FirebaseLink>(
      <a
        href={`https://dashboard.stripe.com/customers/${user.customer_id}`}
        target="_blank"
        rel="noreferrer"
      >
        Stripe
      </a>
      )
      <hr />
      <h3>Suscripciones</h3>
      {subscriptions &&
        subscriptions.map((s) => <Subscription key={s.id} item={s} />)}
    </div>
  )
}

export default function UserSubscriptions({ user_id }) {
  const { firestore } = useFirebase()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()

  useEffect(() => {
    firestore
      .collection("users")
      .doc(user_id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUser(doc)
          setLoading(false)
        }
      })
  }, [firestore, user_id])

  return (
    <div>
      {loading ? <Loading /> : <UserInfo id={user.id} {...user.data()} />}
    </div>
  )
}
