import React, { useEffect, useState } from "react"
import { Map, GoogleApiWrapper } from "google-maps-react"

// Position lat, position lng, offset in metters n and offset in metters e
const getPointFromDistance = (lat, lng, dn, de) => {
  //Earth’s radius, sphere
  const R = 6378137

  //Coordinate offsets in radians
  const dLat = dn / R
  const dLng = de / (R * Math.cos((Math.PI * lat) / 180))

  //OffsetPosition, decimal degrees
  const latO = lat + (dLat * 180) / Math.PI
  const lngO = lng + (dLng * 180) / Math.PI

  return [latO, lngO]
}

// Dado un punto geofrafico, un tamaño de grid y un radio obtiene un lsitado con los
// puntos.
const calculateGridPoints = (centerLat, centerLng, gridSize, radius) => {
  const side = Math.floor(gridSize / 2)
  const incDistance = radius / side

  let points = []
  for (let i = -side; i <= side; i++) {
    for (let j = -side; j <= side; j++) {
      if (i == 0 && j == 0) continue
      const [lat, lng] = getPointFromDistance(
        parseFloat(centerLat),
        parseFloat(centerLng),
        incDistance * i,
        incDistance * j
      )

      points.push({ lat, lng })
    }
  }

  return points
}

const MapContainerBase = ({
  google,
  initialCenter,
  onChangePosition,
  grid,
  gridSize,
  radius,
}) => {
  const [position, setPosition] = useState(initialCenter)
  const [points, setPoints] = useState([])

  useEffect(() => {
    if (initialCenter && initialCenter.lat && initialCenter.lng) {
      setPosition(initialCenter)
    }

    const points = calculateGridPoints(
      parseFloat(initialCenter.lat),
      parseFloat(initialCenter.lng),
      gridSize,
      radius
    )
    setPoints(points)
  }, [initialCenter, gridSize, radius])

  const moveMarker = (props, marker, e) => {
    setPosition({
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    })

    onChangePosition &&
      onChangePosition({
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      })
  }

  let otherProps = {}
  if (initialCenter) {
    otherProps = { initialCenter: initialCenter }
  }

  return (
    <Map
      google={google}
      zoom={6}
      zoomControl={true}
      mapTypeControl={false}
      scaleControl={false}
      rotateControl={false}
      streetViewControl={false}
      fullscreenControl={false}
      initialCenter={position}
      onReady={(mapProps, map) => {
        const { google } = mapProps

        new google.maps.Rectangle({
          fillColor: "white",
          fillOpacity: 0.5,
          map: map,
          bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(-90, -180),
            new google.maps.LatLng(90, 180)
          ),
        })

        if (!grid) return

        // Get map position
        const position = {
          lat: parseFloat(mapProps.initialCenter.lat),
          lng: parseFloat(mapProps.initialCenter.lng),
        }

        // Creating marker in position
        const marker = new google.maps.Marker({
          icon: {
            anchor: new google.maps.Point(10, 10),
            url:
              'data:image/svg+xml;utf-8,<svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50" overflow="inherit"><path d="M25 1c-13.234 0-24 10.766-24 24 0 13.233 10.766 24 24 24 13.233 0 24-10.767 24-24 0-13.234-10.767-24-24-24zm3 43.75v-8.75h-6v8.75c-8.625-1.307-15.443-8.125-16.75-16.75h8.75v-6h-8.75c1.307-8.625 8.125-15.443 16.75-16.75v8.75h6v-8.75c8.625 1.307 15.443 8.125 16.75 16.75h-8.75v6h8.75c-1.307 8.625-8.125 15.443-16.75 16.75z"/></svg>',
          },
          position: position,
          map,
          draggable: true,
        })

        // On drag
        marker.addListener("drag", evt => {
          const latLng = { lat: evt.latLng.lat(), lng: evt.latLng.lng() }
          const points = calculateGridPoints(
            latLng.lat,
            latLng.lng,
            gridSize,
            radius
          )
          markers.forEach((m, i) => m.setPosition(points[i]))
        })

        // On end drag
        marker.addListener("dragend", evt => moveMarker({}, marker, evt))

        // Creating grid markers
        const markers = points.map(
          point =>
            new google.maps.Marker({
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeWeight: 2,
                strokeColor: "red",
              },
              position: point,
              map,
              draggable: false,
            })
        )

        // Fit bounds
        const bounds = new google.maps.LatLngBounds()
        markers
          .filter(m => m.getVisible())
          .forEach(m => bounds.extend(m.getPosition()))
        map.fitBounds(bounds)
      }}
      {...otherProps}
    ></Map>
  )
}

export default GoogleApiWrapper(props => ({
  apiKey: props.apiKey,
  language: props.language,
  ...props,
}))(MapContainerBase)
