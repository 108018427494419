import React, { useEffect } from "react"

const Ctx = React.createContext()

const FlashNoticesContainer = props => (
  <div style={{ padding: "1em 1em 0 1em" }} {...props} />
)

const FlashNotice = ({ children, type, onDismiss }) => {
  const className = `col-12 box ${type || "notice"}`

  return (
    <div className="grid-wrapper">
      <div className={className} onClick={onDismiss}>
        {children}
      </div>
    </div>
  )
}

let toastCount = 0

export function FlashNoticesProvider({ children }) {
  const [toasts, setToasts] = React.useState([])

  useEffect(() => {
    const timer = setTimeout(() => setToasts([]), 5000)
    return () => clearTimeout(timer)
  }, [toasts])

  const add = (content, type) => {
    const id = toastCount++

    const toast = { content, type, id }
    // setToasts([...toasts, toast])
    setToasts([toast])
  }

  const success = content => add(content, "success")

  const error = content => add(content, "error")

  const remove = id => {
    const newToasts = toasts.filter(t => t.id !== id)
    setToasts(newToasts)
  }
  // avoid creating a new fn on every render
  const onDismiss = id => () => remove(id)

  return (
    <Ctx.Provider value={{ add, success, error, remove }}>
      {toasts.length ? (
        <FlashNoticesContainer>
          {toasts.map(({ content, id, type, ...rest }) => (
            <FlashNotice
              key={id}
              type={type}
              FlashNotice={FlashNotice}
              onDismiss={onDismiss(id)}
              {...rest}
            >
              {content}
            </FlashNotice>
          ))}
        </FlashNoticesContainer>
      ) : null}
      {children}
    </Ctx.Provider>
  )
}

// Consumer
// ==============================

export const useToasts = () => React.useContext(Ctx)
