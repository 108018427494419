import React from "react"
import NewButton from "./NewButton"

export default () => {
  return (
    <div>
      <p>No has creado mapas.</p>
      <p>Cuando lo hagas, los mapas aparecerán aquí.</p>
      <NewButton />
    </div>
  )
}
