/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from "react"
import { Link } from "@reach/router"
import { FirebaseContext } from "../Firebase"
import { formatTimestamp } from "../../utils/time"

export default (props) => {
  const firebase = useContext(FirebaseContext)

  const [filePath, setFilePath] = useState(props.filePath)
  const [loading, setLoading] = useState(!!props.filePath)
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    const unsubscribe = props.map.ref.onSnapshot((snapshot) => {
      setFilePath(snapshot.get("filePath"))
      setLoading(!!snapshot.get("filePath"))
    })
    return () => unsubscribe()
  }, [props.map])

  // https://firebase.google.com/docs/storage/web/download-files
  const download = (event) => {
    event.preventDefault()

    setDownloading(true)

    const storage = firebase.storage
    const pathReference = storage.ref(filePath)

    storage
      .ref()
      .child(filePath)
      .getDownloadURL()
      .then(function (url) {
        // This can be downloaded directly:
        var xhr = new XMLHttpRequest()
        xhr.responseType = "blob"
        xhr.onload = function (event) {
          // Create a new Blob object using the response data of the onload object
          let blob = new Blob([xhr.response], {
            // type: "application/vnd.google-earth.kml+xml",
            type: "application/vnd.google-earth.kmz",
          })
          //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
          let a = document.createElement("a")
          a.style = "display: none"
          document.body.appendChild(a)
          //Create a DOMString representing the blob and point the link element towards it
          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = pathReference.name
          //programatically click the link to trigger the download
          a.click()
          //release the reference to the file by revoking the Object URL
          window.URL.revokeObjectURL(url)
          setDownloading(false)
        }
        xhr.open("GET", url)
        xhr.send()
      })
      .catch(function (error) {
        // Handle any errors
        setDownloading(false)
      })
  }

  const { map } = props

  return (
    <tr>
      <td width="100%">{props.name}</td>
      <td style={{ whiteSpace: "nowrap" }}>
        {formatTimestamp(props.createdAt)}
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        {loading ? (
          downloading ? (
            "Descargando..."
          ) : (
            <a href="#" onClick={download}>
              <i className="fa fa-download"></i> Descarga
            </a>
          )
        ) : (
          "Procesando..."
        )}{" "}
        |{" "}
        {/* {props.boost ? (
          <>
            <a href="#" onClick={() => props.onSelectMap(map.id)}>
              <i className="fa fa-rocket"></i> Boost
            </a>{" "}
            |{" "}
          </>
        ) : null} */}
        <a href="#" onClick={() => props.onRemoveMap(map.id)}>
          <i className="fa fa-trash"></i> Eliminar
        </a>
        {/* <button type="button" onClick={() => props.onRemoveMap(map.id)}>
          <i className="fa fa-trash"></i>
        </button> */}
      </td>
    </tr>
  )
}
