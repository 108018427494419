import React, { useState, useCallback, useEffect } from "react"
import * as piexif from "piexifjs"
import { dmsRationalToDeg } from "./helpers"
import { withFirebase } from "../Firebase"
import { updateDownloadsCounter } from "./actions"

const resetOrientation = function(srcBase64, srcOrientation, callback) {
  var img = new Image()

  img.onload = function() {
    var width = img.width,
      height = img.height,
      canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d")

    // set proper canvas dimensions before transform & export
    if (4 < srcOrientation && srcOrientation < 9) {
      canvas.width = height
      canvas.height = width
    } else {
      canvas.width = width
      canvas.height = height
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0)
        break
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height)
        break
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height)
        break
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0)
        break
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0)
        break
      case 7:
        ctx.transform(0, -1, -1, 0, height, width)
        break
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width)
        break
      default:
        break
    }

    // draw image
    ctx.drawImage(img, 0, 0)

    // export base64
    callback(canvas.toDataURL())
  }

  img.src = srcBase64
}

const GeoTagImage = ({ firebase, file, lat, lng }) => {
  const [image, setImage] = useState(null)
  const [exifObj, setExifObj] = useState({})
  const [exifLat, setExifLat] = useState(null)
  const [exifLng, setExifLng] = useState(null)
  const [exifDesc, setExifDesc] = useState(null)

  useEffect(() => {
    const { image, exifObj } = file
    resetOrientation(image.src, 1, resetBase64Image => {
      setImage(resetBase64Image)
    })
    setExifObj(exifObj)
    setExifGPS(exifObj)
    setExif0th(exifObj)
  }, [file])

  const setExifGPS = exifObj => {
    if (!exifObj) return

    let { GPS } = exifObj
    // console.info(exifObj)
    if (GPS) {
      let lat = GPS[piexif.TagValues.GPSIFD.GPSLatitude]
      let latRef = GPS[piexif.TagValues.GPSIFD.GPSLatitudeRef]
      let lng = GPS[piexif.TagValues.GPSIFD.GPSLongitude]
      let lngRef = GPS[piexif.TagValues.GPSIFD.GPSLongitudeRef]

      if (lat && lng) {
        setExifLat(dmsRationalToDeg(lat, latRef))
        setExifLng(dmsRationalToDeg(lng, lngRef))
      }
    }
  }

  const setExif0th = exifObj => {
    let zeroth = exifObj["0th"]
    if (zeroth) {
      setExifDesc(zeroth[piexif.TagValues.ImageIFD.ImageDescription])
    }
  }

  const onChangeDescription = evt => {
    const value = evt.target.value
    setExifDesc(value)
    file.exifObj["0th"][piexif.TagValues.ImageIFD.ImageDescription] = `${value}`
    file.updated = true
  }

  const exifCoordinates =
    exifLat && exifLng ? [exifLat, exifLng].join(", ") : ""

  const buttonEnabled = file.updated && !!lat && !!lng

  const onDownloadFile = evt => updateDownloadsCounter(firebase)(1)

  return (
    <>
      <div className="grid-wrapper">
        <div
          className="col-2"
          style={{
            width: "100%",
            maxWidth: 240,
            height: 130,
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="col-10">
          <div className="grid-wrapper">
            <div className="col-4">
              <label>Nombre</label>
              <input type="text" value={file.name} readOnly={true} />
            </div>
            <div className="col-4">
              <label>Descripción</label>
              <input
                type="text"
                name="tag_description"
                value={exifDesc || ""}
                onChange={onChangeDescription}
              />
            </div>
          </div>
          <div className="grid-wrapper" style={{ marginTop: ".5em" }}>
            <div className="col-4">
              <label>Coordenadas</label>
              <input
                readOnly={true}
                type="text"
                name="geotag_lat"
                value={exifCoordinates}
              />
            </div>
            <div className="col-4">
              <label>&nbsp;</label>
              <a
                download={file.name}
                onClick={onDownloadFile}
                href={file.image.src}
                className={`button fit icon fa-save ${
                  !buttonEnabled ? "disabled" : ""
                }`}
              >
                Descargar
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default withFirebase(({ firebase, file, lat, lng }) => (
  <GeoTagImage firebase={firebase} file={file} lat={lat} lng={lng} />
))
