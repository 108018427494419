import React from "react"

const styles = {
  row: { display: "table-row" },
  labelCol: { display: "table-cell", width: "100%", verticalAlign: "middle" },
  priceCol: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  total: { textAlign: "right", fontSize: "2em" },
}

export default function Overview({ order, price }) {
  let amount = 0
  let amountTax = 0
  let tax = undefined

  if (order) {
    amountTax = order.amountTax / 100
    amount = order.amount / 100
    tax = amountTax - amount
  } else if (price) {
    amountTax = price.unit_amount / 100
    amount = price.unit_amount / 100
  }

  const humanize = (count, interval) => {
    const month = ["més", "meses"]

    if (interval == "month") {
      return count == 1 ? month[0] : `${count} ${month[1]}`
    }
    return `${count} ${interval}`
  }

  return (
    <>
      <div>
        <div style={styles.row}>
          <div style={styles.labelCol}>Importe del pedido:</div>
          <div style={styles.priceCol} className="text-right">
            <strong>{amount.toFixed(2)} €</strong>
          </div>
        </div>
        {tax !== undefined ? (
          <div style={styles.row}>
            <div style={styles.labelCol}>IVA:</div>
            <div style={styles.priceCol} className="text-right">
              <strong>{tax.toFixed(2)} €</strong>
            </div>
          </div>
        ) : null}

        <hr />
        <div style={styles.labelCol}>
          <strong>Total</strong>
          {tax ? (
            <>
              <br />
              IVA incl.
            </>
          ) : null}
        </div>
        <div style={styles.priceCol}>
          <strong style={styles.total}>{amountTax.toFixed(2)} €</strong>
        </div>
      </div>
      {price?.recurring ? (
        <>
          <hr />
          <div
            className="box"
            style={{ backgroundColor: "rgba(212, 212, 255, 0.035)" }}
          >
            <div>
              Se renovará automáticamente cada{" "}
              {humanize(
                price.recurring.interval_count,
                price.recurring.interval
              )}
              .
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
