import React from "react"

import RegisterForm from "../components/Register"
import { LoginLink } from "../components/Login"
import LoginWithGoogle from "../components/Login/LoginWithGoogle"
import Gdpr from "../components/Register/Gdpr"

export default function RegisterPage() {
  return (
    <div id="main" className="alt">
      <section className="grid-wrapper">
        <div className="inner col-3"></div>
        <div className="inner col-6">
          <header className="major">
            <h1>Registro</h1>
          </header>
          <LoginLink />
          <LoginWithGoogle />
          <div data-content="o" className="divider"></div>
          <RegisterForm />
          <Gdpr />
        </div>
      </section>
    </div>
  )
}
