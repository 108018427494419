import app from "firebase/app"

const POWERLINKS_BASE_URL = process.env.GATSBY_POWERLINKS_URL
// const POWERLINKS_BASE_URL =
//   "http://localhost:5001/maps-7f983/europe-west1/powerlinks-http"

export const createPowerLink = (firebase) => (data) => {
  const { currentUser } = firebase.auth

  return firebase
    .userPowerLinks(currentUser.uid)
    .add({ ...data, createdAt: app.firestore.FieldValue.serverTimestamp() })
}

export const updatePowerLink = (firebase) => (id, data) => {
  const { currentUser } = firebase.auth
  return firebase
    .userPowerLinks(currentUser.uid)
    .doc(id)
    .update({ ...data })
}

export const refreshPowerLinks = (firebase) => async (links) => {
  const { currentUser } = firebase.auth
  const headers = {
    Authorization: await currentUser.getIdToken(),
    "Content-Type": "application/json",
  }

  const url = `${POWERLINKS_BASE_URL}/refresh`

  return await fetch(url, {
    method: "POST",
    headers,
  }).then((res) => res.json())
}
