import React from "react"

const styles = {
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    lineHeight: 1,
  },
  listItem: {
    padding: "1em",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  listItemSeparator: {
    padding: "1em 0",
    "&:hover": {
      backgroundColor: "red",
    },
  },
}

export default ({ children }) => {
  return (
    <ul style={styles.list}>
      {React.Children.map(children, (child, i) => (
        <>
          <li key={`nav-${i}`} style={styles.listItem}>
            {child}
          </li>
          {i < children.length - 1 && (
            <li key={`nav-${i}-s`} style={styles.listItemSeparator}>
              /
            </li>
          )}
        </>
      ))}
    </ul>
  )
}
