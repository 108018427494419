import React, { useContext } from "react"
import { Router, Redirect } from "@reach/router"
import * as ROUTES from "../../constants/routes"

import Account from "./Account"
import Security from "./Security"
import Billing from "./Billing"

const SettingsLayout = () => (
  <div id="main" className="alt">
    <section>
      <div className="inner"></div>
    </section>
  </div>
)

export default function Settings() {
  return (
    <>
      <div id="main" className="alt">
        <section>
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-8">
                <Router>
                  <Redirect from="/" to={ROUTES.SETTINGS_DETAILS} noThrow />
                  <Account path="account" />
                  <Security path="security" />
                  <Billing path="billing" />
                </Router>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
