import React, { useContext, useEffect, useState } from "react"
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form"
import { navigate } from "@reach/router"
import { CheckoutContext } from "./context"
import { CircularProgress } from "@material-ui/core"

import Overview from "./Overview"
import { useFirebase, withFirebase } from "../Firebase"
import { useToasts } from "../FlashNotices"

import { loadStripe } from "@stripe/stripe-js"

import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
} from "@stripe/react-stripe-js"

import * as actions from "./actions"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)

const createOptions = () => ({
  style: {
    color: "white",
    base: {
      // backgroundColor: "rgba(212, 212, 255, 0.035)",
      fontSize: "16px",
      // fontFamily: "'Source Sans Pro', Helvetica, sans-serif",
      color: "white",
      letterSpacing: "0.025em",
      // lineHeight: "1em",
      // padding: 10,
      "::placeholder": {
        color: "#aab7c466",
      },
    },
    invalid: {
      color: "#c23d4b",
    },
  },
})

export const Loading = () => (
  <div className="grid-wrapper">
    <div
      className="col-12"
      style={{
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </div>
  </div>
)

const CreditCardForm = ({ onReady }) => {
  const firebase = useFirebase()
  const stripe = useStripe()

  let { state, dispatch } = useContext(CheckoutContext)
  const { formState, control } = useFormContext()

  const handlePaymentSubmit = async (stripe, ccElement) => {
    const user = require("firebase").default.auth().currentUser

    const { order } = state
    const { email } = user
    const { name } = state.details
    const { address1, address2, city, country, postalcode } = state.details
    const { paymentIntent } = state
    const billingDetails = {
      name: name,
      email: email,
      address: {
        line1: address1,
        line2: address2,
        city: city,
        postal_code: postalcode,
        state: "",
        country: country,
      },
    }

    await actions
      .confirmCardPayment(stripe, {
        ccElement,
        paymentIntent,
        billingDetails,
        order,
      })
      .then((paymentIntent) => state.order)
      .catch((err) => {
        console.info(err)
        throw err
      })
  }

  const [environment, setEnvironment] = useState(null)
  firebase
    .settings()
    .doc("payments")
    .get()
    .then((snapshot) => {
      setEnvironment(snapshot.get("environment"))
    })

  const disabled = formState.isSubmitting

  if (!stripe) return null

  return (
    <>
      <div className="field">
        <label>Número de tarjeta</label>
        <div className="input">
          <Controller
            rules={{
              required: true,
              validate: (value) => value === "1",
            }}
            render={({ field }) => (
              <CardNumberElement
                {...field}
                options={createOptions()}
                disabled={disabled}
                onReady={(element) => {
                  dispatch({
                    type: "setPaymentSubmit",
                    payload: async () =>
                      await handlePaymentSubmit(stripe, element),
                  })
                }}
                onChange={(e) => field.onChange(e.complete ? "1" : "0")}
              />
            )}
            control={control}
            name="card.number"
          />
        </div>
      </div>
      <div className="grid-wrapper">
        <div className="field col-6">
          <label>Fecha de expiración</label>
          <div className="input">
            <Controller
              rules={{
                required: true,
                validate: (value) => value === "1",
              }}
              render={({ field }) => (
                <CardExpiryElement
                  {...field}
                  options={createOptions()}
                  disabled={disabled}
                  onChange={(e) => field.onChange(e.complete ? "1" : "0")}
                />
              )}
              control={control}
              name="card.expiry"
            />
          </div>
        </div>
        <div className="field col-6">
          <label>CVC</label>
          <div className="input">
            <Controller
              rules={{
                required: true,
                validate: (value) => value === "1",
              }}
              render={({ field }) => (
                <CardCvcElement
                  {...field}
                  options={createOptions()}
                  disabled={disabled}
                  onChange={(e) => field.onChange(e.complete ? "1" : "0")}
                />
              )}
              control={control}
              name="card.cvc"
            />
          </div>
        </div>
      </div>
      {environment === "test" ? (
        <label>
          Usar 4000002500003155 para testear '3D secure'.
          <br />
          <a href="https://stripe.com/docs/testing#testing">Más tarjetas</a>.
        </label>
      ) : null}
    </>
  )
}

export const PaymentMethods = ({ order }) => {
  const firebase = useFirebase()
  let { state, dispatch } = useContext(CheckoutContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    actions
      .createPayment(firebase, state.order)
      .then((paymentIntent) => {
        dispatch({ type: "setPayment", paymentIntent })
        setLoading(false)
      })
      .catch((err) => {
        console.info(err)
        setError(
          "Ha ocurrido un error cargando el sistema de pago seguro. Vuelve a intentarlo más tarde."
        )
        setLoading(false)
      })
  }, [])

  return (
    <div>
      <button className="button special icon fa-credit-card">Tarjeta</button>
      <hr />
      {loading ? (
        <Loading />
      ) : !error ? (
        <Elements stripe={stripePromise}>
          <CreditCardForm
            onReady={(element) =>
              dispatch({ type: "credit_card", payload: element })
            }
          />
        </Elements>
      ) : (
        <p>{error}</p>
      )}
      <hr />
    </div>
  )
}

export default withFirebase(({ firebase }) => {
  const toasts = useToasts()
  let { state, dispatch } = React.useContext(CheckoutContext)

  const [loading, setLoading] = React.useState(true)

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  useEffect(() => {
    validateOrder()
      .then((order) => {
        setLoading(false)
      })
      .catch((err) => {
        console.info(err)
        navigate("/app/payments/new/checkout")
      })
  }, [])

  const validateOrder = () => {
    return new Promise((resolve, reject) => {
      state.order ? resolve(state.order) : reject()
    })
  }

  const onSubmit = async (data) => {
    const { paymentSubmit } = state
    await paymentSubmit()
      .then((res) => {
        toasts.success("El pago ha sido completado con éxito.")
        navigate(`/app/payments/order/${state.order.orderNumber}`, {
          replace: true,
        })
      })
      .catch((err) => toasts.error(err.message))
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid-wrapper">
            <div className="col-8">
              <h2>Confirmación</h2>
              {loading ? <Loading /> : <PaymentMethods />}
            </div>
            <div className="col-4">
              <h3>Resumen del pedido</h3>
              <Overview order={state.order} />
              <hr />
              <PaymentMethodsButton />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
})

export const PaymentMethodsButton = () => {
  const { formState } = useFormContext()
  let { state, dispatch } = React.useContext(CheckoutContext)

  const className = formState.isSubmitting ? "loading" : ""
  const disabled =
    !state.paymentIntent || formState.isSubmitting || !formState.isValid

  return (
    <button className={className} disabled={disabled}>
      Pago seguro
    </button>
  )
}
