import React, { useContext } from "react"
import { navigate } from "gatsby"

import * as ROUTES from "../constants/routes"
import { AuthUserContext } from "./Session"

export default function OnlyPublicRoute({
  component: Component,
  location,
  ...rest
}) {
  const currentUser = useContext(AuthUserContext)

  if (currentUser && location.pathname === ROUTES.LOGIN) {
    navigate(ROUTES.DASHBOARD)
    return null
  }

  return <Component {...rest} />
}
