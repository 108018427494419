import React, { useState, useEffect } from "react"

import { withFirebase } from "../../Firebase"
import Page from "../Page"
import Loading from "../Loading"
import * as actions from "./actions"
import { formatTimestamp } from "../../../utils/time"

const CancelButton = ({ subscription, firebase, onCancel }) => {
  const cancelSubscription = actions.cancelSubscription(firebase)
  const [cancelled, setCancelled] = useState(false)

  const handleCancel = async (e) => {
    e.preventDefault()

    let msg = "¿Estás seguro de que quieres cancelar la suscripción?"
    if (subscription.powerlink) {
      msg = `${msg} El servicio continuará hasta el ${formatTimestamp(
        new Date(subscription.current_period_end * 1000)
      )}.`
    }

    if (confirm("¿Estás seguro de que quieres cancelar la suscripción?")) {
      await cancelSubscription(subscription.id)

      setCancelled(true)
      onCancel()
    }
  }

  return <button onClick={handleCancel}>Cancelar suscripción</button>
}

const SubscriptionStatus = ({ subscription }) => {
  const status =
    subscription.status == "active"
      ? "Activa"
      : subscription.status == "canceled"
      ? "Cancelada"
      : "Inactiva"
  return (
    <small style={{ fontSize: "16px", fontWeight: "normal" }}>{status}</small>
  )
}

const SubscriptionShow = ({ firebase, id }) => {
  const getSubscription = actions.getSubscription(firebase)
  const [loading, setLoading] = useState(true)
  const [subscription, setSubscription] = useState(null)

  useEffect(() => {
    getSubscription(id).then((subscription) => {
      setSubscription(subscription)
      setLoading(false)
    })
  }, [id])

  return loading ? (
    <Loading />
  ) : (
    <Page>
      <h2>
        Suscripción <SubscriptionStatus subscription={subscription} />
      </h2>
      <p>Inicio: {formatTimestamp(new Date(subscription.start_date * 1000))}</p>
      <hr />

      <h3>Plan</h3>
      {subscription ? (
        <>
          <p>{subscription.plan.nickname}</p>
          {subscription.default_payment_method ? (
            <p>
              Card last4: {subscription.default_payment_method?.card?.last4}
            </p>
          ) : null}
          <p>{subscription.ended_at}</p>
          {subscription.ended_at ? (
            <>
              <p>
                Finalizado:{" "}
                {formatTimestamp(new Date(subscription.ended_at * 1000))}
              </p>
            </>
          ) : (
            <>
              <hr />
              <p>
                Próxima renovación:{" "}
                {formatTimestamp(
                  new Date(subscription.current_period_end * 1000)
                )}
              </p>

              <p>
                <CancelButton
                  subscription={subscription}
                  firebase={firebase}
                  onCancel={() => location.reload()}
                />
              </p>
            </>
          )}
        </>
      ) : null}
    </Page>
  )
}

export default withFirebase(({ firebase, ...props }) => (
  <SubscriptionShow firebase={firebase} {...props} />
))
