import React, { useContext } from "react"
import { navigate } from "gatsby"

import * as ROUTES from "../constants/routes"
import { AuthUserContext } from "./Session"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const currentUser = useContext(AuthUserContext)

  if (!currentUser && location.pathname !== ROUTES.LOGIN) {
    navigate(ROUTES.LOGIN)
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
