import React, { Component } from "react"

import "../assets/scss/main.scss"

import getFirebase, { FirebaseContext } from "./Firebase"

import { CircularProgress } from "@material-ui/core"

class Layout extends Component {
  state = {
    firebase: null,
  }

  componentDidMount() {
    const app = require("firebase/app").default
    const auth = import("firebase/auth")
    const database = import("firebase/database")
    const firestore = import("firebase/firestore")
    const storage = import("firebase/storage")

    Promise.all([app, auth, database, firestore, storage]).then(values => {
      const firebase = getFirebase(values[0])
      setTimeout(() => {
        this.setState({ firebase })
      }, 1000)
    })
  }
  render() {
    return (
      <>
        {/* <CssBaseline /> */}
        <FirebaseContext.Provider value={this.state.firebase}>
          {this.state.firebase ? (
            this.props.children
          ) : (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
        </FirebaseContext.Provider>
      </>
    )
  }
}

export default Layout
