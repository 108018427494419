import React, { useState, useEffect, useContext } from "react"
import { Router } from "@reach/router"

import List from "./List"
import Show from "./Show"
import Create from "./Create"

const Subscriptions = () => {
  return (
    <Router>
      <List path="/" />
      <Show path="/:id/show" />
      <Create path="/*" />
    </Router>
  )
}

export default Subscriptions
