import React from "react"
import { Link } from "@reach/router"

import Breadcrumps from "../breadcrumbs"

export default () => (
  <Breadcrumps>
    <Link to="/">Dashboard</Link>
    <Link to="/app/maps">Mapas</Link>
    <span>Boost</span>
  </Breadcrumps>
)
