import React from "react"
// import { Link } from "@reach/router"

import PaymentItem from "./PaymentItem"

export default function PaymentList({ payments, onRemovePayment }) {
  return (
    <>
      {/* <Link to="new" className="button right">
        Recargar
      </Link>
      <hr /> */}
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th style={{ whiteSpace: "nowrap" }}>Pedido #</th>
              {/* <th>Tipo</th> */}
              <th>Importe</th>
              <th>Importe (IVA incl.)</th>
              <th>Descripción</th>
              <th>Estado</th>
              <th>Método de pago</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <PaymentItem
                payment={payment}
                key={payment.id}
                id={payment.id}
                created={payment.get("createdAt")}
                orderNumber={payment.get("orderNumber")}
                type={payment.get("type")}
                status={payment.get("status")}
                amount={payment.get("amount")}
                amountTax={payment.get("amountTax")}
                description={payment.get("description")}
                paymentMethod={payment.get("paymentMethod")}
                paymentMethodType={payment.get("paymentMethodType")}
                receiptUrl={payment.get("receiptUrl")}
                invoiceUrl={payment.get("invoiceUrl")}
                onRemovePayment={onRemovePayment}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
