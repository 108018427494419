import React, { useEffect, useState } from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"

const MapContainerBase = ({ google, initialCenter, zoom, onChange }) => {
  const [position, setPosition] = useState({})

  useEffect(() => {
    if (initialCenter && initialCenter.lat && initialCenter.lng)
      setPosition(initialCenter)
  }, [initialCenter])

  const onClick = (props, a, e) => {
    setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() })
    onChange([e.latLng.lat(), e.latLng.lng()])
  }
  const onInfoWindowClose = () => {}

  const moveMarker = (props, marker, e) => {
    setPosition({
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    })
    onChange([marker.position.lat(), marker.position.lng()])
  }

  let otherProps = {}
  if (initialCenter) {
    otherProps = { initialCenter: initialCenter }
  }
  return (
    <Map
      google={google}
      zoom={zoom||14}
      onClick={onClick}
      centerAroundCurrentLocation
      streetViewControl={false}
      {...otherProps}
    >
      <Marker
        onClick={onClick}
        name={"Current location"}
        draggable={true}
        onDragend={moveMarker}
        position={{ lat: position.lat, lng: position.lng }}
      />

      <InfoWindow onClose={onInfoWindowClose}>
        <div>
          <h1>{/* this.state.selectedPlace.name */}</h1>
        </div>
      </InfoWindow>
    </Map>
  )
}

export default GoogleApiWrapper(props => ({
  apiKey: props.apiKey,
  language: props.language,
  ...props,
}))(MapContainerBase)
