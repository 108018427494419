import React from "react"

let CheckoutContext = React.createContext()

let initialState = {
  object: null,
  productId: null,
  prices: [],
  price: null,
  details: null,
  // paymentIntent: null,
  // paymentSubmit: () => {},
  order: null,
  // submitting: false,
}

let reducer = (state, action) => {
  switch (action.type) {
    case "setObject":
      return { ...state, object: action.object }
    case "setProduct":
      return { ...state, productId: action.product }
    case "setPrices":
      return { ...state, prices: action.prices }
    case "setPrice":
      return { ...state, price: action.price }
    case "setDetails":
      return { ...state, details: action.details }
    case "setOrder":
      return { ...state, order: action.order }
    case "setPayment":
      return { ...state, paymentIntent: action.paymentIntent }
    case "setPaymentSubmit":
      return { ...state, paymentSubmit: action.paymentSubmit }
    default:
      return state
  }
}

function CheckoutContextProvider(props) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState)
  let value = { state, dispatch }

  // [B]
  return (
    <CheckoutContext.Provider value={value}>
      {props.children}
    </CheckoutContext.Provider>
  )
}

let CheckoutContextConsumer = CheckoutContext.Consumer

// [C]
export { CheckoutContext, CheckoutContextProvider, CheckoutContextConsumer }
