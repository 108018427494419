import React, { useState, useEffect } from "react"

import AuthUserContext from "../Session/context"
import { withFirebase } from "../Firebase"

import { CircularProgress } from "@material-ui/core"

import { useToasts } from "../FlashNotices"

import PaymentsNav from "./nav"
import PaymentsList from "./PaymentsList"
import PaymentsEmpty from "./PaymentsEmpty"

const Payments = ({ firebase, authUser }) => {
  const { success, error } = useToasts()

  const [loading, setLoading] = useState(true)

  const [payments, setPayments] = useState([])

  useEffect(() => {
    const unsubscribe = firebase
      .orders()
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        setPayments(snapshot.docs)
        setLoading(false)
      })

    return () => unsubscribe()
  }, [firebase, authUser.uid])

  const onRemovePayment = (id) => {
    return firebase.firestore
      .collection("orders")
      .doc(id)
      .delete()
      .then((res) => {
        success("Pedido eliminado correctamente.")
      })
      .catch((err) =>
        error(
          "Ha ocurrido un error eliminando el comentario. Inténtalo más tarde."
        )
      )
  }

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          {loading ? (
            <CircularProgress />
          ) : payments.length ? (
            <>
              <PaymentsList
                payments={payments}
                onRemovePayment={onRemovePayment}
              />
              {/* <Pagination /> */}
            </>
          ) : (
            <PaymentsEmpty />
          )}
        </div>
      </section>
    </div>
  )
}

export default withFirebase(({ firebase }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? <Payments authUser={authUser} firebase={firebase} /> : null
    }
  </AuthUserContext.Consumer>
))

export { PaymentsNav }
