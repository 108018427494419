import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { formatTimestamp } from "../../utils/time"
import { useFirebase } from "../Firebase"
import { FirebaseLink } from "./utils"

const Item = ({ item, onEnable }) => {
  return (
    <tr>
      <td>
        <FirebaseLink path={["scheduled-tasks", item.id]}>
          {item.id.substr(0, 8)}...
        </FirebaseLink>
      </td>
      <td>
        <FirebaseLink path={["users", item.get("uid")]}>
          {item.get("user_id").substr(0, 8)}...
        </FirebaseLink>
      </td>

      <td>
        <FirebaseLink path={["power-links", item.get("object_id")]}>
          {item.get("object_id").substr(0, 8)}...
        </FirebaseLink>
      </td>
      <td>
        <FirebaseLink path={["subscriptions", item.get("subscription")]}>
          {item.get("subscription").substr(0, 8)}...
        </FirebaseLink>
      </td>
      <td>
        {item?.get("run_at") && formatTimestamp(item?.get("run_at")?.toDate())}
      </td>
      <td>
        {item?.get("ends_at") &&
          formatTimestamp(item?.get("ends_at")?.toDate())}
      </td>
      <td>{item.get("auto") ? "Sí" : "No"}</td>
      <td>
        {item.get("run_at") < item.get("ends_at") ? "Activo" : "Finalizado"}
      </td>
      <td>
        {item.get("run_at") < item.get("ends_at") && !item.get("auto") ? (
          <button onClick={() => onEnable(item.id)}>Activar</button>
        ) : null}
      </td>
    </tr>
  )
}

export default function ScheduledTasks() {
  const { firestore } = useFirebase()
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [error, setError] = useState(null)
  const [filter, setFilter] = useState("0")

  useEffect(() => {
    let query = firestore
      .collection("scheduled-tasks")
      .where("object_type", "==", "power-link")
    if (filter === "1") {
      query = query.where("auto", "==", false)
    }

    const subscription = query.onSnapshot((snapshot) => {
      const items =
        filter === "1"
          ? snapshot.docs.filter((d) => d.get("run_at") < d.get("ends_at"))
          : snapshot.docs
      setItems(items)
    })
    return () => subscription()
  }, [firestore, filter])

  const enableTask = (id) => {
    const ref = firestore.doc(`/scheduled-tasks/${id}`)
    ref.update({
      auto: true,
    })
  }

  return (
    <>
      <h2>Procesos background</h2>
      <div style={{ margin: "1em 0", width: 200 }}>
        <select onChange={(evt) => setFilter(evt.target.value)}>
          <option value="0">Todos</option>
          <option value="1">Falsos terminados</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Usuario</th>
            <th>Link</th>
            <th>Suscripción</th>
            <th>Ejecución</th>
            <th>Finalización</th>
            <th>Auto</th>
            <th>Estado</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => (
            <Item key={i} item={item} onEnable={enableTask} />
          ))}
        </tbody>
      </table>
    </>
  )
}
