import React from "react"
import { navigate } from "@reach/router"
import { Link } from "@reach/router"

const PayButton = ({ link }) => {
  return "No disponible"
  const onPaymentClick = () => {
    navigate("subscriptions/new", {
      state: { type: "power-link", id: link.id },
    })
  }

  return (
    <button className="btn" onClick={onPaymentClick}>
      Pendiente de pago
    </button>
  )
}

const SusbscriptionButton = ({ link }) => {
  return (
    <>
      <a href={`/app/subscriptions/${link.get("subscriptionId")}/show`}>
        Activa
      </a>
    </>
  )
}

const SubscriptionStatus = ({ link }) => {
  switch (link.get("subscriptionStatus")) {
    case undefined:
      return <PayButton link={link} />
    case "paid":
      return <SusbscriptionButton link={link} />
    case "canceled":
      return <PayButton link={link} />

    default:
      return link.get("subscriptionStatus")
  }
}

const LinkStatuses = {
  ready: "Preparado",
  waiting: "Esperando",
  running: "Ejecutando",
  payment_pending: "",
  finished: "Finalizado",
  unknown: "Desconocido",
}

const LinkStatus = ({ link }) => {
  const status = link.get("status")
  return LinkStatuses[status] !== undefined ? LinkStatuses[status] : status
}

export default function PowerLinkListItem({ link }) {
  const startDate = link.get("startDate")
    ? link.get("startDate").split(" ")[0]
    : "-"
  const totalVisits = link.get("totalVisits")
  const endDate = link.get("endDate") ? link.get("endDate").split(" ")[0] : "-"
  const successVisits = link.get("successVisits") || 0
  const failVisits = link.get("failVisits") || 0
  const notFoundVisits = link.get("notFoundVisits") || 0
  const doneVisits = successVisits + failVisits + notFoundVisits
  const successPercentage =
    doneVisits == 0 ? 0 : (successVisits / doneVisits) * 100

  return (
    <tr>
      <td width="100%">{link.get("linkTitleFicha")}</td>
      <td>
        <SubscriptionStatus link={link} />
      </td>
      <td>
        <LinkStatus link={link} />
      </td>
      <td
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {startDate}
      </td>
      <td
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {endDate}
      </td>

      <td>{doneVisits}</td>
      <td>{failVisits + notFoundVisits}</td>
      <td>{successPercentage.toFixed(2)}%</td>
      <td
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <Link to={`${link.id}/edit`}>Editar</Link>
      </td>

      {/* <td>
        <a href="#" onClick={handleRefresh}>
          Check
        </a>
      </td> */}
    </tr>
  )
}
