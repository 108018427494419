import app from "firebase/app"

export const createProject = firebase => data => {
  const user = require("firebase").default.auth().currentUser

  data = {
    ...data,
    createdAt: app.firestore.FieldValue.serverTimestamp(),
  }

  return firebase.userProjects(user.uid).add(data)
}

export const deleteProject = firebase => id => {
  const user = require("firebase").default.auth().currentUser
  return firebase
    .userProjects(user.uid)
    .doc(id)
    .delete()
}
