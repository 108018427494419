import React from "react"

export default function Page({ header, children }) {
  return (
    <div id="main" className="alt">
      <section>
        {header}
        <div className="inner">{children}</div>
      </section>
    </div>
  )
}
