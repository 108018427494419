import React, { useState, useEffect } from "react"

import { loadStripe } from "@stripe/stripe-js"

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  Elements,
  useElements,
} from "@stripe/react-stripe-js"
import { Controller, useForm } from "react-hook-form"
import { setupPaymentMethod } from "./actions"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)

const createOptions = () => {
  return {
    style: {
      base: {
        // backgroundColor: "rgba(212, 212, 255, 0.035)",
        fontSize: "16px",
        // fontFamily: "'Source Sans Pro', Helvetica, sans-serif",
        color: "white",
        letterSpacing: "0.025em",
        // lineHeight: "1em",
        // padding: 10,
        "::placeholder": {
          color: "#aab7c466",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  }
}

export default function CreditCardForm({
  details,
  environment,
  onCancel,
  onPaymentMethod,
}) {
  const elements = useElements()
  const stripe = useStripe()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [cardNumber, setCardNumber] = useState(false)
  const [cardExpiry, setCardExpiry] = useState(false)
  const [cardCvc, setCardCvc] = useState(false)
  const [error, setError] = useState(null)

  const disabled = isSubmitting

  useEffect(() => {
    setIsValid(cardNumber && cardExpiry && cardCvc)
  }, [cardNumber, cardExpiry, cardCvc])

  const onSubmit = async () => {
    setIsSubmitting(true)
    const paymentMethod = await setupPaymentMethod()

    const { client_secret } = paymentMethod

    const { setupIntent, error } = await stripe.confirmCardSetup(
      client_secret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            address: {
              city: details.city,
              country: details.country,
              line1: details.address1,
              line2: details.address2,
              postal_code: details.postalcode,
              state: details.state,
            },
            name: details.name,
          },
        },
      }
    )

    if (setupIntent) {
      const { payment_method } = setupIntent
      onPaymentMethod(payment_method)
    } else {
      console.error("Error configurando tarjeta.", error)
      setError("Error configurando el método de pago.")
    }

    setIsSubmitting(false)
  }

  return (
    <>
      <div>
        {error ? (
          <div className="field">
            <div className={"col-12 box error"} onClick={() => {}}>
              <p>{error}</p>
            </div>
          </div>
        ) : null}

        <div className="field">
          <label>Número de tarjeta</label>
          <div className="input">
            <CardNumberElement
              options={createOptions()}
              disabled={disabled}
              onChange={(e) => setCardNumber(e.complete)}
            />
          </div>
        </div>

        <div className="grid-wrapper">
          <div className="field col-6">
            <label>Fecha de expiración</label>
            <div className="input">
              <CardExpiryElement
                options={createOptions()}
                disabled={disabled}
                onChange={(e) => setCardExpiry(e.complete)}
              />
            </div>
          </div>
          <div className="field col-6">
            <label>CVC</label>
            <div className="input">
              <CardCvcElement
                options={createOptions()}
                disabled={disabled}
                onChange={(e) => setCardCvc(e.complete)}
              />
            </div>
          </div>
        </div>
        {environment === "test" ? (
          <label>
            Usar 4000002500003155 para testear '3D secure'.
            <br />
            <a href="https://stripe.com/docs/testing#testing">Más tarjetas</a>.
          </label>
        ) : null}
        <button
          disabled={!isValid || isSubmitting}
          onClick={(evt) => {
            evt.preventDefault()
            onSubmit()
          }}
        >
          Guardar método de pago
        </button>
      </div>

      {onCancel && (
        <button disabled={disabled} onClick={onCancel}>
          Cancelar
        </button>
      )}
    </>
  )
}

// export default function CreditCardForm({
//   environment,
//   onCancel,
//   onPaymentMethod,
// }) {
//   return (
//     <Elements stripe={stripePromise}>
//       <Form
//         environment={environment}
//         onCancel={onCancel}
//         onPaymentMethod={onPaymentMethod}
//       />
//     </Elements>
//   )
// }
