import React, { useState, useEffect } from "react"
import { CircularProgress } from "@material-ui/core"
import { useFirebase } from "../../Firebase"

import CreditCardForm from "./CreditCardForm"

import { getPaymentMethod, savePaymentMethod } from "./actions"
import { getBillingDetails } from "../../PaymentsNew/actions"

import { loadStripe } from "@stripe/stripe-js"

import { Elements } from "@stripe/react-stripe-js"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)
export const PaymentMethod = ({ type, card, onUpdate }) => {
  if (!card) return <div>Desconocido</div>

  const { brand, exp_month, exp_year, last4 } = card

  return (
    <div style={{ marginBottom: "2em" }}>
      Tarjeta: {brand} ****{last4}
      <br />
      Expiración: {exp_month}/{exp_year}
    </div>
  )
}

export const EmptyPaymentMethod = ({ onUpdate }) => {
  return (
    <div style={{ marginBottom: "2em" }}>
      No has añadido ningún método de pago.
    </div>
  )
}
export default function DefaultPaymentMethod() {
  const firebase = useFirebase()
  const [paymentMethod, setPaymentMethod] = useState()
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState(null)

  const getPaymentMethodAction = getPaymentMethod(firebase)
  const savePaymentMethodAction = savePaymentMethod(firebase)

  const [isEditing, setIsEditing] = useState(false)

  const handleEdit = async () => {
    const details = await getBillingDetails(firebase)
    const { address1, address2, city, country, name, postalcode, state } =
      details
    if (address1 && city && country && name && postalcode) {
      setDetails(details)
      setIsEditing(true)
    } else {
      alert("Debes completar primero los datos de facturación.")
    }
  }
  const handleCancel = () => setIsEditing(false)

  const fetchPaymentMethod = async () => {
    setLoading(true)
    getPaymentMethodAction()
      .then(setPaymentMethod)
      .then(() => setLoading(false))
  }

  useEffect(() => {
    fetchPaymentMethod()
  }, [])

  const handleUpdate = async (paymentMethod) => {
    setLoading(true)

    await savePaymentMethodAction(paymentMethod)
    await fetchPaymentMethod()
    setIsEditing(false)
    setLoading(false)
  }

  return (
    <>
      <h2>Método de pago</h2>
      <div style={{ marginBottom: "2em" }}>
        <Elements stripe={stripePromise}>
          {loading ? (
            <CircularProgress />
          ) : isEditing ? (
            <CreditCardForm
              details={details}
              onCancel={handleCancel}
              onPaymentMethod={handleUpdate}
              environment={
                process.env.NODE_ENV === "development" ? "test" : "prod"
              }
            />
          ) : paymentMethod ? (
            <>
              <PaymentMethod {...paymentMethod} />
              <button onClick={handleEdit}>Editar método de pago</button>
            </>
          ) : (
            <>
              <EmptyPaymentMethod />
              <button onClick={handleEdit}>Añadir método de pago</button>
            </>
          )}
        </Elements>
      </div>
    </>
  )
}
