import React, { useEffect } from "react"

export default ({ apiKey, onChange }) => {
  var placeSearch, autocomplete

  var componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name",
  }
  useEffect(() => {
    setTimeout(() => {
      if (!window.google) {
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.src = `https://maps.google.com/maps/api/js?key=${apiKey}&libraries=places`
        const headScript = document.getElementsByTagName("script")[0]
        headScript.parentNode.insertBefore(script, headScript)
        script.addEventListener("load", () => {
          onLoad()
        })
      } else {
        onLoad()
      }
    }, 250)
  }, [])

  const onLoad = () => {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: [] }
    )

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields([
      "address_component",
      "geometry",
      "name",
      "formatted_phone_number",
      "website",
    ])

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener("place_changed", fillInAddress)
  }

  const fillInAddress = () => {
    // Get the place details from the autocomplete object.
    var place = autocomplete.getPlace()

    // for (var component in componentForm) {
    //   document.getElementById(component).value = ""
    //   document.getElementById(component).disabled = false
    // }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // for (var i = 0; i < place.address_components.length; i++) {
    //   var addressType = place.address_components[i].types[0]
    //   if (componentForm[addressType]) {
    //     var val = place.address_components[i][componentForm[addressType]]
    //     document.getElementById(addressType).value = val
    //   }
    // }

    const get = type => {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0]
        if (addressType === type) {
          return place.address_components[i][componentForm[addressType]]
        }
      }
    }

    // Geolocation

    if (!place.geometry) return

    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()

    onChange({
      name: place.name,
      coordinates: `${lat}, ${lng}`,
      address: get("route"),
      addressNumber: get("street_number"),
      locality: get("locality"),
      state: get("administrative_area_level_1"),
      postalCode: get("postal_code"),
      country: get("country"),
      phone: place.formatted_phone_number,
      website: place.website,
    })
  }

  // const geolocate = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(function(position) {
  //       var geolocation = {
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       }
  //       console.info(geolocation)
  //       // var circle = new google.maps.Circle({
  //       //   center: geolocation,
  //       //   radius: position.coords.accuracy,
  //       // })
  //       // autocomplete.setBounds(circle.getBounds())
  //     })
  //   }
  // }

  return (
    <div>
      <input
        id="autocomplete"
        placeholder="Dirección o localización"
        // onFocus={geolocate}
        type="text"
      />
    </div>
  )
}
