import React from "react"

import Form from "./Form"
import { ConfirmationServiceProvider } from "../ConfirmDialog"

export default function MapsNew() {
  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <ConfirmationServiceProvider>
            <Form />
          </ConfirmationServiceProvider>
        </div>
      </section>
    </div>
  )
}
