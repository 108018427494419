import React from "react"
import { Link } from "@reach/router"

export default () => {
  return (
    <Link to="new" className="button">
      Recargar
    </Link>
  )
}
