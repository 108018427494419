const SUBSCRIPTIONS_BASE_URL = process.env.GATSBY_SUBSCRIPTIONS_URL

export const getPaymentMethod = (firebase) => async () => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
  }
  const url = `${SUBSCRIPTIONS_BASE_URL}/payment_method`

  return fetch(url, { headers })
    .then((response) => response.json())
    .catch((err) => console.error("Error", err))
}

export const setupPaymentMethod = async () => {
  const firebase = require("firebase").default

  const { currentUser } = firebase.auth()

  const headers = {
    Authorization: await currentUser.getIdToken(),
  }
  const url = `${SUBSCRIPTIONS_BASE_URL}/payment_method/setup`

  return fetch(url, { headers })
    .then((response) => response.json())
    .catch((err) => console.error("Error", err))
}

export const savePaymentMethod = (firebase) => async (paymentMethod) => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
    "Content-Type": "application/json",
  }
  const url = `${SUBSCRIPTIONS_BASE_URL}/payment_method`

  return fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify({ paymentMethod }),
  })
    .then((response) => response.json())
    .catch((err) => console.error("Error", err))
}
