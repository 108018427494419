import React from "react"
import { Link, globalHistory } from "@reach/router"
import { CheckoutContext } from "./context"
import logo from "../../assets/images/logo-localrocket.png"

export const PaymentStep = ({ label, url, current, enabled, completed }) => {
  const classes = ["step"]

  if (!enabled) classes.push("disabled")
  if (current) classes.push("current")

  return enabled ? (
    <Link to={url} className={classes.join(" ")}>
      {completed ? <i className="fa fa-check"></i> : null}
      {label}
    </Link>
  ) : (
    <span className={classes.join(" ")}>{label}</span>
  )
}

export default () => {
  let { state, dispatch } = React.useContext(CheckoutContext)

  let current = 1
  const { pathname } = globalHistory.location
  if (pathname.match(/boost$/)) {
    current = 1
  } else if (pathname.match(/checkout$/)) {
    current = 2
  } else if (pathname.match(/confirm$/)) {
    current = 3
  }
  let step = 1
  let completed = 0

  const {
    data: { mapUrl, mapKeyword1 },
  } = state

  if (mapUrl && mapKeyword1) {
    step = 2
    completed = 1
  }
  if (state.order) {
    step = 3
    completed = 2
  }

  return (
    <div className="grid-wrapper">
      <div className="col-12">
        <div className="steps">
          <Link to="/app/" className="logo">
            <img src={logo} alt="Localrocket" />
          </Link>
          <PaymentStep
            url=""
            // label="Añadir saldo"
            label="Seleccionar pack"
            current={current == 1}
            enabled={step > 0}
            completed={completed > 0}
          />
          <i className="fa fa-caret-right" style={{ color: "#ffffff33" }}></i>
          <PaymentStep
            url="checkout"
            label="Información sobre la compra"
            current={current == 2}
            enabled={step > 1}
            completed={completed > 1}
          />
          <i className="fa fa-caret-right" style={{ color: "#ffffff33" }}></i>
          <PaymentStep
            url="confirm"
            label="Confirmar pedido"
            current={current == 3}
            enabled={step > 2}
            completed={completed > 2}
          />
        </div>
      </div>
    </div>
  )
}
