import React from "react"
import { navigate } from "@reach/router"

import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"

const SignOutButton = ({ firebase }) => (
  <div
    onClick={event => {
      event.preventDefault()
      if (firebase) {
        firebase.doLogout().then(() => navigate(ROUTES.LOGIN))
      }
    }}
  >
    Cerrar sesión
  </div>
)

export default withFirebase(SignOutButton)
