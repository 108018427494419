import fire from "firebase/app"

export const createMap = (firebase) => (data) => {
  const user = require("firebase").default.auth().currentUser
  data = {
    uid: user.uid,
    createdAt: fire.firestore.FieldValue.serverTimestamp(),
    ...data,
    edgesIncrement: data.numberOfEdges,
    radius: data.radius.value,
    radiusIncrement: data.radius.value,
  }

  return firebase.userMaps(user.uid).add(data)
}

export const calculateCredits = ({ circles, edges }) => {
  const totalMarkers = calculateMarkers({ circles, edges })
  let credits = 250
  credits = credits + Math.ceil((totalMarkers - 288) / 10.0)
  return credits
}

export const calculateMarkers = ({ circles, edges }) => {
  let totalMarkers = 0
  for (let i = 1; i <= Math.max(8, circles); i++) {
    totalMarkers = totalMarkers + i * Math.max(8, edges)
  }
  return totalMarkers
}

export const parseSpintax = (text) => {
  let matches, options, random

  const regEx = new RegExp(/{([^{}]+?)}/)

  while ((matches = regEx.exec(text)) !== null) {
    options = matches[1].split("|")
    random = Math.floor(Math.random() * options.length)
    text = text.replace(matches[0], options[random])
  }

  return text
}
