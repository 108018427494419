import React from "react"
import Form from "./Form"
import { navigate } from "@reach/router"
import { useAuth } from "../../Session/withAuthentication"
import { ConfirmationServiceProvider } from "../../ConfirmDialog"
import CircularProgress from "../../Loading"
import * as actions from "../actions"
import { useToasts } from "../../FlashNotices"

export default function New({ firebase, id }) {
  const updatePowerLink = actions.updatePowerLink(firebase)
  const { success } = useToasts()

  const [link, setLink] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  const auth = useAuth()

  React.useEffect(() => {
    firebase
      .userPowerLinks(auth.uid)
      .doc(id)
      .get()
      .then((snapshot) => {
        setLink(snapshot.data())
        setLoading(false)
      })
  }, [auth, id])

  const onSubmit = async (data) => {
    const result = await updatePowerLink(id, {
      ...data,
      linkCpFicha: "",
      linkCpFichaPercentage: 0,
      linkBrand: "",
      linkBrandPercentage: 0,
    })
    success("Poyecto modificado correctamente.")
    navigate(`..`)
  }

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          {loading ? (
            <CircularProgress />
          ) : (
            <ConfirmationServiceProvider>
              <Form onSubmit={onSubmit} link={link} />
            </ConfirmationServiceProvider>
          )}
        </div>
      </section>
    </div>
  )
}
