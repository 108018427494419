// function to add 0 to a day or a month < 10
const pad = n => {
  return n < 10 ? "0" + n : n
}

const formatTimestamp = timestamp => {
  const date = timestamp.getDate()
  const month = timestamp.getMonth() // jan is 0 not 1
  const year = timestamp.getFullYear()
  const hour = timestamp.getHours()
  const minutes = timestamp.getMinutes()

  return `${pad(date)}/${pad(month + 1)}/${year} ${pad(hour)}:${pad(minutes)}`
}

export { formatTimestamp }
