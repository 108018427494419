import React, { useState, useEffect } from "react"
import { formatTimestamp } from "../../utils/time"
import { useFirebase } from "../Firebase"

import Loading from "../Loading"

const Item = ({ item }) => {
  const userName =
    item.get("uid") === "3nlRM5Jp0qNjZ2os8TaHokJMNnv1"
      ? "Sergio"
      : item.get("uid") === "pUVfbRBMtrgt2f8lUoLziwzEcP62"
      ? "Jose"
      : item.get("uid")
  const userUrl = `https://console.firebase.google.com/u/0/project/localrocket-prod/firestore/data/~2Fusers~2F${item.get(
    "uid"
  )}`

  const status =
    item.get("status") === "payment_pending" ? "$$$" : item.get("status")

  return (
    <tr>
      <td>
        <a href={userUrl} target="_blank" rel="noreferrer">
          {userName.slice(0, 8)}
        </a>
      </td>
      <td>{item.get("remoteId")}</td>
      <td>{status}</td>
      <td>{item.get("startDate")}</td>
      <td>{item.get("endDate")}</td>
      <td>{item.get("successVisits")}</td>
      <td>{item.get("notFoundVisits")}</td>
      <td>{item.get("failVisits")}</td>
      <td>{item.get("totalVisits")}</td>
      <td>
        {item?.get("createdAt") &&
          formatTimestamp(item?.get("createdAt")?.toDate())}
      </td>
      <td>
        {item?.get("updatedAt") &&
          formatTimestamp(item?.get("updatedAt")?.toDate())}
      </td>
    </tr>
  )
}

const List = ({ items }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Usuario</th>
          <th>Id remoto</th>
          <th>Estado</th>
          <th>Fecha inicio</th>
          <th>Fecha fin</th>
          <th>Visitas exitosas</th>
          <th>Visitas No encontradas</th>
          <th>Visitas fallidas</th>
          <th>Visitas totales</th>
          <th>Creado</th>
          <th>Actualizado</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </tbody>
    </table>
  )
}

export default function Admin() {
  const { firestore } = useFirebase()
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [error, setError] = useState(null)

  const [query, setQuery] = useState(null)
  const [page, setPage] = useState(0)

  const [statusFilter, setStatusFilter] = useState()

  useEffect(() => {
    let query = firestore
      .collection("power-links")
      .orderBy("createdAt", "desc")
      .limit(15)

    setQuery(query)
  }, [firestore, statusFilter])

  useEffect(() => {
    if (!query) return

    let newQuery = query

    if (statusFilter) {
      newQuery = newQuery.where("status", "==", statusFilter)
    }

    newQuery
      .get()
      .then((querySnapshot) => {
        setItems(querySnapshot.docs)
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
        console.info("error", err)
      })
  }, [query])

  const goNext = (evt) => {
    evt.preventDefault()
    setPage(page - 1)
    console.info("before", items[0])
    setQuery(
      firestore
        .collection("power-links")
        .orderBy("createdAt", "desc")
        .endBefore(items[0])
        .limit(15)
    )
  }
  const goPrev = (evt) => {
    evt.preventDefault()
    setPage(page + 1)
    console.info("after", items[items.length - 1])
    setQuery(
      firestore
        .collection("power-links")
        .orderBy("createdAt", "desc")
        .startAfter(items[items.length - 1])
        .limit(15)
    )
  }
  const goFirst = (evt) => {
    evt.preventDefault()
    setPage(0)
    setQuery(
      firestore.collection("power-links").orderBy("createdAt", "desc").limit(15)
    )
  }

  const FilterSelector = () => {
    return (
      <div style={{ margin: "1em 0", width: 200 }}>
        <select onChange={(evt) => setStatusFilter(evt.target.value)}>
          <option value="">Todos</option>
          <option value="waiting">Waiting</option>
          <option value="running">Running</option>
        </select>
      </div>
    )
  }

  const Pagination = () => {
    return (
      <p style={{ textAlign: "center" }}>
        <button
          className={`button ${items.length < 15 ? "disabled" : ""}`}
          onClick={goPrev}
        >
          Anterior
        </button>
        <button
          className={`button  ${page === 0 ? "disabled" : ""}`}
          onClick={goNext}
        >
          Reciente
        </button>
      </p>
    )
  }

  const EmptyList = () => {
    return <p>No hay enlaces</p>
  }

  return (
    <div className="inner">
      <p>
        Filter: {statusFilter} - Page: {page}
      </p>
      {loading ? (
        <Loading />
      ) : error ? (
        <p>Ha ocurrido un error</p>
      ) : (
        <>
          <h1>Búsquedas de marca</h1>
          <FilterSelector />
          {items.length > 0 ? <List items={items} /> : <EmptyList />}
          <Pagination />
        </>
      )}
    </div>
  )
}
