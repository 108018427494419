const PAYMENTS_BASE_URL = process.env.GATSBY_PAYMENT_URL

/* GET PRICES */
export const getPrices = (firebase) => async (productId) => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
  }

  const url = `${PAYMENTS_BASE_URL}/prices/${productId}`

  return fetch(url, { headers })
    .then((response) => response.json())
    .then((prices) =>
      prices.prices
        .filter((price) => price.active)
        .sort((price1, price2) => price1.unit_amount - price2.unit_amount)
    )
}

export const getBillingDetails = async (firebase) => {
  const user = require("firebase").default.auth().currentUser
  const snapshot = await firebase.billingDetails(user.uid).get()

  return {
    vatnumber: snapshot.get("vatnumber"),
    name: snapshot.get("name"),
    address1: snapshot.get("address1"),
    address2: snapshot.get("address2"),
    postalcode: snapshot.get("postalcode"),
    city: snapshot.get("city"),
    state: snapshot.get("state"),
    country: snapshot.get("country"),
  }
}

export const saveBillingDetails = async (firebase, details) => {
  const user = require("firebase").default.auth().currentUser

  const billing = await firebase.billingDetails(user.uid).get()
  if (!billing.exists) {
    await billing.ref.set({})
  }

  await billing.ref.update({
    vatnumber: details.vatnumber || "",
    name: details.name || "",
    address1: details.address1 || "",
    address2: details.address2 || "",
    postalcode: details.postalcode || "",
    city: details.city || "",
    state: details.state || "",
    country: details.country || "",
  })
}

export const getPayment = async (firebase) => {
  const authUser = await firebase.user().get()

  const url = `${process.env.GATSBY_PAYMENT_URL}/intents/${authUser.id}`

  const params = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  return fetch(url, params)
    .then((res) => res.json())
    .then((json) => {
      console.info(json)
      return json
    })
    .catch((error) => {
      console.info("Ha ocurrido un error:")
      console.info(error)
      throw error
    })
}

export const setOrder = (order, opts) => {
  return order ? updateOrder(order, opts) : createOrder(opts)
}

// Create order
export const createOrder = async (opts) => {
  const token = await require("firebase")
    .default.auth()
    .currentUser.getIdToken(true)

  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  }
  const url = `${process.env.GATSBY_ORDER_URL}`
  const { productId, billingDetails, payload } = opts

  return await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify({ priceId: productId, billingDetails, payload }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.info(err)
      throw err
    })
}

export const updateOrder = async (order, opts) => {
  // const user = await firebase.user().get()
  const token = await require("firebase")
    .default.auth()
    .currentUser.getIdToken(true)

  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  }
  const url = `${process.env.GATSBY_ORDER_URL}/${order.id}`

  const { productId, billingDetails } = opts

  return await fetch(url, {
    method: "PUT",
    headers,
    body: JSON.stringify({ priceId: productId, billingDetails }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.info(err)
      throw err
    })
}

/**
 * Crea u obtiene order y pago actual.
 *
 * @param {*} firebase
 * @param {*} credits
 */
export const createPayment = async (firebase, order) => {
  const user = await firebase.user().get()

  const url = `${process.env.GATSBY_PAYMENT_URL}/intents`

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      uid: user.id,
      orderId: order.id,
    }),
  }

  return fetch(url, params)
    .then((res) => res.json())
    .then((json) => json)
    .catch((error) => {
      console.info("Ha ocurrido un error:")
      console.info(error)
      throw error
    })
}

export const confirmCardPayment = async (
  stripe,
  { ccElement, paymentIntent, billingDetails }
) => {
  // You can also use handleCardPayment with the PaymentIntents API.
  // See our handleCardPayment documentation for more:
  // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment
  const response = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
    payment_method: {
      card: ccElement,
      billing_details: billingDetails,
    },
  })

  if (response.error) {
    // Handle payment error
    throw response.error
  }

  if (response.paymentIntent && response.paymentIntent.status === "succeeded") {
    // Handle payment success
    return response.paymentIntent
  } else {
    // Handle unknown error
    throw "unknown"
  }
}
