import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@material-ui/core"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  root: {
    background: "#242943",
    border: "transparent",
    color: "white",
    borderRadius: 0,
    "& h2": {
      color: "white",
      fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
      display: "block",
      fontWeight: 600,
      letterSpacing: "0.25em",
      textTransform: "uppercase",
    },
    "& p": {
      color: "white",
      fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
    },
    "& button": {
      fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
      borderRadius: 0,
      appearance: "none",
      backgroundColor: "transparent",
      border: 0,
      boxShadow: "inset 0 0 0 2px #fff",
      color: "#fff",
      cursor: "pointer",
      display: "inline-block",
      fontSize: ".8em",
      fontWeight: 600,
      height: "3.5em",
      letterSpacing: ".25em",
      lineHeight: "3.5em",
      padding: "0 1.75em",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "uppercase",
      transition:
        "background-color .2s ease-in-out,box-shadow .2s ease-in-out,color .2s ease-in-out",
      whiteSpace: "nowrap",
      "&:hover, &:active": {
        backgroundColor: "transparent",
        boxShadow: "inset 0 0 0 2px #9bf1ff",
        color: "#9bf1ff",
      },
    },
  },
}))

export const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles()

  const PaperProps = {
    classes: { root: classes.root },
  }

  return (
    <Dialog
      keepMounted
      TransitionComponent={Transition}
      PaperProps={PaperProps}
      open={open}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Sí, continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
