import React, { useState } from "react"
import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"
import { navigate } from "gatsby"

const LoginWithGoogle = props => {
  const [error, setError] = useState(null)

  const onSubmit = event => {
    event.preventDefault()

    props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        const userRef = props.firebase.user(socialAuthUser.user.uid)
        userRef.get().then(docSnapshot => {
          const timestamp = require("firebase/app").default.firestore.FieldValue.serverTimestamp()
          if (docSnapshot.exists) {
            return userRef.update({
              username: socialAuthUser.user.displayName,
              email: socialAuthUser.user.email,
              lastLoggedInAt: timestamp,
            })
          } else {
            return userRef.set(
              {
                username: socialAuthUser.user.displayName,
                email: socialAuthUser.user.email,
                createdAt: timestamp,
                lastLoggedInAt: timestamp,
              },
              { merge: true }
            )
          }
        })
      })
      .then(() => {
        setError(null)
        navigate(ROUTES.HOME)
      })
      .catch(error => {
        setError(error)
      })
  }

  return (
    <form onSubmit={onSubmit}>
      <button type="submit" className="icon fa-google fit special">
        Inicia sesión con Google
      </button>
      {error && <p>{error.message}</p>}
    </form>
  )
}

export default withFirebase(LoginWithGoogle)
