import React from "react"
import { useFormContext } from "react-hook-form"

export default function DetailsButton() {
  const { formState } = useFormContext()

  const className = formState.isSubmitting ? "loading" : ""
  const disabled = formState.isSubmitting || !formState.isValid

  return (
    <button className={className} disabled={disabled}>
      Continuar
    </button>
  )
}
