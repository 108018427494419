import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import { Router, navigate } from "@reach/router"
import AuthUserContext from "../Session/context"
import { withFirebase } from "../Firebase"

import { CircularProgress, Select } from "@material-ui/core"

import MapBoostNav from "./nav"

import { useForm, FormProvider, useFormContext } from "react-hook-form"
import { useConfirmation, ConfirmationServiceProvider } from "../ConfirmDialog"

import { CheckoutContextProvider, CheckoutContext } from "./context"
import * as actions from "../PaymentsNew/actions"

import MapBoostForm from "./MapBoostForm"
import SelectPack from "./SelectPack"
import PaymentSteps from "./PaymentSteps"
import Details from "./Details"
import PaymentMethods from "./PaymentMethods"

const customStyles = {
  overlay: { zIndex: 4 },
  content: {
    backgroundColor: "#242943",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    borderRadius: 0,
    padding: 0,
    zIndex: 4,
  },
}
Modal.setAppElement("#___gatsby")

const MapBoost = ({ firebase, authUser, mapId, location }) => {
  const getPrices = actions.getPrices(firebase)
  const [loading, setLoading] = useState(true)
  const [map, setMap] = useState(null)
  let { state, dispatch } = React.useContext(CheckoutContext)

  useEffect(() => {
    dispatch({ type: "setData", payload: location.state })
    Promise.all([
      actions.getBillingDetails(firebase),
      firebase.userMaps(authUser.uid).doc(mapId).get(),
      getPrices(process.env.GASTBY_STRIPE_MAPBOOST_PRODUCT),
    ])
      .then(([details, mapSnapshot, prices]) => {
        dispatch({
          type: "setDetails",
          payload: details,
        })
        setMap(mapSnapshot)
        dispatch({
          type: "setProducts",
          payload: prices,
        })
        // Store selected price
        const price = prices.find((p) => p.id == state?.priceId) || prices[0]
        dispatch({
          type: "setProduct",
          payload: price.id,
        })
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }, [firebase, authUser.uid])

  if (state.paymentIntent && state.paymentIntent.status === "succeeded") {
    navigate(`/app/payments/order/${state.order.orderNumber}`, {
      replace: true,
    })
  }

  return (
    <div id="main" className="alt">
      <section>
        <PaymentSteps path="/select" />

        <div className="inner">
          {/* <MapBoostForm map={map} /> */}
          {loading ? (
            <CircularProgress />
          ) : (
            <Router>
              <SelectPack path="/" />
              <Details path="checkout" />
              <PaymentMethods path="/confirm" authUser={authUser} />
            </Router>
          )}
        </div>
      </section>
    </div>
  )
}

export default withFirebase(({ firebase, mapId, location }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <ConfirmationServiceProvider>
          <CheckoutContextProvider>
            <Modal isOpen={true} style={customStyles} contentLabel="Mapboost">
              <MapBoost
                authUser={authUser}
                firebase={firebase}
                mapId={mapId}
                location={location}
              />
            </Modal>
          </CheckoutContextProvider>
        </ConfirmationServiceProvider>
      ) : null
    }
  </AuthUserContext.Consumer>
))

export { MapBoostNav }
