import React, { useContext } from "react"
import { navigate } from "@reach/router"
import { useForm, FormProvider } from "react-hook-form"
import { CheckoutContext } from "../context"
import * as actions from "../actions"
import { Form } from "../../Settings/Billing/Details"
import Overview from "../Overview"
import SubmitButton from "./SubmitButton"

export default function Details({ firebase, location }) {
  const updateBillingDetails = actions.updateBillingDetails(firebase)
  const setOrder = actions.setOrder(firebase)

  const { state, dispatch } = useContext(CheckoutContext)

  // Si no tenemos price seleccinado volvemos a la selección de prices
  if (!state?.price) {
    navigate("new")
  }

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      details: state.details || {},
    },
  })

  const handleDetailsSubmit = async (details) => {
    await dispatch({ type: "setDetails", details })
    await updateBillingDetails(details)
    // console.info("Datos guardados correctamente.")
    // console.info("handle details submit", state)
    console.info(state)
    const { type, id } = state.object
    const order = await setOrder(state.order, {
      payload: {
        type,
        id,
      },
      priceId: state.price.id,
      billingDetails: details,
    })

    await dispatch({ type: "setOrder", order })

    return order
  }

  const onSubmit = async (data) => {
    const order = await handleDetailsSubmit(data["details"])

    navigate(location.pathname, {
      state: { ...location.state, orderId: order.id },
      replace: true,
    })
    navigate("confirm", {
      state: { ...location.state, orderId: order.id },
    })
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid-wrapper">
            <div className="col-8">
              <h2>Datos de facturación</h2>
              <Form />
            </div>
            <div className="col-4">
              <h3>Resumen del pedido</h3>
              <Overview price={state.price} />
              <hr />
              <SubmitButton />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
