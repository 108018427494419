import React, { useState, useEffect } from "react"
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import { Router, navigate } from "@reach/router"
import { CheckoutContext } from "./context"
import { Badge } from "@material-ui/core"

import Overview from "./Overview"

const FormPack = ({ product, checked, onChecked }) => {
  const { register } = useFormContext()
  const amount = product.unit_amount
  const amountBefore = parseInt(product.metadata.amount_before)

  const className = `box pack ${checked ? "selected" : ""}`
  console.info(product)
  return (
    <div className={className}>
      <input
        type="radio"
        id={`demo-priority-low-${product.id}`}
        {...register('product', { required: true })}
        value={product.id}
        defaultChecked={checked}
        onChange={(e) => {
          onChecked(product.id)
        }} />

      <label
        htmlFor={`demo-priority-low-${product.id}`}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <strong style={{ paddingLeft: "1em" }}>
          <span style={{ fontSize: "1.5em" }}>{product.nickname}</span>
        </strong>

        <span style={{ textAlign: "right" }}>
          <strong style={{ fontSize: "1.5em" }}>
            {(amount / 100).toFixed(2)} €
          </strong>

          {amountBefore ? (
            <>
              <br />
              <strong
                style={{
                  fontSize: "1.5em",
                  textDecoration: "line-through",
                  opacity: 0.5,
                  fontWeight: "normal",
                }}
              >
                {(amountBefore / 100).toFixed(2)} €
              </strong>
            </>
          ) : null}
          {amountBefore ? (
            <>
              <br />
              <span style={{ opacity: 0.75 }}>
                ahorro {Math.round((1 - amount / amountBefore) * 100)}%
              </span>
            </>
          ) : null}
          {/* <br />
          <small>+TAX</small> */}
        </span>
      </label>
    </div>
  );
}

const Form = () => {
  let { state, dispatch } = React.useContext(CheckoutContext)

  const [selectedId, setSelectedId] = useState(state.productId)

  const onChecked = (id) => {
    setSelectedId(id)
    dispatch({ type: "setProduct", payload: id })
  }

  return (
    <>
      {state.products.map((product) => (
        <FormPack
          key={product.id}
          product={product}
          checked={selectedId == product.id}
          onChecked={onChecked}
        />
      ))}
    </>
  )
}

export default ({ map }) => {
  let { state, dispatch } = React.useContext(CheckoutContext)

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      productId: state.productId,
    },
  })

  const handleAddFundsSubmit = async (product) => {
    dispatch({ type: "setProduct", payload: product })
    return Promise.resolve().then(() => navigate("boost/checkout"))
  }

  const onSubmit = async (data) => {
    await handleAddFundsSubmit(data["product"])
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid-wrapper">
            <div className="col-8">
              <h2>Selecciona pack</h2>
              <Form />
            </div>

            <div className="col-4">
              <h3>Resumen del pedido</h3>
              <Overview />
              <hr />
              <SelectPackButton />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

export const SelectPackButton = () => {
  const { formState } = useFormContext()
  const className = formState.isSubmitting ? "loading" : ""
  const disabled = formState.isSubmitting || !formState.isValid
  return (
    <button className={className} disabled={disabled}>
      Continuar
    </button>
  )
}
