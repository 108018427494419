import React from "react"

import { withFirebase } from "../../Firebase"
import AuthUserContext from "../../Session/context"

import PowerLinkEdit from "./PowerlinksEdit"

export default withFirebase(({ firebase, id }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <PowerLinkEdit authUser={authUser} firebase={firebase} id={id} />
      ) : null
    }
  </AuthUserContext.Consumer>
))
