import React, { useState, useEffect } from "react"
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form"
import { navigate } from "@reach/router"
import { useToasts } from "../FlashNotices"

import Select from "../Select"
import MapPlaceInput from "./MapPlaceInput"

import { useConfirmation, ConfirmationServiceProvider } from "../ConfirmDialog"

import * as actions from "./actions"
import { useFirebase } from "../Firebase"
import { useAuth } from "../Session/withAuthentication"

const MapForm = () => {
  const { register, formState, setValue, watch, control, trigger } =
    useFormContext()

  const radiusOptions = [
    { value: 250, label: "250 m." },
    { value: 500, label: "500 m." },
    { value: 1000, label: "1000 m." },
    { value: 2500, label: "2500 m." },
    { value: 5000, label: "5000 m." },
  ]

  const onChangePlace = (place) => {
    const {
      name,
      coordinates,
      address,
      locality,
      state,
      postalCode,
      country,
      phone,
      website,
    } = place
    setValue("businessName", name)
    setValue("businessGeolocation", coordinates)
    setValue("businessAddress", address)
    setValue("businessLocality", locality)
    setValue("businessState", state)
    setValue("businessPostalCode", postalCode)
    setValue("businessCountry", country)
    setValue("businessPhone", phone)
    setValue("businessWeb", website)
    trigger()
  }

  const confirm = useConfirmation()
  const description = watch("businessDescription")
  const [descriptionTest, setDescriptionTest] = useState(null)

  const onTestSpintax = async (evt) => {
    evt.preventDefault()
    setDescriptionTest(actions.parseSpintax(description))
  }

  return (
    <>
      <div className="grid-wrapper">
        <div className="col-12">
          <MapPlaceInput
            apiKey="AIzaSyBkzwPM29tt2ucTXQno-LxMrhN2Wqs2ams"
            language="es"
            onChange={onChangePlace}
          />
          <hr />
        </div>
        <div className="field col-12">
          <label htmlFor="business_name">Nombre del negocio*</label>
          <div style={{ display: "flex" }}>
            <input
              className={formState.errors.businessName && "error"}
              disabled={formState.isSubmitting}
              type="text"
              id="business_name"
              placeholder="Nombre del negocio"
              {...register("businessName", { required: true })}
            />

            {/* <button
              className="button special icon fa-search"
              style={{ padding: "0 0.75em 0 1.5em" }}
              onClick={onOpenMap}
            >
              <span className="label">Abrir mapa</span>
            </button> */}
          </div>
          {/* errors will return when field validation fails  */}
          {formState.errors.businessName && (
            <div className="error">
              <span>Este campo es obligatorio</span>
            </div>
          )}
        </div>
        <div className="field col-12">
          <label>Descripción</label>
          <textarea
            className={formState.errors.businessDescription && "error"}
            disabled={formState.isSubmitting}
            type="text"
            placeholder="Descripción"
            {...register("businessDescription")}
          />
          {/* errors will return when field validation fails  */}
          {formState.errors.businessDescription && (
            <div className="error">
              <span>Este campo es obligatorio</span>
            </div>
          )}
          <small>
            Permite formato{" "}
            <a
              href="https://en.wikipedia.org/wiki/Article_spinning"
              target="_blank"
              rel="noopener noreferrer"
            >
              spintax
            </a>
            .
          </small>{" "}
          {description && description.match(/.*\{.*\|.*\}.*/) ? (
            <a href="#" onClick={onTestSpintax}>
              Testear descripción
            </a>
          ) : null}
          {descriptionTest ? (
            <pre style={{ whiteSpace: "normal", paddingTop: ".5em" }}>
              <code>{descriptionTest}</code>
            </pre>
          ) : null}
        </div>
      </div>

      <hr />
      <div className="grid-wrapper">
        <div className="field col-12">
          <label html="route">Dirección*</label>
          <input
            disabled={formState.isSubmitting}
            id="route"
            type="text"
            placeholder="Dirección, número, etc."
            {...register("businessAddress", { required: true })}
          />
          {formState.errors.businessAddress && (
            <div className="error">
              <span>Este campo es obligatorio</span>
            </div>
          )}
        </div>

        <div className="field col-4">
          <label htmlFor="locality">Ciudad</label>
          <input
            disabled={formState.isSubmitting}
            id="locality"
            type="text"
            placeholder="Ciudad"
            {...register("businessLocality")}
          />
        </div>

        <div className="field col-4">
          <label htmlFor="administrative_area_level_1">Comunidad</label>
          <input
            disabled={formState.isSubmitting}
            id="administrative_area_level_1"
            type="text"
            {...register("businessState")}
          />
        </div>

        <div className="field col-4">
          <label htmlFor="postal_code">Código postal</label>
          <input
            disabled={formState.isSubmitting}
            id="postal_code"
            type="text"
            {...register("businessPostalCode")}
          />
        </div>

        <div className="field col-12">
          <label>Pais</label>
          <input
            disabled={formState.isSubmitting}
            id="country"
            type="text"
            placeholder="Pais"
            {...register("businessCountry")}
          />
        </div>

        <div className="field col-6">
          <label>Teléfono</label>
          <input
            disabled={formState.isSubmitting}
            type="text"
            placeholder="Teléfono"
            {...register("businessPhone")}
          />
        </div>
        <div className="field col-6">
          <label>Dominio web</label>
          <input
            disabled={formState.isSubmitting}
            type="text"
            placeholder="www.ejemplo.com"
            {...register("businessWeb")}
          />
        </div>
      </div>
      <hr />
      <div className="grid-wrapper">
        <div className="field col-9">
          <label>URL ficha GMB</label>
          <div style={{ display: "flex" }}>
            <input
              className={formState.errors.gmbURL && "error"}
              disabled={formState.isSubmitting}
              type="text"
              placeholder="https://g.page/google-my-business-url"
              {...register("businessGMBUrl")}
            />
          </div>
        </div>
        <div className="field col-3"></div>
        <div className="field col-4">
          <label>Keyword 1</label>
          <div style={{ display: "flex" }}>
            <input
              className={formState.errors.kw1 && "error"}
              disabled={formState.isSubmitting}
              type="text"
              placeholder="Keyword 1"
              {...register("businessKw1")}
            />
          </div>
        </div>
        <div className="field col-4">
          <label>Keyword 2</label>
          <div style={{ display: "flex" }}>
            <input
              className={formState.errors.kw2 && "error"}
              disabled={formState.isSubmitting}
              type="text"
              placeholder="Keyword 2"
              {...register("businessKw2")}
            />
          </div>
        </div>
        <div className="field col-4">
          <label>Keyword 3</label>
          <div style={{ display: "flex" }}>
            <input
              className={formState.errors.kw3 && "error"}
              disabled={formState.isSubmitting}
              type="text"
              placeholder="Keyword 3"
              {...register("businessKw3")}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="grid-wrapper">
        <div className="field col-6">
          <label>Coordenadas (lat, lng)*</label>
          <div style={{ display: "flex" }}>
            <input
              className={formState.errors.businessGeolocation && "error"}
              disabled={formState.isSubmitting}
              type="text"
              placeholder="lat,lng"
              {...register("businessGeolocation", { required: true })}
            />
            {/* <button
              className="button special icon fa-map"
              onClick={onOpenMap}
            >
              <span className="label">Abrir mapa</span>
            </button> */}
          </div>
          <small>
            Coordenadas de la ficha de negocio o el centro del área de servicio.
          </small>
          {/* errors will return when field validation fails  */}
          {formState.errors.businessGeolocation && (
            <div className="error">
              <span>Este campo es obligatorio</span>
            </div>
          )}
        </div>
        <div className="field col-6"></div>
      </div>
      <div className="grid-wrapper">
        <div className="field col-4">
          <label>Puntos primer radial*</label>
          <input
            className={formState.errors.numberOfEdges && "error"}
            disabled={formState.isSubmitting}
            type="number"
            defaultValue="8"
            placeholder="8"
            {...register("numberOfEdges", { required: true, min: 8, max: 16 })}
          />
          {/* errors will return when field validation fails  */}
          {formState.errors.numberOfEdges && (
            <div className="error">
              <span>El número de puntos debe ser un entero entre 8 y 16.</span>
            </div>
          )}
        </div>
        <div className="field col-4">
          <label>Número de radiales*</label>
          <input
            className={formState.errors.numberOfCircles ? "error" : ""}
            disabled={formState.isSubmitting}
            type="number"
            defaultValue="8"
            placeholder="8"
            {...register("numberOfCircles", {
              required: true,
              min: 8,
              max: 15,
            })}
          />
          {/* errors will return when field validation fails  */}
          {formState.errors.numberOfCircles && (
            <div className="error">
              <span>El número de círculos debe ser un entero entre 8 y 15</span>
            </div>
          )}
        </div>
        <div className="field col-4">
          <label>Distancia entre radiales*</label>

          <Controller
            name="radius"
            control={control}
            defaultValue={radiusOptions[0]}
            render={({ field }) => (
              <Select
                {...field}
                options={radiusOptions}
                defaultValue={radiusOptions[0]}
                isSearchable={false}
                isDisabled={false}
                menuPlacement="auto"
              />
            )}
          />
          {/* <Select
          options={radiusOptions}
          // value={radiusOptions.find(option => option.value == radius)}
          value={value.selectedOption}
          defaultValue={{ value: 250, label: "250 m." }}
          isSearchable={false}
          isDisabled={false}
          menuPlacement="auto"
          onChange={(option) => {
            setValue("radius", option.value)
            setReactSelectValue({ option })
          }}
        /> */}
        </div>
      </div>
    </>
  )
}

const SubmitButton = ({ disabled }) => {
  const { formState } = useFormContext()
  const isDisabled = disabled || formState.isSubmitting || !formState.isValid
  const className = formState.isSubmitting ? "loading" : ""

  if (formState.isValid) {
  }

  return (
    <>
      <button className={className} disabled={isDisabled}>
        Aceptar
      </button>
    </>
  )
}

export default function Form() {
  const firebase = useFirebase()
  const authUser = useAuth()
  const { success, error } = useToasts()
  const [currentCredits, setCurrentCredits] = useState(null)

  const createMap = actions.createMap(firebase)

  useEffect(() => {
    const unsubscribe = firebase.user(authUser.uid).onSnapshot((snapshot) => {
      setCurrentCredits(snapshot.get("credits") || 0)
    })
    return () => unsubscribe()
  }, [firebase, authUser.uid])

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {},
  })

  const confirm = useConfirmation()

  const onSubmit = async (data) => {
    return confirm({
      variant: "danger",
      catchOnCancel: true,
      title: "¿Generar mapa?",
      description: `Se va a generar un mapa fractal con los datos introducidos. ¿Quieres continuar?`,
    })
      .then(() =>
        createMap(data) // TODO: parsear radius, extraer action
          .then(() => navigate("/app/maps"))
          .catch((error) => error("Ha ocurrido un error enviando los datos."))
      )
      .catch((error) => {
        console.info(error)
      })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid-wrapper">
          <div className="col-8">
            <MapForm />
            <SubmitButton />
          </div>
          <div className="col-4"></div>
        </div>
      </form>
    </FormProvider>
  )
}

// export default ({ authUser, firebase }) => {
//   const { register, errors, handleSubmit, setValue } = useForm()
//   const [isLoading, setIsLoading] = useState(false)
//   // const [mapOpen, setMapOpen] = useState(false)

//   const confirm = useConfirmation()

//   const onSubmit = data => {
//     confirm({
//       variant: "danger",
//       catchOnCancel: true,
//       title: "¿Consumir créditos?",
//       description:
//         "Generar el mapa a partir de los datos introducidos require 5 créditos. Actualmente dispones de XX. ¿Quieres continuar?",
//     })
//       .then(() => createMap(data))
//       .catch(() => {})
//   }
//   const createMap = data => {
//     setIsLoading(true)
//     data = {
//       uid: authUser.uid,
//       createdAt: fire.firestore.FieldValue.serverTimestamp(),
//       ...data,
//       edgesIncrement: data.numberOfEdges,
//       radiusIncrement: data.radius,
//     }
//     firebase
//       .userMaps(authUser.uid)
//       .add(data)
//       .then(() => {
//         setIsLoading(false)
//         navigate("/app/maps")
//       })
//   }

//         {/* <Modal
//           aria-labelledby="transition-modal-title"
//           aria-describedby="transition-modal-description"
//           // className={classes.modal}
//           open={mapOpen}
//           onClose={onCloseMap}
//           closeAfterTransition
//           BackdropComponent={Backdrop}
//           BackdropProps={{
//             timeout: 500,
//           }}
//           // PaperProps={PaperProps}
//         >
//           <Fade in={mapOpen}>
//             <div
//               style={{
//                 position: "relative",
//                 width: "80vw",
//                 height: "60vmin",
//                 translate: "-50%, -50%",
//               }}
//             >
//               <MapContainer
//                 apiKey="AIzaSyBkzwPM29tt2ucTXQno-LxMrhN2Wqs2ams"
//                 language="es"
//                 initialCenter={{
//                   lat: 40.854885,
//                   lng: -88.081807,
//                 }}
//                 setCoordinates={coordinates => {
//                   setCoordinates(coordinates)
//                   setValue("coordinates", "value", coordinates)
//                 }}
//               />
//             </div>
//           </Fade>
//         </Modal> */}
