import React from "react"

const firebaseLinkTo = (path = [""]) => {
  const parsedPath = path.join("/").replaceAll("/", "~2F")
  return `https://console.firebase.google.com/u/0/project/localrocket-prod/firestore/data/${parsedPath}`
}

export const FirebaseLink = ({ children, path = [] }) => {
  const url = firebaseLinkTo(path)
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}
