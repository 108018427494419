import React from "react"
import { Link } from "gatsby"
import { Router } from "@reach/router"
import Subscriptions from "./Subscriptions"
import UserSubscriptions from "./UserSubscriptions"
import PowerLinks from "./PowerLinks"
import ScheduledTasks from "./ScheduledTasks"

export default function Admin() {
  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <h1>Administración</h1>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "0 0 2em 0",
            }}
          >
            <Link to={"/app/admin/subscriptions"}>Suscripciones</Link>
            <Link to={"/app/admin/power-links"}>Búsquedas</Link>
            <Link to={"/app/admin/scheduled-tasks"}>Tareas</Link>
          </div>
          <hr />
          <Router>
            <Subscriptions path="subscriptions" />
            <UserSubscriptions path="subscriptions/user/:user_id" />
            <PowerLinks path="power-links" />
            <ScheduledTasks path="scheduled-tasks" />
          </Router>
        </div>
      </section>
    </div>
  )
}
