import React from "react"
import { Link } from "@reach/router"

import { AuthUserContext } from "../Session"
import { withFirebase } from "../Firebase"

import * as actions from "../Projects/actions"
import { formatTimestamp } from "../../utils/time"

const ProjectsHome = ({ authUser, firebase }) => {
  const [projects, setProjects] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const deleteProject = actions.deleteProject(firebase)

  React.useEffect(() => {
    const unsubscribe = firebase
      .userProjects(authUser.uid)
      .orderBy("createdAt", "desc")
      .onSnapshot(querySnapshot => {
        setProjects(querySnapshot.docs)
        setLoading(false)
      })
    return () => unsubscribe()
  }, [authUser.uid, firebase])

  return loading ? null : (
    <>
      <h3>Proyectos</h3>
      <div className="">
        {projects.length ? (
          <table>
            <thead>
              <tr>
                <th width="100%">Proyecto</th>
                <th>Fecha</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {projects.map(project => (
                <tr key={project.id}>
                  <td>{project.get("name")}</td>
                  <td>{formatTimestamp(project.get("createdAt").toDate())}</td>
                  <td>
                    <a href={`projects/${project.id}`}>Ver</a>
                    <a
                      href="#"
                      onClick={evt => {
                        evt.preventDefault()
                        deleteProject(project.id)
                      }}
                    >
                      Eliminar
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No has creado ningún proyecto. </p>
        )}
        <p>
          <Link to="projects/new" className="button">
            Crear proyecto
          </Link>
        </p>
      </div>
    </>
  )
}

export default withFirebase(({ firebase, id }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <ProjectsHome authUser={authUser} firebase={firebase} id={id} />
      ) : null
    }
  </AuthUserContext.Consumer>
))
