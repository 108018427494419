import React, { useState, useCallback, useEffect, useReducer } from "react"

import * as piexif from "piexifjs"

import { useDropzone } from "react-dropzone"
import GeoTagsNav from "./nav"
import MapPlaceInput from "./MapPlaceInput"
import MapContainer from "../MapsNew/MapContainer"
import GeoTagImage from "./GeoTagImage"
import { degToDmsRational } from "./helpers"

import { generateFilesDownload } from "./actions"

function MyDropzone({ handleFiles }) {
  const onDrop = useCallback(
    acceptedFiles => {
      handleFiles(acceptedFiles)
    },
    [acceptedFiles]
  )

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg",
  })

  return (
    <div
      {...getRootProps()}
      style={{
        width: "100%",
        height: "100%",
        borderStyle: "dashed",
        borderWidth: isDragActive ? 3 : 2,
        borderColor: isDragActive
          ? "rgba(212, 212, 255, 0.2)"
          : "rgba(212, 212, 255, 0.1)",
        borderRadius: 5,
        background: isDragActive
          ? "rgba(212, 212, 255, 0.135)"
          : "rgba(212, 212, 255, 0.035)",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Deja tus imágenes aquí.</p>
      ) : (
        <p style={{ textAlign: "center" }}>
          Arrastra tus imágenes aquí
          <br />o<br />
          haz click para seleccionarlas.
          <br />
          <small>(Máx. 10, formato JPEG)</small>
        </p>
      )}
    </div>
  )
}

const MapWidget = ({
  lat,
  lng,
  zoom,
  onChangeLat,
  onChangeLng,
  initialCenter,
}) => {
  const onChangePlace = place => {
    onChangeLat(parseFloat(place[0]))
    onChangeLng(parseFloat(place[1]))
  }

  const setLat = lat => {
    onChangeLat(parseFloat(lat))
  }

  const setLng = lng => {
    onChangeLng(parseFloat(lng))
  }

  return (
    <>
      <div
        style={{
          height: 360,
          boder: "1px solid #e5e5e5",
          position: "relative",
          zIndex: 1,
        }}
      >
        <MapContainer
          zoom={zoom}
          initialCenter={initialCenter}
          apiKey="AIzaSyBkzwPM29tt2ucTXQno-LxMrhN2Wqs2ams"
          language="es"
          onChange={onChangePlace}
        />
      </div>
      <div className="grid-wrapper">
        <div className="field col-6">
          <div style={{ display: "flex", paddingTop: ".5em" }}>
            <input
              disabled={false}
              type="text"
              name="geotag_lat"
              placeholder="Latitud"
              value={lat ? lat : ""}
              onChange={evt => setLat(evt.target.value)}
            />
          </div>
        </div>
        <div className="field col-6">
          <div style={{ display: "flex", paddingTop: ".5em" }}>
            <input
              disabled={false}
              type="text"
              name="geotag_lng"
              placeholder="Longitud"
              value={lng ? lng : ""}
              onChange={evt => setLng(evt.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default () => {
  const [files, setFiles] = useState([])
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()
  const [initialCenter, setInitialCenter] = useState({
    lat: 40.46366700000001,
    lng: -3.74922,
  })
  const [zoom, setZoom] = useState(1)

  const updateFileExif = file =>
    new Promise((resolve, reject) => {
      let { image, name, exifObj } = file

      const jpegData = image.src

      // Actualizamos exifObj
      const newExifObj = {
        "0th": {},
        GPS: {},
        Exif: {},
      }

      // Orientation
      if (exifObj["0th"][piexif.TagValues.ImageIFD.Orientation]) {
        newExifObj["0th"][piexif.TagValues.ImageIFD.Orientation] =
          exifObj["0th"][piexif.TagValues.ImageIFD.Orientation]
      }

      if (lat && lng) {
        newExifObj["GPS"][piexif.TagValues.GPSIFD.GPSLatitudeRef] =
          lat < 0 ? "S" : "N"
        newExifObj["GPS"][
          piexif.TagValues.GPSIFD.GPSLatitude
        ] = degToDmsRational(lat)

        newExifObj["GPS"][piexif.TagValues.GPSIFD.GPSLongitudeRef] =
          lng < 0 ? "W" : "E"
        newExifObj["GPS"][
          piexif.TagValues.GPSIFD.GPSLongitude
        ] = degToDmsRational(lng)
      }
      newExifObj["0th"][piexif.TagValues.ImageIFD.ImageDescription] =
        exifObj["0th"][piexif.TagValues.ImageIFD.ImageDescription] || ""
      // newExifObj["0th"][piexif.TagValues.ImageIFD.Software] =
      //   "Localrocket GeoTag"

      const exifbytes = piexif.dump(newExifObj)
      const newData = piexif.insert(exifbytes, jpegData)
      image = new Image()
      image.src = newData

      resolve({ image, name, exifObj: newExifObj, updated: true })
    })

  const updateFilesExif = files => Promise.all(files.map(updateFileExif))

  const handleFile = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = evt => {
        const jpegData = evt.target.result
        const name = file.name

        // Obtenemos ExifObj
        let exifObj
        try {
          exifObj = piexif.load(jpegData)
        } catch (e) {
          exifObj = {}
        }
        const image = new Image()
        image.src = jpegData

        if (!exifObj["0th"]) exifObj["0th"] = {}
        if (!exifObj["1st"]) exifObj["1st"] = {}
        if (!exifObj["GPS"]) exifObj["GPS"] = {}
        if (!exifObj["Exif"]) exifObj["Exif"] = {}

        resolve({ image, name, exifObj })
      }

      reader.readAsDataURL(file)
    })

  const handleFiles = files => Promise.all(files.map(handleFile)).then(setFiles)

  const onWriteExifClick = evt => {
    evt.preventDefault()
    Promise.all(files.map(updateFileExif)).then(setFiles)
  }

  const onDownloadAll = async evt => {
    evt.preventDefault()

    try {
      await generateFilesDownload(files)
    } catch (e) {
      console.info(e)
    }
  }

  const writeButtonEnabled = files.length && lat && lng
  const downloadButtonEnabled =
    files.length > 0 &&
    files.length == files.filter(file => file.updated).length

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <MapPlaceInput
            apiKey="AIzaSyBkzwPM29tt2ucTXQno-LxMrhN2Wqs2ams"
            language="es"
            onChange={place => {
              const { address, locality, country, coordinates } = place
              const [lat, lng] = coordinates.split(", ")

              setLat(lat)
              setLng(lng)
              setInitialCenter({ lat, lng })
              console.info(place)
              if (address) {
                setZoom(14)
              } else if (locality) {
                setZoom(10)
              } else if (country) {
                setZoom(4)
              }
            }}
          />

          <div className="grid-wrapper">
            <div className="col-6">
              <MapWidget
                lat={lat}
                lng={lng}
                onChangeLat={setLat}
                onChangeLng={setLng}
                initialCenter={initialCenter}
                zoom={zoom}
              />
            </div>
            <div className="col-6">
              <div style={{ height: 360 }}>
                <MyDropzone handleFiles={handleFiles} lat={lat} lng={lng} />
              </div>
              <small
                style={{ fontSize: "small", color: "rgba(255,255,255,.5)" }}
              >
                Las imágenes no se subirán a ningún servidor.
              </small>
            </div>
          </div>
          <hr />
          <div className="grid-wrapper">
            <div
              className="col-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              {files.length}{" "}
              {files.length == 1 ? "imagen cargada" : "imágenes cargadas"}.
            </div>
            <div className="col-6" style={{ textAlign: "right" }}>
              <button
                disabled={!writeButtonEnabled}
                type="submit"
                onClick={onWriteExifClick}
                className={`special icon fa-pencil ${
                  !writeButtonEnabled ? "disabled" : null
                }`}
              >
                Escribir EXIF
              </button>{" "}
              <button
                disabled={!downloadButtonEnabled}
                type="submit"
                onClick={onDownloadAll}
                className={`icon fa-save ${
                  !downloadButtonEnabled ? "disabled" : null
                }`}
              >
                Descargar TODO
              </button>
            </div>
          </div>
          <hr />
          <div className="grid-wrapper">
            {files.length
              ? files.map((file, i) => (
                  <div key={i} className="col-12">
                    <GeoTagImage file={file} lat={lat} lng={lng} />
                  </div>
                ))
              : null}
          </div>
          <div>
            <h3>Cómo geotagear imágenes</h3>
            1. Arrastra o selecciona tus imágenes (10 máximo). <br />
            2. Selecciona en el mapa la posición donde quieres geotagear tus
            imágenes o introduce las coordenadas manualmente.
            <br />
            3. Opcionalmente, modifica la descripción de las imágenes. <br />
            4. Haz click en "Escribir EXIF". <br />
            5. Haz click en los botones "Descargar" de tus imágenes para
            descargarlas o descárgalas todas haciendo click en "Descargar todo".
          </div>
        </div>
      </section>
    </div>
  )
}

export { GeoTagsNav }
