import React, { useEffect } from "react"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"

const GeoGridScanMapBase = ({ google, initialCenter, points }) => {
  const makeMarker = ({ map, point }) => {
    const colors = [
      "#003333",
      "#009933",
      "#66AA33",
      "#99CC33",
      "#CCCC33",
      "#DDCC33",
      "#FFCC00",
      "#FFAA00",
      "#FF9900",
      "#FF6600",
      "#FF6600",
      "#ff3300",
      "#FF3300",
      "#CC3300",
      "#CC3300",
      "#CC3300",
      "#CC3300",
      "#CC3300",
      "#CC3300",
      "#CC3300",
      "#CC3300",
      "#CC3300",
    ]
    let color =
      point.pos > 0 ? colors[point.pos - 1] : colors[colors.length - 1]

    return new google.maps.Marker({
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 0.9,
        scale: 14,
        strokeColor: "black",
        strokeWeight: 1,
      },
      label: {
        text: point.pos > 0 ? point.pos.toString() : "",
        color: "white",
        // fontSize: 12,
      },
      position: {
        lat: point.point.latitude,
        lng: point.point.longitude,
      },
      map,
      draggable: false,
    })
  }
  return (
    <Map
      google={google}
      initialCenter={initialCenter}
      zoomControl={true}
      mapTypeControl={false}
      scaleControl={false}
      rotateControl={false}
      streetViewControl={false}
      fullscreenControl={false}
      onReady={(mapProps, map) => {
        const { google } = mapProps

        new google.maps.Rectangle({
          fillColor: "white",
          fillOpacity: 0.5,
          map: map,
          bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(-90, -180),
            new google.maps.LatLng(90, 180)
          ),
        })

        // Creating grid markers
        const markers = points.map(point => makeMarker({ map, point }))

        // Fit bounds
        const bounds = new google.maps.LatLngBounds()
        points.forEach(p =>
          bounds.extend({
            lat: p.point.latitude,
            lng: p.point.longitude,
          })
        )
        map.fitBounds(bounds)
        // const bounds = new google.maps.LatLngBounds()
        // markers
        //   .filter(m => m.getVisible())
        //   .forEach(m => bounds.extend(m.getPosition()))
        // map.fitBounds(bounds)
      }}
    >
      {/* <Marker
        onClick={onClick}
        name={"Current location"}
        draggable={true}
        onDragend={moveMarker}
        position={{ lat: position.lat, lng: position.lng }}
      /> */}
    </Map>
  )
}

export default GoogleApiWrapper(props => ({
  apiKey: props.apiKey,
  language: props.language,
  ...props,
}))(GeoGridScanMapBase)
