import React, { useEffect, useState, useRef } from "react"
import { navigate } from "@reach/router"
import { useForm } from "react-hook-form"

import GeoGridMap from "./GeoGridMap"
import MapPlaceInput from "./MapPlaceInput"
import Select from "../Select"

import { useConfirmation } from "../ConfirmDialog"

import * as actions from "./actions"

import * as styles from "./GeoGridNew.module.scss"

const gridOptions = [
  { value: 3, label: "3x3" },
  { value: 5, label: "5x5" },
  { value: 7, label: "7x7" },
  { value: 9, label: "9x9" },
  { value: 11, label: "11x11" },
  { value: 13, label: "13x13" },
  { value: 15, label: "15x15" },
]

const radiusOptions = [
  { value: 1000, label: "1 km." },
  { value: 2000, label: "2 km." },
  { value: 3000, label: "3 km." },
  { value: 4000, label: "4 km." },
  { value: 5000, label: "5 km." },
  { value: 6000, label: "6 km." },
  { value: 7000, label: "7 km." },
  { value: 8000, label: "8 km." },
  { value: 9000, label: "9 km." },
  { value: 10000, label: "10 km." },
]

const setMapSize = (container) => () => {
  const containerRect = container.getBoundingClientRect()
  const windowHeight = window.innerHeight
  const height = windowHeight - containerRect.y

  container.style.height = `${height}px`
}

export default ({ firebase }) => {
  const containerRef = useRef(null)
  const confirm = useConfirmation()

  const {
    register,
    errors,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState,
    triggerValidation,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      size: gridOptions[0].value,
      radius: radiusOptions[0].value,
    },
  })

  const [initialCenter, setInitialCenter] = useState({
    lat: 40.46366700000001,
    lng: -3.74922,
  })

  React.useEffect(() => {
    register("businessName", { required: true })
    register("businessTerm", { required: true })
    register("lat", { required: true })
    register("lng", { required: true })
    register("radius", { required: true })
    register("size", { srequired: true })
    triggerValidation()
  }, [register])

  useEffect(() => {
    const onResize = setMapSize(containerRef.current)
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  const onBusinessTermChanged = (value) => {
    setValue("businessTerm", value)
    triggerValidation()
  }

  const onChangePosition = ({ lat, lng }) => {
    setValue("lat", lat)
    setValue("lng", lng)
    setInitialCenter({ lat, lng })
  }

  const onPlaceChanged = ({ name, coordinates }) => {
    const [lat, lng] = coordinates.split(", ")

    setValue("businessName", name)
    onChangePosition({ lat, lng })
    triggerValidation()
  }

  const onSubmit = async (data) => {
    try {
      await confirm({
        variant: "danger",
        catchOnCancel: true,
        title: "¿Escanear puntos?",
        description: `Vas a escanear los puntos seleccionados. ¿Quieres continuar?`,
      })
        .then(() =>
          actions
            .createGrid(firebase, data)
            .then(navigate("/app/geogrids"))
            .catch((error) => error("Ha ocurrido un error enviando los datos"))
        )
        .catch((error) => {
          console.info(error)
        })
    } catch (e) {
      console.info(e)
    }
  }

  return (
    <div ref={containerRef} className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeoGridMap
          apiKey="AIzaSyBkzwPM29tt2ucTXQno-LxMrhN2Wqs2ams"
          onChangePosition={onChangePosition}
          initialCenter={initialCenter}
          grid={getValues()["businessName"]}
          radius={watch("radius")}
          gridSize={watch("size")}
        />
        <div className={styles.controls}>
          <div className={styles.search} style={{ flex: 1 }}>
            <MapPlaceInput
              apiKey="AIzaSyBkzwPM29tt2ucTXQno-LxMrhN2Wqs2ams"
              onChange={onPlaceChanged}
            />
            <div style={{ flex: 2 }}>
              <input
                type="text"
                name="businessTerm"
                onChange={(evt) => onBusinessTermChanged(evt.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <button
                className="button"
                disabled={formState.isSubmitting || !formState.isValid}
              >
                Enviar
              </button>
            </div>
          </div>
          <div className={styles.options}>
            <Select
              name="size"
              options={gridOptions}
              defaultValue={gridOptions.find(
                (o) => o.value == getValues()["size"]
              )}
              isSearchable={false}
              isDisabled={false}
              menuPlacement="auto"
              onChange={(option) => setValue("size", option.value)}
            />
            <Select
              name="radius"
              options={radiusOptions}
              defaultValue={radiusOptions.find(
                (o) => o.value == getValues()["radius"]
              )}
              isSearchable={false}
              isDisabled={false}
              menuPlacement="auto"
              onChange={(option) => setValue("radius", option.value)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}
