import React from "react"

let CheckoutContext = React.createContext()

let initialState = {
  products: [],
  productId: null,
  data: {},
  details: null,
  paymentIntent: null,
  paymentSubmit: () => {},
  order: null,
  submitting: false,
}

let reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState
    case "update":
      return { ...state, credits: action.payload }
    case "setData":
      return { ...state, data: action.payload }
    case "setDetails":
      return { ...state, details: action.payload }
    case "setProducts":
      return { ...state, products: action.payload }
    case "setProduct":
      return { ...state, productId: action.payload }
    case "setOrder":
      return { ...state, order: action.payload }
    case "setPayment":
      return { ...state, paymentIntent: action.payload }
    case "setPaymentSubmit":
      return { ...state, paymentSubmit: action.payload }
    default:
      return state
  }
}

function CheckoutContextProvider(props) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState)
  let value = { state, dispatch }

  // [B]
  return (
    <CheckoutContext.Provider value={value}>
      {props.children}
    </CheckoutContext.Provider>
  )
}

let CheckoutContextConsumer = CheckoutContext.Consumer

// [C]
export { CheckoutContext, CheckoutContextProvider, CheckoutContextConsumer }
