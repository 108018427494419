import React, { useState, useEffect } from "react"

import AuthUserContext from "./context"
import { withFirebase } from "../Firebase"

export const AuthProvider = withFirebase(props => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )

  useEffect(() => {
    props.firebase.onAuthUserListener(
      authUser => {
        localStorage.setItem("authUser", JSON.stringify(authUser))

        setCurrentUser(authUser)
      },
      () => {
        localStorage.removeItem("authUser")
        setCurrentUser(null)
      }
    )
  }, [props.firebase])

  return (
    <AuthUserContext.Provider value={currentUser}>
      {props.children}
    </AuthUserContext.Provider>
  )
})

export const useAuth = () => React.useContext(AuthUserContext)
export default AuthUserContext
