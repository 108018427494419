// const SUBSCRIPTIONS_BASE_URL =
// "http://localhost:5001/maps-7f983/europe-west1/subscriptions-http"
const SUBSCRIPTIONS_BASE_URL = process.env.GATSBY_SUBSCRIPTIONS_URL

/* GET SUBSCRIPTION */
export const getSubscription = firebase => async subscriptionId => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
  }

  const url = `${SUBSCRIPTIONS_BASE_URL}/subscriptions/${subscriptionId}`

  return fetch(url, { headers }).then(response => response.json())
}

/* CANCEL SUBSCRIPTION */
export const cancelSubscription = firebase => async subscriptionId => {
  const { currentUser } = firebase.auth

  const headers = {
    Authorization: await currentUser.getIdToken(),
  }

  const url = `${SUBSCRIPTIONS_BASE_URL}/subscriptions/${subscriptionId}/cancel`

  return fetch(url, { method: "POST", headers }).then(response =>
    response.json()
  )
}
