import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import MapPlaceInput from "../../MapsNew/MapPlaceInput"
import { useConfirmation } from "../../ConfirmDialog"

export default function Form({ onSubmit }) {
  const confirm = useConfirmation()
  const { register, errors, setValue, handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const onChangePlace = place => {
    setValue("linkUrl", place.website)
    setValue("linkTitleFicha", place.name)
  }

  const submit = async data => {
    await confirm({
      variant: "danger",
      catchOnCancel: true,
      title: "¿Crear búsqueda?",
      description: `Por favor, asegúrate que los datos son correctos. Una vez creada la
        búsqueda no se puede cancelar. ¿Quieres continuar?`,
    }).then(() => onSubmit(data))
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="field">
        <MapPlaceInput
          apiKey="AIzaSyBkzwPM29tt2ucTXQno-LxMrhN2Wqs2ams"
          language="es"
          onChange={onChangePlace}
        />
      </div>
      <div className="field">
        <label>URL de tu web que aparece en la ficha de GMB *</label>
        <input type="text" {...register('linkUrl', { required: true })} />
      </div>
      <div className="field">
        <label>Título ficha GMB *</label>
        <input type="text" {...register('linkTitleFicha', { required: true })} />
      </div>
      <div className="field">
        <label>Búsqueda 1 *</label>
        <input type="text" {...register('linkKeyword1', { required: true })} />
      </div>
      <div className="field">
        <label>Búsqueda 2 *</label>
        <input type="text" {...register('linkKeyword2', { required: true })} />
      </div>
      <div className="field">
        <label>Búsqueda 3 *</label>
        <input type="text" {...register('linkKeyword3', { required: true })} />
      </div>
      <div className="field">
        <label>Búsqueda 4 *</label>
        <input type="text" {...register('linkKeyword4', { required: true })} />
      </div>
      <div className="field">
        <label>Búsqueda 5 *</label>
        <input type="text" {...register('linkKeyword5', { required: true })} />
      </div>

      {/* <hr />
      <div className="field">
        <label>CP Ficha</label>
        <input
          type="text"
          name="linkCpFicha"
          ref={register({ required: true })}
        />
      </div>
      <div className="field">
        <label>% CP Ficha</label>
        <input
          type="number"
          name="linkCpFichaPercentage"
          defaultValue={0}
          ref={register({ required: true })}
        />
      </div>
      <hr />
      <div className="field">
        <label>Marca</label>
        <input
          type="text"
          name="linkBrand"
          ref={register({ required: true })}
        />
      </div>
      <div className="field">
        <label>% Marca</label>
        <input
          type="number"
          name="linkBrandPercentage"
          defaultValue={0}
          ref={register({ required: true })}
        />
      </div> */}
      <p>
        Por favor, asegúrate que los datos son correctos. Una vez creada la
        búsqueda no se puede cancelar.
      </p>
      <div>
        <button className="btn">Crear búsqueda</button>
      </div>
    </form>
  );
}
