import React from "react"
import Form from "./Form"
import { navigate } from "@reach/router"
import { ConfirmationServiceProvider } from "../../ConfirmDialog"
import * as actions from "../actions"

export default function New({ firebase }) {
  const createPowerLink = actions.createPowerLink(firebase)

  const onSubmit = async data => {
    const res = await createPowerLink({
      ...data,
      linkCpFicha: "",
      linkCpFichaPercentage: 0,
      linkBrand: "",
      linkBrandPercentage: 0,
    })
    navigate(`/app/subscriptions/new`, {
      state: {
        type: "power-link",
        id: res.id,
      },
    })
  }

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <ConfirmationServiceProvider>
            <Form onSubmit={onSubmit} />
          </ConfirmationServiceProvider>
        </div>
      </section>
    </div>
  )
}
