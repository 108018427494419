import React, { useContext } from "react"
import { Link, globalHistory } from "@reach/router"
import { CheckoutContext } from "./context"
import logo from "../../assets/images/logo-localrocket.png"

export const SubscriptionStep = ({
  label,
  url,
  current,
  enabled,
  completed,
}) => {
  const classes = ["step"]

  if (!enabled) classes.push("disabled")
  if (current) classes.push("current")

  return enabled ? (
    <Link to={url} className={classes.join(" ")}>
      {completed ? <i className="fa fa-check"></i> : null}
      {label}
    </Link>
  ) : (
    <span className={classes.join(" ")}>{label}</span>
  )
}

export default function SubscriptionSteps() {
  const { state } = useContext(CheckoutContext)

  const { price, order } = state

  let current = 1
  switch (globalHistory.location.pathname) {
    case "/app/subscriptions/new":
      current = 1
      break
    case "/app/subscriptions/checkout":
      current = 2
      break
    case "/app/subscriptions/confirm":
      current = 3
      break
  }
  let step = 1
  let completed = 0
  if (price) {
    step = 2
    completed = 1
  }

  if (order) {
    step = 3
    completed = 2
  }

  return (
    <div className="grid-wrapper">
      <div className="col-12">
        <div className="steps">
          <Link to="/app/" className="logo">
            <img src={logo} alt="Localrocket" />
          </Link>
          <SubscriptionStep
            url="new"
            // label="Añadir saldo"
            label="Seleccionar plan"
            current={current == 1}
            enabled={step > 0}
            completed={completed > 0}
          />
          <i className="fa fa-caret-right" style={{ color: "#ffffff33" }}></i>
          <SubscriptionStep
            url="checkout"
            label="Información sobre la compra"
            current={current == 2}
            enabled={step > 1}
            completed={completed > 1}
          />
          <i className="fa fa-caret-right" style={{ color: "#ffffff33" }}></i>
          <SubscriptionStep
            url="confirm"
            label="Confirmar pedido"
            current={current == 3}
            enabled={step > 2}
            completed={completed > 2}
          />
        </div>
      </div>
    </div>
  )
}
