import React, { useState, useEffect } from "react"
import { CircularProgress } from "@material-ui/core"

import AuthUserContext from "../Session/context"
import { withFirebase } from "../Firebase"

import GeoGridsEmpty from "./GeoGridsEmpty"
import GeoGridList from "./GeoGridsList"
import GeoGridsNav from "./nav"

import { useConfirmation, ConfirmationServiceProvider } from "../ConfirmDialog"

const GeoGrids = ({ firebase, authUser }) => {
  const [loading, setLoading] = useState(true)
  const [grids, setGrids] = useState([])

  const confirm = useConfirmation()

  useEffect(() => {
    const unsubscribe = firebase
      .userGrids(authUser.uid)
      .orderBy("createdAt", "desc")
      .onSnapshot(querySnapshot => {
        setGrids(querySnapshot.docs)
        setLoading(false)
      })
    return () => unsubscribe()
  }, [firebase, authUser.uid])

  const onRemoveGrid = id => {
    confirm({
      variant: "danger",
      title: "¿Eliminar informe?",
      description: `El informe se eliminará. Esta operación no se puede deshacer. ¿Quieres continuar?`,
    }).then(() => {
      firebase
        .userGrids(authUser.uid)
        .doc(id)
        .delete()
    })
  }

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          {loading ? (
            <CircularProgress />
          ) : grids.length ? (
            <>
              <GeoGridList grids={grids} onRemoveGrid={onRemoveGrid} />
            </>
          ) : (
            <GeoGridsEmpty />
          )}
        </div>
      </section>
    </div>
  )
}

export default withFirebase(({ firebase }) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <ConfirmationServiceProvider>
          <GeoGrids authUser={authUser} firebase={firebase} />
        </ConfirmationServiceProvider>
      ) : null
    }
  </AuthUserContext.Consumer>
))

export { GeoGridsNav }
