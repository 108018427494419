import React from "react"
import { Router } from "@reach/router"

import * as ROUTES from "../constants/routes"

import { AuthProvider } from "../components/Session/withAuthentication"

import PrivateRoute from "../components/PrivateRoute"
import OnlyPublicRoute from "../components/OnlyPublicRoute"

import Layout from "../components/layout"

import Dashboard from "../components/Dashboard"
import LoginPage from "../pages/login"
import RegisterPage from "../pages/register"
import PasswordForget from "../pages/pw-forget"

export default function App() {
  return (
    <Layout>
      <AuthProvider>
        <Router>
          <PrivateRoute path="/app/*" component={Dashboard} />
          <OnlyPublicRoute path={ROUTES.LOGIN} component={LoginPage} />
          <OnlyPublicRoute
            component={PasswordForget}
            path={ROUTES.FORGOT_PASSWORD}
          />
          <OnlyPublicRoute component={RegisterPage} path={ROUTES.REGISTER} />
        </Router>
      </AuthProvider>
    </Layout>
  )
}
