import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { CheckoutContext } from "../context"

export default function SubmitButton() {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext()
  const {
    state: { paymentSubmit },
  } = useContext(CheckoutContext)

  const className = isSubmitting ? "loading" : ""
  const disabled = !paymentSubmit || isSubmitting || !isValid

  return (
    <button className={className} disabled={disabled}>
      Pago seguro
    </button>
  )
}
