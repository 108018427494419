import React, { useState, useEffect, useContext } from "react"
import Modal from "react-modal"
import { Router } from "@reach/router"

import { withFirebase } from "../Firebase"
import { useAuth } from "../Session/withAuthentication"

import SubscriptionsNav from "./nav"
import SubscriptionSteps from "./SubscriptionSteps"

import Page from "./Page"
import Prices from "./Prices"
import Details from "./Details"
import Payment from "./Payment"
import Thanks from "./Thanks"

import Loading from "./Loading"

import { CheckoutContextProvider, CheckoutContext } from "./context"
import * as actions from "./actions"

const customStyles = {
  overlay: { zIndex: 4 },
  content: {
    backgroundColor: "#242943",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    borderRadius: 0,
    padding: 0,
    zIndex: 4,
  },
}
Modal.setAppElement("#___gatsby")

const SubscriptionNew = ({ firebase, location, ...props }) => {
  const user = useAuth()
  const getPrices = actions.getPrices(firebase)
  const getBillingDetails = actions.getBillingDetails(firebase)

  const [loading, setLoading] = useState(true)
  const { state, dispatch } = useContext(CheckoutContext)

  const initializeState = async () => {
    const state = location.state || {}
    console.info("state", state)
    // Store Object
    if (state.type && state.id) {
      await dispatch({
        type: "setObject",
        object: { type: state.type, id: state.id },
      })
    }

    // Store Product Id
    // Develop
    // const productId = state.productId || "prod_JSENXGNEAMaugt"
    // production
    const productId = state.productId || process.env.GASTBY_STRIPE_LINKS_PRODUCT

    if (productId) {
      await dispatch({ type: "setProduct", product: productId })
    }

    // Store Product's Prices
    const spacialUsers = [
      "pUVfbRBMtrgt2f8lUoLziwzEcP62",
      // "RWO8xBGvWycttuFAjK3drEicHTk2",
      "7IjhXdvNMqb5TaGAGdm5UZWheQD3",
    ]

    let prices = await getPrices(productId)
    console.info(prices)
    if (spacialUsers.includes(user.uid)) {
      prices = prices.filter(
        (p) =>
          p.id === "price_1J2wWIHtOgK4idYgSRE6NQMu" ||
          p.id === "price_1J2wVhHtOgK4idYgqgWcKSzN"
      )
    } else {
      prices = prices.filter(
        (p) =>
          p.id === "price_1J2045HtOgK4idYgZhAE9wQY" ||
          p.id === "price_1J2045HtOgK4idYgYkEagRun"
      )
    }

    await dispatch({
      type: "setPrices",
      prices,
    })

    // Store selected price
    const price = prices.find((p) => p.id == state?.priceId) || prices[0]
    await dispatch({ type: "setPrice", price })

    // Store UserDetails
    const details = await getBillingDetails()

    await dispatch({
      type: "setDetails",
      details,
    })
  }

  useEffect(() => {
    setLoading(true)
    initializeState().then((_) => setLoading(false))
  }, [firebase])

  // if (state.paymentIntent && state.paymentIntent.status === "succeeded") {
  //   navigate(`/app/subscriptions/order/${state.order.orderNumber}`, {
  //     replace: true,
  //   })
  // }

  return (
    <Page header={<SubscriptionSteps />}>
      {loading ? (
        <Loading />
      ) : (
        <Router>
          <Prices path="/new" />
          <Details path="/checkout" />
          <Payment path="/confirm" />
          <Thanks path="/order/:orderId" />
        </Router>
      )}
    </Page>
  )
}

export default withFirebase(({ firebase, ...props }) => (
  <CheckoutContextProvider>
    <Modal isOpen={true} style={customStyles} contentLabel="Recargar crédito">
      <SubscriptionNew firebase={firebase} {...props} />
    </Modal>
  </CheckoutContextProvider>
))

export { SubscriptionsNav }
