import React from "react"
import { useForm, FormProvider, useFormContext } from "react-hook-form"
import { useToasts } from "../FlashNotices"
import { navigate } from "gatsby"

const Form = () => {
  const { register, formState } = useFormContext()
  return (
    <>
      <input
        disabled={formState.isSubmitting}
        type="text"
        id="code"
        {...register("code", { required: true })}
        placeholder="Cupón"
      />
    </>
  )
}

export default ({ code }) => {
  const { success, error } = useToasts()
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      code: code,
    },
  })

  const onSubmit = async (data) => {
    const { code } = data
    const token = await require("firebase")
      .default.auth()
      .currentUser.getIdToken()

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    }

    const url = `${process.env.GATSBY_COUPON_URL}/apply`
    return await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ code }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.credits) {
          success(
            `¡Enhorabuena! Tu código se ha aplicado correctamente y ahora tienes ${json.credits} créditos.`
          )
          navigate("/app")
          return
        }
        if (json.error) {
          if (json.error == "code/empty") {
            error("¡Oops! El código no es válido.")
          } else if (json.error == "user/not-found") {
            error("¡Oops! No se ha podido aplicar el código.")
          } else if (json.error == "code/not-found") {
            error("¡Oops! El código introducido no existe.")
          } else if (json.error == "code/too-many-uses") {
            error("¡Oops! El código introducido ha excedido el límite de usos.")
          } else if (json.error == "code/already-used") {
            error("¡Oops! Parece que ya has usado este código antes.")
          } else {
            error("¡Oops! Ha ocurrido un error desconocido.")
          }
        }
      })
      .catch((err) => {
        console.info(err)
        error(
          `¡Oops! Parece ha ocurrido un error y no se ha podido aplicar el código.`
        )
        throw err
      })
  }

  return (
    <div id="main" className="alt">
      <section>
        <div className="inner">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="grid-wrapper">
                <div className="col-8">
                  <h2>Introduce tu código regalo:</h2>
                  <Form />
                  <hr />
                  <CouponButton />
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </section>
    </div>
  )
}

const CouponButton = () => {
  const { formState } = useFormContext()
  const className = formState.isSubmitting ? "loading" : ""
  const disabled = formState.isSubmitting || !formState.isValid
  return (
    <button className={className} disabled={disabled}>
      Continuar
    </button>
  )
}
