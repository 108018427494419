import React from "react"
import ReactSelect from "react-select"

const customStyles = {
  valueContainer: (provided, state) => ({
    color: "red",
    borderColor: state.isFocused ? "red" : "green",
    boxShadow: state.isFocused ? "0 0 0 2px red" : "green",
  }),
  option: (provided, state) => ({
    ...provided,
    // borderBottom: "1px dotted pink",
    // color: state.isSelected ? "red" : "blue",
    // padding: 20,
    padding: "8px 1em",
    backgroundColor: state.isSelected
      ? "rgba(212, 212, 255, 0.2)"
      : state.isFocused
      ? "rgba(212, 212, 255, 0.1)"
      : "rgba(212, 212, 255, 0.035)",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#242943",
    paddingBottom: 0,
    paddingTop: 0,
    border: "solid 1px rgba(212, 212, 255, 0.1)",
    borderRadius: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(212, 212, 255, 0.035)",
    border: "none",
    borderRadius: 0,
    padding: 0,
    borderColor: state.isFocused ? "#9bf1ff" : "none",
    boxShadow: state.isFocused ? "0 0 0 2px #9bf1ff" : "none",
    height: "2.75em",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "rgba(244, 244, 255, 0.2)",
    padding: "0 1em",
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
  }),
  input: (provided, state) => ({
    // ...provided,
    width: "100%",
    padding: "0 1em",
    color: "rgba(255, 255, 255)",
    borderColor: "none",
    boxShadow: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return {
      ...provided,
      opacity,
      transition,
      padding: "0 1em",
      color: "white",
      width: "calc(100% - 1em)",
    }
  },
}

export default function Select(props) {
  return <ReactSelect styles={customStyles} {...props} />
}
