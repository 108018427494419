import React, { useEffect, useState, useContext } from "react"
import { navigate } from "@reach/router"
import { useForm, FormProvider } from "react-hook-form"
import { CheckoutContext } from "../context"

import Price from "./Price"
import SubmitButton from "./SubmitButton"
import Overview from "../Overview"

export default function Prices({ firebase, location }) {
  // Estado
  let { state, dispatch } = useContext(CheckoutContext)
  const [prices, _] = useState(state.prices)
  const [price, setPrice] = useState(state.price)

  useEffect(() => {
    if (!price) return
    setPrice(price)
    methods.setValue("priceId", price.id, true)
  }, [price])

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      priceId: price?.id,
    },
  })

  const onSubmit = async (data) => {
    // No hacemos nada con data, porque el data.priceId será el mismo que
    // que tengamos en el estado.
    await dispatch({
      type: "setPrice",
      price: price,
    })
    navigate("checkout", { state: { priceId: price.id, ...state.object } })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid-wrapper">
          <div className="col-8">
            <h2>Selecciona plan</h2>
            {prices.map((p) => (
              <Price
                key={p.id}
                price={p}
                checked={p.id == price?.id}
                onChecked={setPrice.bind(null, p)}
              />
            ))}
          </div>
          <div className="col-4">
            <h3>Resumen del pedido</h3>
            <Overview price={price} />
            <hr />
            <SubmitButton />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
