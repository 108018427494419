import React, { useState, useEffect } from "react"

import { useFormContext } from "react-hook-form"
import { withFirebase } from "../Firebase"
import AuthUserContext from "../Session/context"
import { CheckoutContext } from "./context"

const styles = {
  row: { display: "table-row" },
  labelCol: { display: "table-cell", width: "100%", verticalAlign: "middle" },
  priceCol: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  total: { textAlign: "right", fontSize: "2em" },
}

const Overview = ({ firebase, authUser, order }) => {
  const { state, dispatch } = React.useContext(CheckoutContext)

  const { watch } = useFormContext()
  const productId = watch("product") || state.productId

  const [currentCredits, setCurrentCredits] = useState(0)
  useEffect(() => {
    const unsubscribe = firebase.user(authUser.uid).onSnapshot((snapshot) => {
      setCurrentCredits(snapshot.get("credits") || 0)
    })
    return () => unsubscribe()
  }, [authUser.uid, firebase])

  let credits = 0
  let totalCredits = 0
  let amount = 0
  let amountTax = 0
  let tax = undefined

  const product = state.products.find((product) => product.id == productId)

  if (order) {
    credits = order.credits
    amountTax = order.amountTax / 100
    amount = order.amount / 100
    tax = amountTax - amount
  } else if (product) {
    credits = parseInt(product.metadata.credits, 10)
    amountTax = product.unit_amount / 100
    amount = product.unit_amount / 100
  }

  totalCredits = currentCredits + credits

  return (
    <>
      <div>
        <div style={styles.row}>
          <div style={styles.labelCol}>Importe del pedido:</div>
          <div style={styles.priceCol} className="text-right">
            <strong>{amount.toFixed(2)} €</strong>
          </div>
        </div>
        {tax !== undefined ? (
          <div style={styles.row}>
            <div style={styles.labelCol}>IVA:</div>
            <div style={styles.priceCol} className="text-right">
              <strong>{tax.toFixed(2)} €</strong>
            </div>
          </div>
        ) : null}

        <hr />
        <div style={styles.labelCol}>
          <strong>Total</strong>
          {tax ? (
            <>
              <br />
              IVA incl.
            </>
          ) : null}
        </div>
        <div style={styles.priceCol}>
          <strong style={styles.total}>{amountTax.toFixed(2)} €</strong>
        </div>
      </div>

      <hr />
      <div
        className="box"
        style={{ backgroundColor: "rgba(212, 212, 255, 0.035)" }}
      >
        <div>Saldo actualizado trás el pago:</div>
        <div>
          {totalCredits} {totalCredits === 1 ? "crédito" : "créditos"}
        </div>
      </div>
    </>
  )
}

export default withFirebase(({ firebase, order }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <Overview firebase={firebase} authUser={authUser} order={order} />
      ) : null
    }
  </AuthUserContext.Consumer>
))
