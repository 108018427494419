import React from "react"
import { Link } from "@reach/router"

export default ({
  id,
  orderNumber,
  type,
  amount,
  amountTax,
  status,
  description,
  paymentMethod,
  paymentMethodType,
  created,
  receiptUrl,
  invoiceUrl,
  onRemovePayment,
}) => {
  const onRemove = evt => {
    evt.preventDefault()
    if (confirm("¿Eliminar pedido?")) {
      onRemovePayment(id)
    }
  }

  let createdAt = new Date(1970, 0, 1)
  createdAt.setSeconds(created.seconds)

  return (
    <tr>
      <td>{createdAt.toLocaleDateString()}</td>
      <td>{orderNumber}</td>
      {/* <td>{type}</td> */}
      <td>{(amount / 100).toFixed(2)}€</td>
      <td>{(amountTax / 100).toFixed(2)}€</td>
      <td>{description}</td>
      <td style={{ whiteSpace: "nowrap" }}>
        {status === "succeeded" ? (
          <>
            <i className="fa fa-check"></i> Procesado
          </>
        ) : (
          <Link to={`${orderNumber}/pay`}>Pagar ahora</Link>
        )}
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        {paymentMethodType == "card"
          ? `${paymentMethod.brand} ****${paymentMethod.last4}`
          : "-"}
      </td>

      <td style={{ textAlign: "right" }}>
        {invoiceUrl ? (
          <a href={invoiceUrl} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-print"></i>
          </a>
        ) : receiptUrl ? (
          <a href={receiptUrl} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-print"></i>
          </a>
        ) : null}
        {status !== "succeeded" ? (
          <a href="#" onClick={onRemove}>
            <i className="fa fa-trash"></i>
          </a>
        ) : null}
      </td>
    </tr>
  )
}
