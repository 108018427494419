import React from "react"
import { CircularProgress } from "@material-ui/core"

export default function Loading() {
  return (
    <div className="grid-wrapper">
      <div
        className="col-12"
        style={{
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </div>
    </div>
  )
}
